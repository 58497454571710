import React from 'react';

const BriefCaseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      x="0"
      y="0"
      viewBox="0 0 24 24"
      xmlSpace="preserve">
      <g>
        <path
          d="M19.5 4h-2.03c-.25-2.25-2.16-4-4.47-4h-2.5C8.19 0 6.28 1.75 6.03 4H4.5C2.02 4 0 6.02 0 8.5v11C0 21.98 2.02 24 4.5 24h15c2.48 0 4.5-2.02 4.5-4.5v-11C24 6.02 21.98 4 19.5 4Zm-9-3H13c1.76 0 3.22 1.31 3.46 3H7.04c.24-1.69 1.7-3 3.46-3ZM23 19.5c0 1.93-1.57 3.5-3.5 3.5h-15C2.57 23 1 21.43 1 19.5v-11C1 6.57 2.57 5 4.5 5h15C21.43 5 23 6.57 23 8.5v11Zm-5.72-6.76c.46.46.72 1.09.72 1.76s-.26 1.3-.73 1.77l-2.4 2.57c-.1.11-.23.16-.37.16-.12 0-.24-.04-.34-.13-.2-.19-.21-.5-.02-.71l2.41-2.59c.17-.17.29-.36.37-.57H6.5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h10.41c-.07-.21-.19-.4-.35-.56l-2.43-2.6c-.19-.2-.18-.52.02-.71.2-.19.52-.18.71.02l2.41 2.59Z"
          fill="#000000"
          opacity="1"
          data-original="#000000"></path>
      </g>
    </svg>
  );
};

export default BriefCaseIcon;
