import React from 'react';
import Quote from '../../Assets/Images/testimonial-quote-icon.png';
import ClientReviewList from '../../Utils/ClientReviewList';
import { CONSTANT } from '../../Utils/Constant';
import './Testimonial.scss';

const Testimonial = () => {
  console.log('ClientReviewList?.length', ClientReviewList?.length);
  return (
    <div className="testimonial">
      <div className="container">
        <div className="testimonial-common-div">
          {ClientReviewList.map((item, index) => {
            return (
              <div
                key={index}
                className="testimonial-container"
                style={{
                  margin:
                    ClientReviewList?.length % 2 !== 0 && ClientReviewList?.length - 1 === index
                      ? '30px 19.35px'
                      : '30px auto'
                }}
                data-aos="fade-up"
                data-aos-duration={'1' + (index + 1) + '00'}
                data-aos-once="true">
                <div className="testimonial-content">
                  <div className="review-text">
                    <img
                      src={Quote}
                      alt={'Quote | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                      title={'Quote | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                    />
                    <p>{item.clientReview}</p>
                  </div>
                  <div className="testimonial-detail">
                    <p className="client-name">{item?.clientName}</p>
                    <p className="client-position">{item?.clientPosition}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
