import React from 'react';

const AppDevelopmentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      x="0"
      y="0"
      viewBox="0 0 24 24"
      xmlSpace="preserve">
      <g>
        <path
          d="M15.5 24h-7A4.505 4.505 0 0 1 4 19.5v-15C4 2.019 6.019 0 8.5 0h7C17.981 0 20 2.019 20 4.5v15c0 2.481-2.019 4.5-4.5 4.5Zm-7-23C6.57 1 5 2.57 5 4.5v15C5 21.43 6.57 23 8.5 23h7c1.93 0 3.5-1.57 3.5-3.5v-15C19 2.57 17.43 1 15.5 1h-7ZM14 20.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5Z"
          fill="#000000"
          opacity="1"
          data-original="#000000"></path>
      </g>
    </svg>
  );
};

export default AppDevelopmentIcon;
