import React from 'react';

const WebDesignIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      x="0"
      y="0"
      viewBox="0 0 24 24"
      xmlSpace="preserve">
      <g>
        <path
          d="M19.5 1h-15A4.505 4.505 0 0 0 0 5.5v13C0 20.981 2.019 23 4.5 23h15c2.481 0 4.5-2.019 4.5-4.5v-13C24 3.019 21.981 1 19.5 1Zm-15 1h15C21.43 2 23 3.57 23 5.5V8H1V5.5C1 3.57 2.57 2 4.5 2Zm15 20h-15C2.57 22 1 20.43 1 18.5V9h22v9.5c0 1.93-1.57 3.5-3.5 3.5ZM3 5a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm3 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm3 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm8 7.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0Zm-4 0V17c0 1.654-1.346 3-3 3s-3-1.346-3-3v-4.5a.5.5 0 0 1 1 0V17c0 1.103.897 2 2 2s2-.897 2-2v-4.5a.5.5 0 0 1 1 0Z"
          fill="#000000"
          opacity="1"
          data-original="#000000"></path>
      </g>
    </svg>
  );
};

export default WebDesignIcon;
