import React from 'react';
import './HeroText.scss';

const HeroText = ({ title, content, blogAsset, blogAssetAlt }) => {
  return (
    <div className="hero-text">
      <div className="container">
        <h1 data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
          {title}
        </h1>
        <h6 data-aos="fade-up" data-aos-duration="1200" data-aos-once="true">
          {content}
        </h6>
        {blogAsset && (
          <img
            src={blogAsset}
            alt={blogAssetAlt}
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-once="true"
          />
        )}
      </div>
    </div>
  );
};

export default HeroText;
