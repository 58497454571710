import React from 'react';

const UsFlag = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="26"
      height="26"
      viewBox="0 0 48 48">
      <path
        d="M0 0 C9.25842095 1.18767483 18.36752171 1.26079741 27.6875 1.0625 C27.71636537 5.37498609 27.73425255 9.68744762 27.75 14 C27.75837891 15.23169922 27.76675781 16.46339844 27.77539062 17.73242188 C27.77861328 18.90224609 27.78183594 20.07207031 27.78515625 21.27734375 C27.79039307 22.36136475 27.79562988 23.44538574 27.80102539 24.56225586 C27.6875 27.0625 27.6875 27.0625 26.6875 28.0625 C25.34273613 28.16122903 23.99291294 28.19329159 22.64453125 28.1953125 C21.40413086 28.19724609 21.40413086 28.19724609 20.13867188 28.19921875 C19.26791016 28.19535156 18.39714844 28.19148438 17.5 28.1875 C16.22286133 28.19330078 16.22286133 28.19330078 14.91992188 28.19921875 C10.36858398 28.19217878 6.18097198 27.81239611 1.6875 27.0625 C-1.43870887 26.94290462 -4.55961284 26.96757374 -7.6875 27 C-8.51894531 27.00451172 -9.35039062 27.00902344 -10.20703125 27.01367188 C-12.24221897 27.02536836 -14.27736923 27.04330065 -16.3125 27.0625 C-16.34136537 22.75001391 -16.35925255 18.43755238 -16.375 14.125 C-16.38337891 12.89330078 -16.39175781 11.66160156 -16.40039062 10.39257812 C-16.40361328 9.22275391 -16.40683594 8.05292969 -16.41015625 6.84765625 C-16.41539307 5.76363525 -16.42062988 4.67961426 -16.42602539 3.56274414 C-16.3125 1.0625 -16.3125 1.0625 -15.3125 0.0625 C-9.90456301 -1.04130229 -5.3738239 -0.76395507 0 0 Z "
        fill="#EDECED"
        transform="translate(18.3125,9.9375)"
      />
      <path
        d="M0 0 C0.02886537 4.31248609 0.04675255 8.62494762 0.0625 12.9375 C0.07087891 14.16919922 0.07925781 15.40089844 0.08789062 16.66992188 C0.09111328 17.83974609 0.09433594 19.00957031 0.09765625 20.21484375 C0.10289307 21.29886475 0.10812988 22.38288574 0.11352539 23.49975586 C0 26 0 26 -1 27 C-4.3 27 -7.6 27 -11 27 C-11 18.42 -11 9.84 -11 1 C-3 0 -3 0 0 0 Z "
        fill="#F92727"
        transform="translate(46,11)"
      />
      <path
        d="M0 0 C0 8.58 0 17.16 0 26 C-3.63 26.33 -7.26 26.66 -11 27 C-11.02886537 22.68751391 -11.04675255 18.37505238 -11.0625 14.0625 C-11.07087891 12.83080078 -11.07925781 11.59910156 -11.08789062 10.33007812 C-11.09111328 9.16025391 -11.09433594 7.99042969 -11.09765625 6.78515625 C-11.10289307 5.70113525 -11.10812988 4.61711426 -11.11352539 3.50024414 C-11 1 -11 1 -10 0 C-3.57142857 -1.19047619 -3.57142857 -1.19047619 0 0 Z "
        fill="#F73939"
        transform="translate(13,10)"
      />
      <path
        d="M0 0 C2.97 0 5.94 0 9 0 C9 1.65 9 3.3 9 5 C8.34 5 7.68 5 7 5 C6.67 6.65 6.34 8.3 6 10 C5.01 10 4.02 10 3 10 C3.309375 10.721875 3.61875 11.44375 3.9375 12.1875 C4.92560539 14.80307309 5.5287754 17.25118982 6 20 C7.32 20 8.64 20 10 20 C10.33 22.31 10.66 24.62 11 27 C7.37 26.67 3.74 26.34 0 26 C0 17.42 0 8.84 0 0 Z "
        fill="#F9F4F4"
        transform="translate(13,10)"
      />
      <path
        d="M0 0 C0 0.66 0 1.32 0 2 C0.66 2 1.32 2 2 2 C2.268125 2.639375 2.53625 3.27875 2.8125 3.9375 C3.88667752 6.33131686 3.88667752 6.33131686 7 7 C6.41321669 10.52069985 5.64350079 12.71299843 4 16 C0.7 15.67 -2.6 15.34 -6 15 C-7.4216319 11.6828589 -8.39009109 8.55780196 -9 5 C-8.360625 4.896875 -7.72125 4.79375 -7.0625 4.6875 C-6.381875 4.460625 -5.70125 4.23375 -5 4 C-4.67 3.01 -4.34 2.02 -4 1 C-2 0 -2 0 0 0 Z "
        fill="#DA1011"
        transform="translate(25,15)"
      />
      <path
        d="M0 0 C0.99 0 1.98 0 3 0 C3 8.58 3 17.16 3 26 C1.68 26.33 0.36 26.66 -1 27 C-1.02886537 22.68751391 -1.04675255 18.37505238 -1.0625 14.0625 C-1.07087891 12.83080078 -1.07925781 11.59910156 -1.08789062 10.33007812 C-1.09111328 9.16025391 -1.09433594 7.99042969 -1.09765625 6.78515625 C-1.10289307 5.70113525 -1.10812988 4.61711426 -1.11352539 3.50024414 C-1 1 -1 1 0 0 Z "
        fill="#E10404"
        transform="translate(43,11)"
      />
      <path
        d="M0 0 C0.99 0 1.98 0 3 0 C3 8.58 3 17.16 3 26 C1.68 26.33 0.36 26.66 -1 27 C-1.02886537 22.68751391 -1.04675255 18.37505238 -1.0625 14.0625 C-1.07087891 12.83080078 -1.07925781 11.59910156 -1.08789062 10.33007812 C-1.09111328 9.16025391 -1.09433594 7.99042969 -1.09765625 6.78515625 C-1.10289307 5.70113525 -1.10812988 4.61711426 -1.11352539 3.50024414 C-1 1 -1 1 0 0 Z "
        fill="#D50404"
        transform="translate(3,10)"
      />
      <path
        d="M0 0 C1.98 0 3.96 0 6 0 C6 2.97 6 5.94 6 9 C7.32 9.66 8.64 10.32 10 11 C8.35 11 6.7 11 5 11 C5 9.35 5 7.7 5 6 C4.34 6 3.68 6 3 6 C3 5.34 3 4.68 3 4 C1.68 4.33 0.36 4.66 -1 5 C-1 6.65 -1 8.3 -1 10 C-1.66 9.01 -2.32 8.02 -3 7 C-2.67 6.01 -2.34 5.02 -2 4 C-1.34 4 -0.68 4 0 4 C0 2.68 0 1.36 0 0 Z "
        fill="#DECBCC"
        transform="translate(22,11)"
      />
      <path
        d="M0 0 C0.99 0 1.98 0 3 0 C3 3.96 3 7.92 3 12 C1.68 12 0.36 12 -1 12 C-1 9.69 -1 7.38 -1 5 C-0.34 5 0.32 5 1 5 C0.67 3.35 0.34 1.7 0 0 Z "
        fill="#E30203"
        transform="translate(21,17)"
      />
      <path
        d="M0 0 C0 0.99 0 1.98 0 3 C-0.66 3 -1.32 3 -2 3 C-2 5.31 -2 7.62 -2 10 C-1.01 10.33 -0.02 10.66 1 11 C-0.32 11 -1.64 11 -3 11 C-4.4216319 7.6828589 -5.39009109 4.55780196 -6 1 C-3 0 -3 0 0 0 Z "
        fill="#F23B3C"
        transform="translate(22,19)"
      />
      <path
        d="M0 0 C2.475 0.495 2.475 0.495 5 1 C5 2.98 5 4.96 5 7 C3.68 7 2.36 7 1 7 C0.67 4.69 0.34 2.38 0 0 Z "
        fill="#D7BFC0"
        transform="translate(23,30)"
      />
      <path
        d="M0 0 C1.32 0 2.64 0 4 0 C4.33 2.31 4.66 4.62 5 7 C2.69 6.67 0.38 6.34 -2 6 C-1.34 5.67 -0.68 5.34 0 5 C0 3.35 0 1.7 0 0 Z "
        fill="#EAE9EB"
        transform="translate(19,30)"
      />
    </svg>
  );
};

export default UsFlag;
