import React from 'react';

const WebDevelopmentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      x="0"
      y="0"
      viewBox="0 0 24 24"
      xmlSpace="preserve">
      <g>
        <path
          d="M19.5 1h-15A4.505 4.505 0 0 0 0 5.5v9C0 16.981 2.019 19 4.5 19h7v3H7a.5.5 0 0 0 0 1h10a.5.5 0 0 0 0-1h-4.5v-3h7c2.481 0 4.5-2.019 4.5-4.5v-9C24 3.019 21.981 1 19.5 1ZM23 14.5c0 1.93-1.57 3.5-3.5 3.5h-15C2.57 18 1 16.43 1 14.5v-9C1 3.57 2.57 2 4.5 2h15C21.43 2 23 3.57 23 5.5v9Zm-4.732-6.266a2.503 2.503 0 0 1 0 3.536l-3.083 3.083a.498.498 0 0 1-.708 0 .5.5 0 0 1 0-.707l3.083-3.083a1.502 1.502 0 0 0 0-2.122l-3.088-3.088a.5.5 0 0 1 .707-.707l3.088 3.088ZM6.44 8.941a1.502 1.502 0 0 0 0 2.122l3.083 3.083a.5.5 0 0 1-.708.707L5.732 11.77a2.503 2.503 0 0 1 0-3.536L8.82 5.146a.5.5 0 0 1 .707.707L6.439 8.941Z"
          fill="#000000"
          opacity="1"
          data-original="#000000"></path>
      </g>
    </svg>
  );
};

export default WebDevelopmentIcon;
