import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import LeftArrow from '../../Assets/Images/slider-left-background-arrow-button.png';
import RightArrow from '../../Assets/Images/slider-right-background-arrow-button.png';
import { CONSTANT } from '../../Utils/Constant';
import './HeroCaseStudy.scss';

const HeroCaseStudy = ({ caseStudyData }) => {
  var settings1 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: (
      <img
        src={LeftArrow}
        alt={`Case Study | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
        title={`Case Study | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
      />
    ),
    nextArrow: (
      <img
        src={RightArrow}
        alt={`Case Study | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
        title={`Case Study | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
      />
    ),
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true
  };
  var settings2 = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: (
      <img
        src={LeftArrow}
        alt={`Case Study | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
        title={`Case Study | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
      />
    ),
    nextArrow: (
      <img
        src={RightArrow}
        alt={`Case Study | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
        title={`Case Study | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
      />
    ),
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <>
      <div className="heroCaseStudy">
        <div className="case-study">
          <div className="hero-case-study">
            <div
              className={`hero-content bg-primary ${caseStudyData.bgType === 'blue' ? 'bg-blue' : caseStudyData.bgType === 'midnight-slate' ? 'bg-midnight-slate' : ''}`}>
              <div className="container">
                <div className="hero-project-details">
                  <div className="content">
                    <h3>{caseStudyData?.heroTitle}</h3>
                    <span> {caseStudyData?.heroSubTitle}</span>
                  </div>
                  <div className="industry-container">
                    <div className="industry">
                      <h5 data-aos="fade-up" data-aos-duration="1100" data-aos-once="true">
                        Industry
                      </h5>
                      <p data-aos="fade-up" data-aos-duration="1100" data-aos-once="true">
                        {caseStudyData?.industry}
                      </p>
                    </div>
                    <div className="industry">
                      <h5 data-aos="fade-up" data-aos-duration="1100" data-aos-once="true">
                        Deliverables
                      </h5>
                      <p data-aos="fade-up" data-aos-duration="1100" data-aos-once="true">
                        {caseStudyData?.deliverables}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hero-img">
              <div className="container">
                <div className="img">
                  <img src={caseStudyData?.projectImg} alt="hero-img" />
                </div>
              </div>
            </div>
          </div>
          <div className="case-study-content">
            <div className="container">
              <div className="project-content">
                <div className="category-container">
                  <div
                    className="category"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-once="true">
                    <span>Category</span>
                    <p>{caseStudyData?.category}</p>
                  </div>
                  <div
                    className="category"
                    data-aos="fade-up"
                    data-aos-duration="1300"
                    data-aos-once="true">
                    <span>Client</span>
                    <p>{caseStudyData?.client}</p>
                  </div>
                  <div
                    className="category"
                    data-aos="fade-up"
                    data-aos-duration="1400"
                    data-aos-once="true">
                    <span>Technology</span>
                    <p>{caseStudyData?.technology}</p>
                  </div>
                  <div
                    className="category"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-once="true">
                    <span>Location</span>
                    <p>{caseStudyData?.location}</p>
                  </div>
                </div>
                <div
                  className="project-details"
                  data-aos="fade-up"
                  data-aos-duration="1600"
                  data-aos-once="true">
                  <span>{caseStudyData?.contentTitle}</span>
                  <p>{caseStudyData?.description}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="case-study-details">
            <div className="container">
              <div
                className="challenges"
                data-aos="fade-up"
                data-aos-duration="1700"
                data-aos-once="true">
                <span>Challenges</span>
                <p dangerouslySetInnerHTML={{ __html: caseStudyData?.challenges }}></p>
              </div>
              <div
                className="challenges"
                data-aos="fade-up"
                data-aos-duration="1800"
                data-aos-once="true">
                <span>Solution</span>
                <p dangerouslySetInnerHTML={{ __html: caseStudyData?.solution }}></p>
              </div>
              <div
                className="challenges"
                data-aos="fade-up"
                data-aos-duration="1900"
                data-aos-once="true">
                <span>Result</span>
                <p dangerouslySetInnerHTML={{ __html: caseStudyData?.result }}></p>
              </div>
            </div>
          </div>
          <div className="case-study-slider">
            <div className="container">
              <Slider
                className={
                  caseStudyData.slideType === 'web'
                    ? 'project-screenshort-slider variable-width'
                    : 'project-screenshort-slider variable-width mobile-view-slider'
                }
                {...(caseStudyData.slideType === 'web' ? settings1 : settings2)}>
                {caseStudyData.sliderImg.map((imgUrl, index) => (
                  <div className="img-slider" key={index}>
                    <div className="img-screenshort">
                      <img
                        src={imgUrl}
                        alt={`Slider ${index + 1}`}
                        className={caseStudyData.slideType !== 'web' && 'mobile-view-img'}
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroCaseStudy;
