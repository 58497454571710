import React from 'react';

const RightArrow = () => {
  return (
    <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 5.5H14M14 5.5C14 5.5 10.5138 4.21429 8.22222 1M14 5.5C14 5.5 10.5138 6.78571 8.22222 10"
        stroke="#FAC212"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default RightArrow;
