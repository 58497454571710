import React from 'react';
import './CoreValues.scss';
import CreativityImg from '../../Assets/Images/core-value-creativity-icon.png';
import AccountabilityImg from '../../Assets/Images/core-value-accountability-icon.png';
import IntegrityImg from '../../Assets/Images/core-value-integrity-icon.png';
import { CONSTANT } from '../../Utils/Constant';

const CoreValues = () => {
  return (
    <div className="coreValues">
      <div className="container">
        <h4>Our Core Values</h4>
        <div className="core-value">
          <div className="coreValues-container">
            <div className="coreValues-img">
              <img
                src={CreativityImg}
                alt={'Core values Creativity | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                title={'Creativity | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
              />
            </div>
            <p>Creativity</p>
          </div>
          <div className="coreValues-container">
            <div className="coreValues-img">
              <img
                src={AccountabilityImg}
                alt={'Core values accountability | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                title={'Accountability | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
              />
            </div>
            <p>Accountability</p>
          </div>
          <div className="coreValues-container">
            <div className="coreValues-img">
              <img
                src={IntegrityImg}
                alt={'Core values Integrity | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                title={'Integrity | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
              />
            </div>
            <p>Integrity</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoreValues;
