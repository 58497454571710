import React, { useContext, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AOS from 'aos';
import Home from './Pages/Home/Home';
import Blog from './Pages/Blog/Blog';
import Career from './Pages/Career/Career';
import OurTeam from './Pages/OurTeam/OurTeam';
import AboutUs from './Pages/AboutUs/AboutUs';
import Projects from './Pages/Projects/Projects';
import Services from './Pages/Services/Services';
import ContactPage from './Pages/ContactPage/ContactPage';
import PageNotFound from './Pages/PageNotFound/PageNotFound';
import CaseStudies from './Pages/CaseStudiesPage/CaseStudies';
import TestimonialPage from './Pages/Testimonial/TestimonialPage';
import ServicesDetails from './Pages/ServicesDetails/ServicesDetails';
import PrivacyPolicyPage from './Pages/PrivacyPolicy/PrivacyPolicyPage';
import TermsOfServicesPage from './Pages/TermsOfServices/TermsOfServicesPage';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import BlogDetails from './Pages/BlogDetails/BlogDetails';
import { DataContext } from './Context/DataContext';
import { ToastContainer } from 'react-toastify';
function App() {
  const { fetchBlogList, fetchUserIPv4, getDeviceInfo } = useContext(DataContext);
  useEffect(() => {
    AOS.init();
    fetchBlogList();
    fetchUserIPv4();
    getDeviceInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/case-studies',
      element: <Projects />
    },
    {
      path: '/case-study/:companyName',
      element: <CaseStudies />
    },
    {
      path: '/testimonial',
      element: <TestimonialPage />
    },
    {
      path: '/about-us',
      element: <AboutUs />
    },
    {
      path: '/contact-us',
      element: <ContactPage />
    },
    {
      path: '/services',
      element: <Services />
    },
    {
      path: '/service/:serviceName',
      element: <ServicesDetails />
    },
    {
      path: '/career',
      element: <Career />
    },
    {
      path: '/our-team',
      element: <OurTeam />
    },
    {
      path: '/blog',
      element: <Blog />
    },
    {
      path: '/blog/:blogName',
      element: <BlogDetails />
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicyPage />
    },
    {
      path: '/terms-of-service',
      element: <TermsOfServicesPage />
    },
    {
      path: '*',
      element: <PageNotFound />
    }
  ]);

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );
}

export default App;
