import React from 'react';

const UkFlag = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      x="0"
      y="0"
      viewBox="0 0 283.46 283.46"
      style={{ enableBackground: 'new 0 0 512 512' }}
      xmlSpace="preserve">
      <g>
        <path
          fill="#cccbca"
          d="M280.63 235.093c-92.597-56.025-185.2 56.009-277.796 0V48.375c92.596 56.017 185.2-56.017 277.796 0z"
          opacity="1"
          data-original="#cccbca"></path>
        <path
          fill="#2e307f"
          d="M278.354 231.484c-91.073-52.416-182.151 55.084-273.229 2.699V52.015c91.079 52.393 182.156-55.107 273.229-2.69z"
          opacity="1"
          data-original="#2e307f"></path>
        <path
          fill="#ffffff"
          d="M278.354 49.34c-10.935-6.299-21.886-10.284-32.837-12.52-34.594 15.995-69.188 49.522-103.773 83.041-34.594-12.597-69.188-25.195-103.783-55.31-10.947-2.236-21.89-6.221-32.837-12.52v21.886c34.594 42.964 69.188 62.857 103.782 77.209-34.594 31.77-69.188 58.001-103.782 61.17v21.887c10.947 6.291 21.89 10.276 32.837 12.52 34.594-16.019 69.188-49.546 103.782-83.072 34.586 12.613 69.18 25.212 103.773 55.334 10.951 2.244 21.902 6.229 32.837 12.52v-21.886c-34.594-42.972-69.188-62.865-103.782-77.225 34.595-31.77 69.188-57.994 103.782-61.147l.001-21.887z"
          opacity="1"
          data-original="#ffffff"></path>
        <g fill="#d12433">
          <path
            d="M5.125 52.031v10.936c45.541 56.559 91.079 73.149 136.619 89.729v-10.951c-45.541-16.58-91.078-33.156-136.619-89.714zM278.354 220.549c-45.529-56.559-91.073-73.157-136.61-89.752v10.946c45.537 16.6 91.081 33.183 136.61 89.74v-10.934z"
            fill="#d12433"
            opacity="1"
            data-original="#d12433"></path>
          <path
            d="M5.125 234.183c5.472 3.138 10.939 5.711 16.411 7.781 40.069-11.609 80.139-50.439 120.208-89.27v-10.951c-45.541 44.133-91.078 88.282-136.619 92.44zM261.944 41.559c-40.069 11.594-80.139 50.424-120.2 89.254v10.946c45.537-44.128 91.065-88.261 136.595-92.427a111.705 111.705 0 0 0-16.395-7.773z"
            fill="#d12433"
            opacity="1"
            data-original="#d12433"></path>
        </g>
        <path
          fill="#d12633"
          d="M278.354 124.475c-41.732-24.02-83.48-14.458-125.229-2.087V47.254c-7.594 2.251-15.178 4.597-22.772 6.848v75.134c-41.744 12.367-83.485 21.938-125.229-2.071v31.883c41.744 24.005 83.485 14.435 125.229 2.071v75.134c7.594-2.259 15.178-4.597 22.772-6.855v-75.135c41.748-12.362 83.496-21.929 125.229 2.087v-31.875z"
          opacity="1"
          data-original="#d12633"></path>
        <g fill="#fff">
          <path
            d="M121.238 56.746v66.019c-38.704 10.943-77.41 17.552-116.114-4.714v9.115c41.744 24.008 83.485 14.438 125.229 2.071V54.103c-3.039.901-6.075 1.78-9.115 2.643zM5.125 168.148c38.704 22.263 77.41 15.658 116.114 4.707v66.034c3.04-.862 6.076-1.741 9.115-2.636v-75.134c-41.744 12.363-83.485 21.934-125.229-2.071v9.1zM278.354 156.35c-41.732-24.016-83.48-14.449-125.229-2.087v75.135c3.044-.895 6.087-1.773 9.115-2.636v-66.034c38.704-10.936 77.409-17.541 116.113 4.722l.001-9.1zM278.354 115.359c-38.704-22.271-77.409-15.666-116.113-4.73V44.61c-3.028.863-6.071 1.75-9.115 2.644v75.134c41.748-12.371 83.496-21.933 125.229 2.087l-.001-9.116z"
            fill="#ffffff"
            opacity="1"
            data-original="#ffffff"></path>
        </g>
      </g>
    </svg>
  );
};

export default UkFlag;
