import React from 'react';
import { ServiceList } from '../../Utils/ServiceList';
import './ServicesDetails.scss';

const ServicesDetails = () => {
  return (
    <div className="services-details">
      {ServiceList.map((item, i) => {
        return (
          <div
            data-aos="fade-up"
            data-aos-duration={'1' + (i + 0) + '00'}
            data-aos-once="true"
            key={item.id}
            className="details">
            <div className="icon">{item.icon}</div>
            <div className="content">
              <h6>{item.title}</h6>
              <p>{item.content}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ServicesDetails;
