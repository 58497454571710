import React, { useEffect, useRef, useState } from 'react';
import { FaMinus } from 'react-icons/fa';
import { HiPlus } from 'react-icons/hi';
import { API_URL, CONSTANT } from '../../Utils/Constant';
// import OpenPositionList from '../../Utils/OpenPositionList';
import './OpenPosition.scss';
import { Helmet } from 'react-helmet';

const OpenPosition = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [contentHeights, setContentHeights] = useState([]);
  const [posts, setPosts] = useState([]);
  const contentRefs = useRef([]);
  console.log(contentHeights);
  useEffect(() => {
    calculateHeights();
  }, []);

  useEffect(() => {}, [posts]);

  const calculateHeights = () => {
    const heights = contentRefs.current.map((ref) => ref && ref.scrollHeight);
    setContentHeights(heights);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${CONSTANT.BACKEND_API_URL}${API_URL.CAREERS}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setPosts(jsonData.data);
      } catch (error) {
        console.error('Api call failed.');
      }
    };

    fetchData();
  }, []);

  function Image({ image }) {
    return <img src={image.url} alt={image.alternativeText} />;
  }

  function List({ format, children }) {
    const Tag = format === 'ordered' ? 'ol' : 'ul';
    return <Tag>{children}</Tag>;
  }

  function ListItem({ children }) {
    return <li>{children}</li>;
  }

  function Heading({ level, children }) {
    const Tag = `h${level}`;
    return <Tag>{children}</Tag>;
  }

  function Text({ text, bold }) {
    return bold ? <strong>{text}</strong> : <span>{text}</span>;
  }

  function Paragraph({ text }) {
    return (
      <>
        {text.map((ele, ind) => (
          <p key={ind}>
            {Array.isArray(ele) ? (
              ele.map((subEle, subInd) => (
                <Text key={subInd} text={subEle.text} bold={subEle.bold} />
              ))
            ) : (
              <Text text={ele.text} bold={ele.bold} />
            )}
          </p>
        ))}
      </>
    );
  }

  function renderContent(items) {
    return items.map((item, index) => {
      switch (item.type) {
        case 'heading':
          return (
            <Heading key={index} level={item.level}>
              {item.children.map((child, childIndex) => (
                <Text key={childIndex} text={child.text} bold={child.bold} />
              ))}
            </Heading>
          );
        case 'text':
          return <Text key={index} text={item.text} bold={item.bold} />;
        case 'paragraph':
          return <Paragraph key={index} text={item.children} />;
        case 'list':
          return (
            <List key={index} format={item.format}>
              {item.children.map((child, childIndex) => (
                <ListItem key={childIndex}>
                  {child.children.map((listItem, listItemIndex) => (
                    <Text key={listItemIndex} text={listItem.text} bold={listItem.bold} />
                  ))}
                </ListItem>
              ))}
            </List>
          );
        case 'image':
          return <Image key={index} image={item.image} />;
        default:
          return null;
      }
    });
  }
  return (
    <>
      {activeAccordion !== null && (
        <Helmet>
          <title>{posts[activeAccordion]?.attributes?.title}</title>
          <script type="application/ld+json" data-react-helmet="true">
            {JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'JobPosting',
              title: posts[activeAccordion]?.attributes?.title,
              description: posts[activeAccordion]?.attributes?.title,
              identifier: {
                '@type': 'Organization',
                name: CONSTANT?.PIXERFECT_SOLUTIONS_LLP,
                value: CONSTANT?.PIXERFECT_SOLUTIONS_LLP
              },
              hiringOrganization: {
                '@type': 'Organization',
                name: CONSTANT?.PIXERFECT_SOLUTIONS_LLP,
                sameAs: CONSTANT?.PIXERFECT_WEB_LINK,
                logo: `${CONSTANT?.BACKEND_API_URL}/uploads/pixerfect_logo_cf3bc1ab0d.png`
              },
              industry: 'Software Development Industry',
              employmentType: 'FULL_TIME',
              workHours: '9am-6pm',
              datePosted: posts[activeAccordion]?.attributes?.publishedAt,
              jobLocation: {
                '@type': 'Place',
                address: {
                  '@type': 'PostalAddress',
                  streetAddress: 'Silverstone Arcade',
                  addressLocality: 'Surat',
                  postalCode: '395006',
                  addressCountry: 'IN'
                }
              },
              baseSalary: {
                '@type': 'MonetaryAmount',
                currency: 'INR',
                value: {
                  '@type': 'QuantitativeValue',
                  minValue: 5000,
                  maxValue: 200000,
                  unitText: 'MONTH'
                }
              },
              qualifications: 'graduation complete',
              educationRequirements: 'BE, ME, B.Tech, M.Tech, BCA and MCA',
              experienceRequirements: '1year+'
            })}
          </script>
        </Helmet>
      )}
      <div className="container">
        <div className="open-position">
          <h2
            className="why-best-text"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-once="true">
            If you&apos;re as obsessed with tech and <br />
            Design as we are, you&apos;ve come to the right place.
          </h2>
          <h2 className="open-position-title">Open Positions</h2>
          <div className="open-position-accordion">
            {posts &&
              posts.length > 0 &&
              posts.map((item, index) => (
                <div
                  key={index}
                  id={`accordion-list-${index}`}
                  className={`accordion-list ${activeAccordion === index ? 'active' : ''}`}>
                  <button
                    onClick={() => {
                      // if (activeAccordion < index && activeAccordion !== null && index !== 0) {
                      //   window.scrollTo(
                      //     window.scrollX,
                      //     window.scrollY - (contentHeights[index] + 100)
                      //   );
                      // }
                      if (activeAccordion !== index) {
                        setActiveAccordion(index);
                        const element = document.getElementById(`accordion-list-${index}`);
                        if (element) {
                          setTimeout(() => {
                            const topPos =
                              element.getBoundingClientRect().top + window.pageYOffset - 100; // Calculate the top position with an offset of 200px
                            window.scrollTo({ top: topPos, behavior: 'smooth' });
                          }, 500);
                        }
                      } else {
                        setActiveAccordion(null);
                      }
                    }}
                    className="accordion-toggle"
                    type="button">
                    <span className="position-title">
                      <b>{item?.attributes?.title}</b>
                      <span>{`Years of experience:  ${item?.attributes?.yearsOfExperience}+`}</span>
                    </span>
                    {activeAccordion !== index ? <HiPlus size={42} /> : <FaMinus size={32} />}
                  </button>
                  <div
                    className="position-details"
                    ref={(el) => (contentRefs.current[index] = el)}
                    style={{
                      // maxHeight: index === activeAccordion ? contentHeights[index] + 100 + 'px' : 0
                      maxHeight: index === activeAccordion ? 'max-content' : 0
                    }}>
                    <div className="position-details-wrap">
                      {renderContent(posts[activeAccordion || 0]?.attributes?.description)}
                      <div className="apply-btn">
                        <a
                          href={`https://mail.google.com/mail/?view=cm&fs=1&to=${CONSTANT.PIXERFECT_MAIL}&su=Applying for ${item?.attributes?.title}`}
                          title={'Email | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                          className="primary-btn"
                          target="_blank"
                          rel="noreferrer">
                          <span>Apply Now</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <h5
            className="sub-title"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-once="true">
            Got other ideas?
          </h5>
          <p
            className="sub-title-content"
            data-aos="fade-up"
            data-aos-duration="1100"
            data-aos-once="true">
            Send us your portfolio and cv to{' '}
            <strong>
              <a
                href={`https://mail.google.com/mail/?view=cm&fs=1&to=${CONSTANT.PIXERFECT_MAIL}&su= <Your Name>'s Cv`}
                title={'Email | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                className="remove-a-tag-css"
                target="_blank"
                rel="noreferrer">
                {CONSTANT.PIXERFECT_MAIL}
              </a>
            </strong>
          </p>
        </div>
      </div>
    </>
  );
};

export default OpenPosition;
