import RecruitmentProcess1 from '../Assets/Images/recruitment-process/recruitment-process-review-application-cv.png';
import RecruitmentProcess2 from '../Assets/Images/recruitment-process/recruitment-process-interview-invitation.png';
import RecruitmentProcess3 from '../Assets/Images/recruitment-process/recruitment-process-interview.png';
import RecruitmentProcess4 from '../Assets/Images/recruitment-process/recruitment-process-test-task.png';
import RecruitmentProcess5 from '../Assets/Images/recruitment-process/recruitment-process-final-feedback.png';

const RecruitmentProcessList = [
  {
    img: RecruitmentProcess1,
    count: 1,
    title: 'Application Review',
    content:
      'Send us your portfolio (please make it relevant) and a CV (please make it 1 page only). You can apply for one of the open positions or send us your application anyway.'
  },
  {
    img: RecruitmentProcess2,
    count: 2,
    title: 'Interview Invitation',
    content:
      'If you get invited for an interview, just bring your best energy and ideas! You will be interviewed by the people you will be working with: the CEO and your future project manager.'
  },
  {
    img: RecruitmentProcess3,
    count: 3,
    title: 'The Interview',
    content:
      "We're happy to learn more about you and check whether you suit our company culture and job requirements. That's also a great opportunity for you to ask us any questions. We will give you feedback on whether we do or don't invite you to the next step."
  },
  {
    img: RecruitmentProcess4,
    count: 4,
    title: 'Test Task',
    content:
      "If you're a candidate for a junior role, we'll ask you to complete a test task. It will help us learn more about your skills. You also will see what could be your potential tasks."
  },
  {
    img: RecruitmentProcess5,
    count: 5,
    title: "Now You're One of Us!",
    content:
      "Once we've made a decision, we will give you final feedback and invite you to work with us or keep you on file for further opportunities."
  }
];
export default RecruitmentProcessList;
