import { FaAws } from 'react-icons/fa';
import AiDevelopmentImg from '../Assets/Images/ai-development-service.png';
import AppDevelopmentImg from '../Assets/Images/app-development-service.png';
import AwsCloudServicesImg from '../Assets/Images/aws-cloud-services.png';
import DigitalMarketingImg from '../Assets/Images/digital-marketing-service.png';
import GameDevelopmentImg from '../Assets/Images/game-development-service.png';
import AIIcon from '../Assets/Images/how-it-work-icon/ai-icon.png';
import BrowserIcon from '../Assets/Images/how-it-work-icon/browser-icon.png';
import CheckAltIcon from '../Assets/Images/how-it-work-icon/check-alt-icon.png';
import MeetingIcon from '../Assets/Images/how-it-work-icon/meeting-icon.png';
import PadlockCheckIcon from '../Assets/Images/how-it-work-icon/padlock-check-icon.png';
import PlanIcon from '../Assets/Images/how-it-work-icon/plan-icon.png';
import PuzzleIcon from '../Assets/Images/how-it-work-icon/puzzle-icon.png';
import RocketIcon from '../Assets/Images/how-it-work-icon/rocket-icon.png';
import TabletAndroidIcon from '../Assets/Images/how-it-work-icon/tablet-android-icon.png';
import TimeBagIcon from '../Assets/Images/how-it-work-icon/time-bag-icon.png';
import ToDoIcon from '../Assets/Images/how-it-work-icon/to-do-icon.png';
import HowItWorkAiDevelopmentImg from '../Assets/Images/how-it-work-right-img/how-it-works-ai-development.png';
import HowItWorkAppDevelopmentImg from '../Assets/Images/how-it-work-right-img/how-it-works-app-development.png';
import HowItWorkCloudAwsServicesImg from '../Assets/Images/how-it-work-right-img/how-it-works-cloud-aws-services.png';
import HowItWorkDigitalMarketingImg from '../Assets/Images/how-it-work-right-img/how-it-works-digital-marketing.png';
import HowItWorkGameDevelopmentImg from '../Assets/Images/how-it-work-right-img/how-it-works-game-development.png';
import HowItWorkWebDesignImg from '../Assets/Images/how-it-work-right-img/how-it-works-web-design.png';
import HowItWorkWebDevelopmentImg from '../Assets/Images/how-it-work-right-img/how-it-works-web-development.png';
import ServicePageAIDevelopmentImg from '../Assets/Images/services/servicepage-ai-development-icon.png';
import ServicePageAppDevelopmentImg from '../Assets/Images/services/servicepage-app-development-icon.png';
import ServicePageAwsCloudImg from '../Assets/Images/services/servicepage-cloud-aws-services-icon.png';
import ServicePageDigitalMarketingImg from '../Assets/Images/services/servicepage-digital-marketing-icon.png';
import ServicePageGameDevelopmentImg from '../Assets/Images/services/servicepage-game-development-icon.png';
import ServicePageWebDesignImg from '../Assets/Images/services/servicepage-website-design-icon.png';
import ServicePageWebDevelopmentImg from '../Assets/Images/services/servicepage-website-development-icon.png';
import AdobeXdIcon from '../Assets/Images/trusted-partner/adobexd-icon.png';
import AngularIcon from '../Assets/Images/trusted-partner/angular-icon.png';
import AwsIcon from '../Assets/Images/trusted-partner/aws-icon.png';
import BootstrapIcon from '../Assets/Images/trusted-partner/bootstrap-icon.png';
import CssIcon from '../Assets/Images/trusted-partner/css-icon.png';
import DigitaloceanIcon from '../Assets/Images/trusted-partner/digitalocean-icon.png';
import FigmaIcon from '../Assets/Images/trusted-partner/figma-icon.png';
import FirebaseIcon from '../Assets/Images/trusted-partner/firebase-icon.png';
import GoogleCloudIcon from '../Assets/Images/trusted-partner/google-cloud-icon.png';
import HerokuIcon from '../Assets/Images/trusted-partner/heroku-icon.png';
import HtmlIcon from '../Assets/Images/trusted-partner/html-5-icon.png';
import IllustratorIcon from '../Assets/Images/trusted-partner/illustrator-icon.png';
import JavascriptIcon from '../Assets/Images/trusted-partner/javascript-icon.png';
import MongodbIcon from '../Assets/Images/trusted-partner/mongodb-icon.png';
import MysqlIcon from '../Assets/Images/trusted-partner/mysql-icon.png';
import NodejsIcon from '../Assets/Images/trusted-partner/nodejs-icon.png';
import PhotoShopIcon from '../Assets/Images/trusted-partner/photo-shop-icon.png';
import PostgresqlIcon from '../Assets/Images/trusted-partner/postgresql-icon.png';
import ReactJsIcon from '../Assets/Images/trusted-partner/reactjs-icon.png';
import VueJsIcon from '../Assets/Images/trusted-partner/vuejs-icon.png';
import WebDesignImg from '../Assets/Images/web-design-service.png';
import WebDevelopmentImg from '../Assets/Images/web-development-service.png';
import AppGameDevelopmentIcon from '../Assets/Svg/AppGameDevelopmentIcon';
import ChatbotIcon from '../Assets/Svg/ChatbotIcon';
import DigitalMarketingIcon from '../Assets/Svg/DigitalMarketingIcon';
import WebDevelopmentIcon from '../Assets/Svg/WebDevelopmentIcon';
import SketchIcon from '../Assets/Images/trusted-partner/sketch-icon.png';

export const ServiceList = [
  {
    id: 1,
    icon: <WebDevelopmentIcon />,
    title: 'Web Design & Development',
    content: 'Web development and design converge to create captivating online experiences.'
  },
  {
    id: 2,
    icon: <AppGameDevelopmentIcon />,
    title: 'App & Game Development',
    content: "Game and mobile application brings entertainment and utility to users' fingertips."
  },
  {
    id: 3,
    icon: <DigitalMarketingIcon />,
    title: 'Digital Marketing',
    content: 'Digital marketing amplifies brand visibility and engagement across online platforms.'
  },
  {
    id: 4,
    icon: <ChatbotIcon />,
    title: 'AI Development',
    content: 'AI development innovations that revolutionize industries and empower smarter systems.'
  },
  {
    id: 5,
    icon: <FaAws />,
    title: 'Clouds & AWS Migration Services',
    content:
      'Unlocking seamless transitions to AWS, our expert migration services optimize businesses for the cloud era.'
  }
];

export const ServicesPageList = [
  {
    imgUrl: WebDesignImg,
    title: 'Web Design service',
    path: '/service/website-design'
  },
  {
    imgUrl: WebDevelopmentImg,
    title: 'Web Development service',
    path: '/service/website-development'
  },
  {
    imgUrl: AppDevelopmentImg,
    title: 'App Development service',
    path: '/service/application-development'
  },
  {
    imgUrl: GameDevelopmentImg,
    title: 'Game Development service',
    path: '/service/game-development'
  },
  {
    imgUrl: AiDevelopmentImg,
    title: 'AI Development service',
    path: '/service/ai-development'
  },
  {
    imgUrl: DigitalMarketingImg,
    title: 'Digital Marketing service',
    path: '/service/digital-marketing'
  },
  {
    imgUrl: AwsCloudServicesImg,
    title: 'Clouds & AWS Services',
    path: '/service/cloud-aws'
  }
];

export const ServicesPageDetailsList = [
  {
    imgUrl: ServicePageWebDevelopmentImg,
    title: 'Website Development Service',
    params: 'website-development',
    descriptionTitle: 'Endless Possibilities With Our Design Team',
    descriptionContent:
      'Web developers harness an arsenal of programming languages, frameworks, and tools to architect robust digital solutions that cater to diverse user needs. we produce websites and CMS that make your life easier.',
    keyword: [
      'Website Development',
      'MVC Frameworks',
      'Content Management Systems',
      'Responsive Website Development',
      'Hosting And Maintenance',
      'Membership Subscription Site'
    ],
    howItWorksContent:
      'We build sites that answer your company’s specific call to action. We value performance and reliability as much as we do beauty. Clients collaborate with information on their values, goals, concerns and user pathways to build a brilliant site.',
    howItWorksSubTitle: 'Experts at Pixerfect Solutions follow a thorough process for development.',
    howItWorksStep: [
      {
        icon: MeetingIcon,
        name: 'Initial Consultation'
      },
      {
        icon: TabletAndroidIcon,
        name: 'Research and Information'
      },
      {
        icon: PlanIcon,
        name: 'Strategy Formation'
      },
      {
        icon: BrowserIcon,
        name: 'Website Architecture'
      },
      {
        icon: ToDoIcon,
        name: 'Development'
      },
      {
        icon: CheckAltIcon,
        name: 'Quality Assurance'
      },
      {
        icon: RocketIcon,
        name: 'Final Optimization'
      }
    ],
    howItWorksImg: HowItWorkWebDevelopmentImg,
    trustedPartner: [
      {
        name: 'React Js',
        icon: ReactJsIcon
      },
      {
        name: 'Angular',
        icon: AngularIcon
      },
      {
        name: 'Vue js',
        icon: VueJsIcon
      },
      {
        name: 'Node JS',
        icon: NodejsIcon
      },
      {
        name: 'Html',
        icon: HtmlIcon
      },
      {
        name: 'Css',
        icon: CssIcon
      },
      {
        name: 'Mongodb',
        icon: MongodbIcon
      },
      {
        name: 'Javascript',
        icon: JavascriptIcon
      },
      {
        name: 'My sql',
        icon: MysqlIcon
      },
      {
        name: 'Bootstrap',
        icon: BootstrapIcon
      },
      {
        name: 'Firebase',
        icon: FirebaseIcon
      },
      {
        name: 'Postgresql',
        icon: PostgresqlIcon
      }
    ],
    serviceContent:
      'At PixerFect Solutions LLP, we are passionate about crafting exceptional websites that leave a lasting impression. Our Website Development service is designed to bring your unique vision to life and help your business thrive in the digital world.'
  },
  {
    imgUrl: ServicePageAppDevelopmentImg,
    title: 'Application Development Service',
    params: 'application-development',
    descriptionTitle: 'Endless Possibilities With Our Design Team',
    descriptionContent:
      'Application development is characterized by a relentless pursuit of excellence, with a focus on staying ahead of emerging technologies and industry trends to deliver cutting-edge solutions that drive business growth and user satisfaction.',
    keyword: [
      'Android App Development',
      'Native Android App Development',
      'iOS App Development',
      'Progressive Web Apps',
      'Flutter App Development',
      'Cross-Platform App Development'
    ],
    howItWorksContent:
      'We create dynamic mobile apps that work on any device. This starts with a detailed review of your current app or desires, data from current trends in your industry and several testing phases to ensure a smooth launch.',
    howItWorksSubTitle: 'Experts at Pixerfect Solutions follow a thorough process for development.',
    howItWorksStep: [
      {
        icon: MeetingIcon,
        name: 'Initial Consultation'
      },
      {
        icon: PlanIcon,
        name: 'Strategy Formation'
      },
      {
        icon: TabletAndroidIcon,
        name: 'Research and Strategy'
      },
      {
        icon: BrowserIcon,
        name: 'Application Architecture'
      },
      {
        icon: ToDoIcon,
        name: 'Design & Development'
      },
      {
        icon: CheckAltIcon,
        name: 'Quality Assurance'
      },
      {
        icon: RocketIcon,
        name: 'Final Optimization'
      }
    ],
    howItWorksImg: HowItWorkAppDevelopmentImg,
    trustedPartner: [],
    serviceContent:
      'At PixerFect Solutions LLP, we specialize in mobile app development using the powerful and versatile Flutter framework. With Flutter, we can create high-quality, cross-platform mobile applications that run seamlessly on both iOS and Android devices'
  },
  {
    imgUrl: ServicePageDigitalMarketingImg,
    title: 'Digital Marketing Service',
    params: 'digital-marketing',
    descriptionTitle: 'Endless Possibilities are with our marketing team',
    descriptionContent:
      'We encompass a diverse array of channels, including social media, search engines, email, and content marketing. All aimed at reaching and resonating with target demographics.',
    keyword: [
      'Content & Blog Marketing',
      'Paid Advertising & PPC Campaigns',
      'Social Media Marketing (SMM)',
      'Search Engine Optimization (SEO)',
      'Remarketing And Retargeting',
      'Newsletter Marketing'
    ],
    howItWorksContent:
      'Each marketing campaign we design begins with a comprehensive understanding of not only your business but your avatar. We research where your target audience spends their time and what they’re drawn to and translate this data into a profitable marketing plan.',
    howItWorksSubTitle: 'Experts at Pixerfect Solutions follow a thorough process for development.',
    howItWorksStep: [
      {
        icon: MeetingIcon,
        name: 'Evaluation'
      },
      {
        icon: TabletAndroidIcon,
        name: 'Competitor analysis'
      },
      {
        icon: PlanIcon,
        name: 'Relevant keywords'
      },
      {
        icon: BrowserIcon,
        name: 'Content'
      },
      {
        icon: ToDoIcon,
        name: 'Technical SEO'
      },
      {
        icon: RocketIcon,
        name: 'Link profile'
      }
    ],
    howItWorksImg: HowItWorkDigitalMarketingImg,
    trustedPartner: [],
    serviceContent:
      "At PixerFect Solutions LLP, we specialize in curating customized digital marketing solutions that embody your brand's identity and address precise customer requirements. Our dedicated team ensures that each digital marketing endeavor authentically reflects your brand."
  },
  {
    imgUrl: ServicePageAwsCloudImg,
    title: 'Cloud & AWS Services',
    params: 'cloud-aws',
    descriptionTitle: 'Endless Possibilities are with our team',
    descriptionContent:
      'AWS offers a comprehensive suite of cloud services, including computing power, storage, databases, machine learning, and more, allowing organizations to innovate and scale rapidly without the burden of managing physical hardware.',
    keyword: [
      'Scale Horizontally For Growth.',
      'Maximize Uptime, Availability.',
      'Encryption, Access Control, Monitoring.',
      'Graceful Failure Handling, Fast Recovery.',
      'Automate For Efficiency, Reduce Human Error.',
      'Optimize Cloud Costs: Reserved Instances, Auto-scaling.'
    ],
    howItWorksContent:
      'Deploying and managing applications on AWS, Heroku, Google Cloud, and more. Utilizing tools like Kubernetes, Docker, and Terraform for containerization and orchestration.',
    howItWorksSubTitle: 'Experts at Pixerfect Solutions follow a thorough process for development.',
    howItWorksStep: [
      {
        icon: MeetingIcon,
        name: 'Initial Consultation'
      },
      {
        icon: TabletAndroidIcon,
        name: 'Infrastructure'
      },
      {
        icon: PlanIcon,
        name: 'Service Access'
      },
      {
        icon: ToDoIcon,
        name: 'Resource Deployment'
      },
      {
        icon: PadlockCheckIcon,
        name: 'Security and Compliance'
      },
      {
        icon: TimeBagIcon,
        name: 'Monitoring and Management'
      },
      {
        icon: RocketIcon,
        name: 'Final Optimization'
      }
    ],
    howItWorksImg: HowItWorkCloudAwsServicesImg,
    trustedPartner: [
      {
        name: 'Aws Services',
        icon: AwsIcon
      },
      {
        name: 'Digital Ocean',
        icon: DigitaloceanIcon
      },
      {
        name: 'Firebase',
        icon: FirebaseIcon
      },
      {
        name: 'Heroku',
        icon: HerokuIcon
      },
      {
        name: 'Google Cloud',
        icon: GoogleCloudIcon
      }
    ]
  },
  {
    imgUrl: ServicePageWebDesignImg,
    title: 'Website Design Service',
    params: 'website-design',
    descriptionTitle: 'Endless Possibilities With Our Design Team',
    descriptionContent:
      'From intuitive navigation to responsive layouts, professional web designers seamlessly merge creativity with technical expertise to build websites that not only look stunning but also function flawlessly across all devices.',
    keyword: ['Website Design', 'Frameworks', 'Responsive Website Design'],
    howItWorksContent:
      'We build sites that answer your company’s specific call to action. We value performance and reliability as much as we do beauty. Clients collaborate with Information on their values, goals, concerns and user pathways to build a brilliant site.',
    howItWorksSubTitle: 'Experts at Pixerfect Solutions follow a thorough process for development.',
    howItWorksStep: [
      {
        icon: MeetingIcon,
        name: 'Initial Consultation'
      },
      {
        icon: TabletAndroidIcon,
        name: 'Research and Information'
      },
      {
        icon: PlanIcon,
        name: 'Strategy Formation'
      },
      {
        icon: BrowserIcon,
        name: 'Website Architecture'
      },
      {
        icon: ToDoIcon,
        name: 'Design'
      },
      {
        icon: RocketIcon,
        name: 'Final Optimization'
      }
    ],
    howItWorksImg: HowItWorkWebDesignImg,
    trustedPartner: [
      {
        name: 'Figma',
        icon: FigmaIcon
      },
      {
        name: 'Adobe Illustrator',
        icon: IllustratorIcon
      },
      {
        name: 'Adobe Photoshop',
        icon: PhotoShopIcon
      },
      {
        name: 'Adobe XD',
        icon: AdobeXdIcon
      },
      {
        name: 'Sketch',
        icon: SketchIcon
      },
      {
        name: 'Javascript',
        icon: JavascriptIcon
      },
      {
        name: 'Html',
        icon: HtmlIcon
      },
      {
        name: 'Css',
        icon: CssIcon
      }
    ],
    serviceContent:
      'At PixerFect Solutions LLP, we are passionate about crafting exceptional websites that leave a lasting impression. Our Website Development service is designed to bring your unique vision to life and help your business thrive in the digital world.'
  },
  {
    imgUrl: ServicePageGameDevelopmentImg,
    title: 'Game Development Service',
    params: 'game-development',
    descriptionTitle: 'Endless Possibilities With Our Design Team',
    descriptionContent:
      'From concept art to coding, every aspect of the development process is meticulously honed to deliver polished, high-quality experiences that resonate with players.',
    keyword: [
      'Game development service',
      'Game design company',
      'Video game development',
      'Custom game development',
      'Game development process',
      'Game programming',
      'Game development studio',
      'Mobile game development',
      'Unity game development',
      '3D game development'
    ],
    howItWorksContent:
      'We build games that answer your company’s specific call to action. We value performance and reliability as much as we do beauty. Clients collaborate with Information on their values, goals, concerns and user pathways to build a brilliant site.',
    howItWorksSubTitle: 'Experts at Pixerfect Solutions follow a thorough process for development.',
    howItWorksStep: [
      {
        icon: MeetingIcon,
        name: 'Initial Consultation'
      },
      {
        icon: TabletAndroidIcon,
        name: 'Research and Information'
      },
      {
        icon: PlanIcon,
        name: 'Strategy Formation'
      },
      {
        icon: PuzzleIcon,
        name: 'Platform Architecture'
      },
      {
        icon: ToDoIcon,
        name: 'Development'
      },
      {
        icon: CheckAltIcon,
        name: 'Quality Assurance'
      },
      {
        icon: RocketIcon,
        name: 'Final Optimization'
      }
    ],
    howItWorksImg: HowItWorkGameDevelopmentImg,
    trustedPartner: [],
    serviceContent:
      'At PixerFect Solutions LLP, we are passionate about crafting exceptional websites that leave a lasting impression. Our Website Development service is designed to bring your unique vision to life and help your business thrive in the digital world.'
  },
  {
    imgUrl: ServicePageAIDevelopmentImg,
    title: 'AI Development Service',
    params: 'ai-development',
    descriptionTitle: 'Endless Possibilities With Our Design Team',
    descriptionContent:
      'AI developers leverage advanced algorithms, machine learning techniques, and computational resources to create intelligent applications that solve complex problems across diverse domains. involves the design, training, and deployment of intelligent systems.',
    keyword: [
      'AI development service',
      'Artificial intelligence development',
      'AI solutions',
      'Machine learning development',
      'Chatbot development',
      'Natural language processing',
      'AI integration',
      'AI architecture',
      'Intelligent systems development',
      'AI application development'
    ],
    howItWorksContent:
      'We build your business with intelligent chatbot solutions tailored to your needs. Enhance user engagement and streamline operations with bots designed for precision and efficiency.',
    howItWorksSubTitle: 'Experts at Pixerfect Solutions follow a thorough process for development.',
    howItWorksStep: [
      {
        icon: MeetingIcon,
        name: 'Chatbot Design'
      },
      {
        icon: TabletAndroidIcon,
        name: 'Multi Platform Integration'
      },
      {
        icon: PlanIcon,
        name: 'Natural Language Process'
      },
      {
        icon: AIIcon,
        name: 'AI Architecture'
      },
      {
        icon: ToDoIcon,
        name: 'Design & Development'
      },
      {
        icon: CheckAltIcon,
        name: 'Quality Assurance'
      },
      {
        icon: RocketIcon,
        name: 'Final launch'
      }
    ],
    howItWorksImg: HowItWorkAiDevelopmentImg,
    trustedPartner: [],
    serviceContent:
      'At PixerFect Solutions LLP, we are passionate about crafting exceptional websites that leave a lasting impression. Our Website Development service is designed to bring your unique vision to life and help your business thrive in the digital world.'
  }
];
