import React from 'react';
import './Agencies.scss';
import AgencySlider from './AgencySlider';

const Agencies = () => {
  return (
    <div className="agencies">
      <div className="container">
        <div className="agencies-header">
          <h4>For Agencies By Agency</h4>
          <p>Spanning Innovative Client Acquisition Systems To Agency Owners.</p>
        </div>
        <div>
          <AgencySlider />
        </div>
      </div>
    </div>
  );
};

export default Agencies;
