import React from 'react';
import ContactForm from '../Contact/ContactForm';
import ContactDetails from '../Footer/ContactDetails';
import OurLocation from '../Footer/OurLocation';
import './ContactDetail.scss';

const ContactDetail = () => {
  return (
    <div className="contact-details">
      <div className="container">
        <h1>Tell Us What You Need, We&apos;re Listening</h1>
        <span className="text">
          Let&apos;s Get Down Business And By Business We Mean Providing A Perfect Solution
          Experience
        </span>
        <div className="contact-detail">
          <div className="contact-forms">
            <ContactForm />
          </div>
          <div className="contact-info-container">
            <span
              className="contact-text"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true">
              Let&apos;s Get Down Business And By Business We Mean Providing A Perfect Solution
              Experience
            </span>
            <div className="contact-info-details">
              <div className="location">
                <OurLocation animate={true} />
              </div>
              <div className="contact-lists">
                <ContactDetails animate={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetail;
