import React from 'react';

const AiIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      x="0"
      y="0"
      viewBox="0 0 24 24"
      xmlSpace="preserve">
      <g>
        <path
          d="M23.5 9a.5.5 0 0 0 0-1H22V6.5c0-.169-.01-.336-.028-.5H23.5a.5.5 0 0 0 0-1h-1.757A4.523 4.523 0 0 0 19 2.257V.5a.5.5 0 0 0-1 0v1.528A4.583 4.583 0 0 0 17.5 2H16V.5a.5.5 0 0 0-1 0V2h-2.5V.5a.5.5 0 0 0-1 0V2H9V.5a.5.5 0 0 0-1 0V2H6.5c-.169 0-.336.01-.5.028V.5a.5.5 0 0 0-1 0v1.757A4.523 4.523 0 0 0 2.257 5H.5a.5.5 0 0 0 0 1h1.528A4.583 4.583 0 0 0 2 6.5V8H.5a.5.5 0 0 0 0 1H2v2.5H.5a.5.5 0 0 0 0 1H2V15H.5a.5.5 0 0 0 0 1H2v1.5c0 .169.01.336.028.5H.5a.5.5 0 0 0 0 1h1.757A4.523 4.523 0 0 0 5 21.743V23.5a.5.5 0 0 0 1 0v-1.528c.164.018.331.028.5.028H8v1.5a.5.5 0 0 0 1 0V22h2.5v1.5a.5.5 0 0 0 1 0V22H15v1.5a.5.5 0 0 0 1 0V22h1.5c.169 0 .336-.01.5-.028V23.5a.5.5 0 0 0 1 0v-1.757A4.523 4.523 0 0 0 21.743 19H23.5a.5.5 0 0 0 0-1h-1.528c.018-.164.028-.331.028-.5V16h1.5a.5.5 0 0 0 0-1H22v-2.5h1.5a.5.5 0 0 0 0-1H22V9h1.5Zm-6 12h-11C4.57 21 3 19.43 3 17.5v-11C3 4.57 4.57 3 6.5 3h11C19.43 3 21 4.57 21 6.5v11c0 1.93-1.57 3.5-3.5 3.5ZM17 7.5v9a.5.5 0 0 1-1 0v-9a.5.5 0 0 1 1 0Zm-5.162.872c-.215-.812-1.022-1.376-1.857-1.371a1.87 1.87 0 0 0-1.698 1.363l-2.265 8a.5.5 0 1 0 .962.272l.744-2.628H12.313l.703 2.622a.505.505 0 0 0 .612.354.501.501 0 0 0 .354-.613l-2.145-7.998Zm-3.83 4.636 1.24-4.38a.863.863 0 0 1 .77-.627c.378-.033.756.262.854.629l1.174 4.378H8.008Z"
          fill="#000000"
          opacity="1"
          data-original="#000000"></path>
      </g>
    </svg>
  );
};

export default AiIcon;
