import React from 'react';
import './Contact.scss';
import ContactForm from './ContactForm';
import ContactDetails from './ContactDetails';

const Contact = () => {
  return (
    <div className="container">
      <div className="contact">
        <ContactForm />
        <ContactDetails />
      </div>
    </div>
  );
};

export default Contact;
