import { FaAws } from 'react-icons/fa';
import AiIcon from '../Assets/Svg/AiIcon';
import AppDevelopmentIcon from '../Assets/Svg/AppDevelopmentIcon';
// import BookIcon from '../Assets/Svg/BookIcon';
// import RocketIcon from '../Assets/Svg/RocketIcon';
// import FashionIcon from '../Assets/Svg/FashionIcon';
// import BuildingIcon from '../Assets/Svg/BuildingIcon';
// import PieChartIcon from '../Assets/Svg/PieChartIcon';
// import ECommerceIcon from '../Assets/Svg/ECommerceIcon';
// import HealthCareIcon from '../Assets/Svg/HealthCareIcon';
import BriefCaseIcon from '../Assets/Svg/BriefCaseIcon';
import DigitalMarketingIcon from '../Assets/Svg/DigitalMarketingIcon';
import GameDevelopmentIcon from '../Assets/Svg/GameDevelopmentIcon';
import TeamIcon from '../Assets/Svg/TeamIcon';
import TrophyIcon from '../Assets/Svg/TrophyIcon';
import WebDesignIcon from '../Assets/Svg/WebDesignIcon';
import WebDevelopmentIcon from '../Assets/Svg/WebDevelopmentIcon';
import { CONSTANT } from './Constant';

const MenuList = [
  // {
  //   name: 'Industries',
  //   title: `${CONSTANT.PIXERFECT_SOLUTIONS_LLP} - Industries`,
  //   path: '/',
  //   isSubMenu: true,
  //   subMenu: {
  //     subMenuName: 'Industries',
  //     subMenuDes:
  //       'Discover industry specific section tailored to meet the unique challenges and opportunities of sector, leveraging our extensive cross-industries expertise.',
  //     isViewAllBtn: true,
  //     viewAllPath: '/',
  //     subMenuList: [
  //       {
  //         name: 'E-Commerce',
  //         icon: <ECommerceIcon />,
  //         title: 'E-Commerce web and app development company in Surat, India',
  //         path: '/'
  //       },
  //       {
  //         name: 'Business',
  //         icon: <PieChartIcon />,
  //         title: 'Business development company in Surat, India',
  //         path: '/'
  //       },
  //       {
  //         name: 'Government',
  //         icon: <BuildingIcon />,
  //         title: 'Government web and app development company in Surat, India',
  //         path: '/'
  //       },
  //       {
  //         name: 'Start-ups',
  //         icon: <RocketIcon />,
  //         title: 'Start-up Solutions company in Surat, India',
  //         path: '/'
  //       },
  //       {
  //         name: 'Technology',
  //         icon: <AiIcon />,
  //         title: 'Technology Solutions company in Surat, India',
  //         path: '/'
  //       },
  //       {
  //         name: 'Healthcare',
  //         icon: <HealthCareIcon />,
  //         title: 'Healthcare web and app development company in Surat, India',
  //         path: '/'
  //       },
  //       {
  //         name: 'Education',
  //         icon: <BookIcon />,
  //         title: 'Education web and app development company in Surat, India',
  //         path: '/'
  //       },
  //       {
  //         name: 'Fashion & Beauty',
  //         icon: <FashionIcon />,
  //         title: 'Fashion & Beauty web and app development company in Surat, India',
  //         path: '/'
  //       }
  //     ]
  //   }
  // },
  {
    name: 'Services',
    title: `${CONSTANT.PIXERFECT_SOLUTIONS_LLP} - Service`,
    path: '/services',
    isSubMenu: true,
    subMenu: {
      subMenuName: 'Services',
      subMenuDes:
        'Explore our comprehensive range of development services designed to bring your digital product to life, from ideation to launch and beyond.',
      isViewAllBtn: true,
      viewAllPath: '/services',
      subMenuList: [
        {
          name: 'Web Design',
          icon: <WebDesignIcon />,
          title: 'Web design company in Surat, India',
          path: '/service/website-design'
        },
        {
          name: 'Web Development',
          icon: <WebDevelopmentIcon />,
          title: 'Web development company in Surat, India',
          path: '/service/website-development'
        },
        {
          name: 'App Development',
          icon: <AppDevelopmentIcon />,
          title: 'App development company in Surat, India',
          path: '/service/application-development'
        },
        {
          name: 'Game Development',
          icon: <GameDevelopmentIcon />,
          title: 'Game development company in Surat, India',
          path: '/service/game-development'
        },
        {
          name: 'Digital Marketing',
          icon: <DigitalMarketingIcon />,
          title: 'Digital marketing company in Surat, India',
          path: '/service/digital-marketing'
        },
        {
          name: 'AI Development',
          icon: <AiIcon />,
          title: 'AI development company in Surat, India',
          path: '/service/ai-development'
        },
        {
          name: 'Clouds & AWS Migration Services',
          icon: <FaAws />,
          title: 'Clouds & AWS migration services company in Surat, India',
          path: '/service/cloud-aws'
        }
      ]
    }
  },
  {
    name: 'Company',
    title: `${CONSTANT.PIXERFECT_SOLUTIONS_LLP} - Company`,
    path: '/',
    isSubMenu: true,
    subMenu: {
      subMenuName: 'Company',
      subMenuDes:
        'Learn more about our mission, vision, values and the talented team behind our success. See how our commitment to excellence shape every product we undertake.',
      isViewAllBtn: false,
      viewAllPath: '/',
      subMenuList: [
        {
          name: 'Meet The Team',
          icon: <TeamIcon />,
          title: `${CONSTANT.PIXERFECT_SOLUTIONS_LLP} - Meet The Team`,
          path: '/our-team'
        },
        {
          name: 'About Us',
          icon: <TrophyIcon />,
          title: `${CONSTANT.PIXERFECT_SOLUTIONS_LLP} - About Us`,
          path: '/about-us'
        },
        {
          name: 'Career',
          icon: <BriefCaseIcon />,
          title: `${CONSTANT.PIXERFECT_SOLUTIONS_LLP} - Career`,
          path: '/career'
        }
      ]
    }
  },
  {
    name: 'Case Studies',
    title: `${CONSTANT.PIXERFECT_SOLUTIONS_LLP} - Case Studies`,
    path: '/case-studies',
    isSubMenu: false
  },
  {
    name: 'Testimonial',
    title: `${CONSTANT.PIXERFECT_SOLUTIONS_LLP} - Testimonial`,
    path: '/testimonial',
    isSubMenu: false
  },
  {
    name: 'Blog',
    title: `${CONSTANT.PIXERFECT_SOLUTIONS_LLP} - Blog`,
    path: '/blog',
    isSubMenu: false
  }
];
export default MenuList;
