import React from 'react';
import './BlogBottomSlider.scss';
import Slider from 'react-slick';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';

function BlogBottomSlider({ sliderData }) {
  const navigate = useNavigate();
  var settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: 'linear',

    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          speed: 1000,
          autoplaySpeed: 2500
        }
      }
    ]
  };
  const blogOpenHandler = (slug) => {
    navigate(`/blog/${slug}`);
    window.scrollTo(0, 0);
  };
  return (
    <div className="blog-slick-slider">
      {sliderData.length > 1 && (
        <Slider {...settings}>
          {sliderData?.map((item, index) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              key={index}
              className="blog-slider-card"
              onClick={() => blogOpenHandler(item?.slug)}
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-once="true">
              <div className="card-img-div">
                <img src={item?.img} alt={item?.title} />
              </div>
              <div className="card-content-div">
                <div>
                  <h4>{item?.title}</h4>
                  <p className="card-description">{item?.description}</p>
                </div>
                <div className="card-date">
                  <p>{moment(item?.date).format('DD MMMM, YYYY').toUpperCase()}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
}

export default BlogBottomSlider;
