import React from 'react';
import './ContactDetails.scss';
import { GoCheck } from 'react-icons/go';

const ContactDetails = () => {
  return (
    <div className="contactDetails">
      <h4 data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
        Connect With Us
      </h4>
      <h2 data-aos="fade-up" data-aos-duration="1100" data-aos-once="true">
        Make Magic Happen!{' '}
      </h2>
      <p data-aos="fade-up" data-aos-duration="1200" data-aos-once="true">
        Drop us a line, and we will be thrilled to hear from you.
      </p>
      <div>
        <div className="points" data-aos="fade-up" data-aos-duration="1300" data-aos-once="true">
          <GoCheck className="checkIcon" />
          <span>Tell us about your requirement</span>
        </div>
        <div className="points" data-aos="fade-up" data-aos-duration="1400" data-aos-once="true">
          <GoCheck className="checkIcon" />
          <span>Brief us about your business</span>
        </div>
        <div className="points" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
          <GoCheck className="checkIcon" />
          <span>Find out if we&apos;re a good fit</span>
        </div>
        <div className="points" data-aos="fade-up" data-aos-duration="1600" data-aos-once="true">
          <GoCheck className="checkIcon" />
          <span>Get the details about our service</span>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
