import React from 'react';

const ArrowRightIcon = () => {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0V7.00699L15.708 9L0 10.993V18L22 9L0 0Z" fill="#FAC212" />
    </svg>
  );
};

export default ArrowRightIcon;
