import Slide1 from '../Assets/Images/e-commerce-industry.png';
import Slide2 from '../Assets/Images/startups-industry.png';
import Slide3 from '../Assets/Images/business-to-business-industry.png';
import Slide4 from '../Assets/Images/enterprise-industry.png';
import Slide5 from '../Assets/Images/food-and-beverage-industry.png';
import Slide6 from '../Assets/Images/entertainment-and-media-industry.png';
import Slide7 from '../Assets/Images/business-to-consumer-industry.png';
import Slide8 from '../Assets/Images/real-estate-industry.png';
import Slide9 from '../Assets/Images/non-profit-industry.png';
import Slide10 from '../Assets/Images/schools-and-institutions-industry.png';
import Slide11 from '../Assets/Images/technology-services-industry.png';
import Slide12 from '../Assets/Images/medical-healthcare-industry.png';
import Slide13 from '../Assets/Images/beauty-and-fashion-industry.png';
import Slide14 from '../Assets/Images/financial-industry.png';
import Slide15 from '../Assets/Images/small-business-industry.png';
import Slide16 from '../Assets/Images/luxury-items-industry.png';
import Slide17 from '../Assets/Images/publications-industry.png';
import Slide18 from '../Assets/Images/travel-industry.png';
import Slide19 from '../Assets/Images/automotive-industry.png';
import Slide20 from '../Assets/Images/sports-industry.png';
import Slide21 from '../Assets/Images/education-industry.png';

const WeServeSlideList = [
  {
    title: 'E-Commerce',
    content:
      'Increase your sales with a dynamic webstore that reaches more customers with seamless transaction capabilities.',
    imgUrl: Slide1
  },
  {
    title: 'Startups',
    content:
      'We serve ambitious startups and solo entrepreneurs looking to build and grow their online presence.',
    imgUrl: Slide2
  },
  {
    title: 'Business to Business',
    content:
      'Experience the power of a broader professional network by connecting with industry peers who need your services.',
    imgUrl: Slide3
  },
  {
    title: 'Enterprise',
    content:
      'Establish yourself as an industry leader with digital tools powerful enough for the largest, most complex professional networks.',
    imgUrl: Slide4
  },
  {
    title: 'Food & Beverage',
    content:
      'From the latest steakhouse to the newest brand of coffee, become a household name with attractive marketing assets.',
    imgUrl: Slide5
  },
  {
    title: 'Entertainment & Media',
    content:
      'For one of the world’s largest industries, start with the latest technology and accessibility across all platforms.',
    imgUrl: Slide6
  },
  {
    title: 'Business to Consumer',
    content:
      'Reach more users, turn clicks into dollars and build a brand that consumers can trust.',
    imgUrl: Slide7
  },
  {
    title: 'Real Estate',
    content:
      'Showcase homes, apartments and properties to prospective buyers and schedule virtual showings all in one place.',
    imgUrl: Slide8
  },
  {
    title: 'Non-profit',
    content:
      'Serve with efficient digital solutions, virtual conferencing, and advance community-based efforts',
    imgUrl: Slide9
  },
  {
    title: 'Schools & Institutions',
    content:
      "Tap into the power of a large interface and easily accessible data to meet your organization's needs",
    imgUrl: Slide10
  },
  {
    title: 'Technology Services',
    content:
      'Show up and stand out with state-of-the-art digital techniques to boost your company profile.',
    imgUrl: Slide11
  },
  {
    title: 'Medical & Healthcare',
    content:
      'In a field that requires the highest level of care, make every detail feel like a healing touch.',
    imgUrl: Slide12
  },
  {
    title: 'Beauty & Fashion',
    content: 'Craft a brand that is equally stunning as the products you create.',
    imgUrl: Slide13
  },
  {
    title: 'Financial',
    content:
      'Security, knowledge & commitment. Create a secure, reputable presence with personalized branding.',
    imgUrl: Slide14
  },
  {
    title: 'Small Businesses',
    content:
      'Client relationships are the heart of your business – wow them with better productivity and more ways to experience the personalized service they love.',
    imgUrl: Slide15
  },
  {
    title: 'Luxury',
    content: 'For those who seek the best, deliver the exceptional at every turn.',
    imgUrl: Slide16
  },
  {
    title: 'Publication',
    content:
      'Create gorgeous print ads, brochures and internal communications and share your story with a wider audience.',
    imgUrl: Slide17
  },
  {
    title: 'Travel',
    content:
      'From business meetings to anniversary trips, empower travelers with state-of-the-art check-in and booking services.',
    imgUrl: Slide18
  },
  {
    title: 'Automotive',
    content:
      "Become a leader in one of the world's largest industries and engage with an expansive, diverse client base.",
    imgUrl: Slide19
  },
  {
    title: 'Sports',
    content:
      'Deliver the kind of experience every fan dreams about with modern digital interfaces and expansive online reach.',
    imgUrl: Slide20
  },
  {
    title: 'Education',
    content:
      'Build a platform to dispense valuable information and interpret crucial concepts with engaging, innovative design.',
    imgUrl: Slide21
  }
];
export default WeServeSlideList;
