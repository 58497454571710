import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import LeftArrow from '../../Assets/Images/slider-left-arrow-with-shadow.png';
import RightArrow from '../../Assets/Images/slider-right-arrow-with-shadow.png';
import { CONSTANT } from '../../Utils/Constant';
import EventsAndCelebrationsList from '../../Utils/EventsAndCelebrationsList';
import './EventsAndCelebrations.scss';

const EventsAndCelebrations = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  var settings1 = {
    asNavFor: nav2,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    ref: (slider) => (sliderRef1 = slider)
  };

  var settings2 = {
    asNavFor: nav1,
    className: 'center',
    autoplay: false,
    centerMode: true,
    arrows: false,
    slidesToShow: 1,
    centerPadding: '280px',
    slidesToScroll: 1,
    focusOnSelect: true,
    ref: (slider) => (sliderRef2 = slider),
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          centerPadding: '180px'
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: '100px'
        }
      },
      {
        breakpoint: 575,
        settings: {
          centerPadding: '60px'
        }
      },
      {
        breakpoint: 375,
        settings: {
          centerPadding: '50px'
        }
      }
    ]
  };

  return (
    <div className="events-celebrations">
      <div className="container">
        <div className="events-celebrations-head">
          <h4>Events and Celebrations</h4>
          <p>Check out our latest celebrations below.</p>
        </div>
      </div>
      <div>
        <div className="slider-container">
          <div className="slider-arrow prev-btn">
            <button
              type="button"
              className="arrow-btn prev"
              onClick={() => {
                sliderRef1.slickPrev();
                sliderRef2.slickPrev();
              }}>
              <img
                src={LeftArrow}
                alt="Previous button"
                title={`Our Team | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
              />
            </button>
          </div>
          <div className="bg-slide">
            <Slider {...settings1}>
              {EventsAndCelebrationsList.map((item, index) => (
                <div key={index}>
                  <div className="bg-img">
                    <img
                      src={item.bgImg}
                      alt={item.title + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                      title={item.title + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="top-slide">
            <Slider {...settings2}>
              {EventsAndCelebrationsList.map((item, index) => (
                <div key={index}>
                  <div className="slider-wrap">
                    <div className="event-img">
                      <img
                        src={item.eventImg}
                        alt={item.title + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                        title={item.title + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                      />
                    </div>
                    <div className="event-title">
                      <h2 className="border-text">{item.title}</h2>
                      <h2 className="filled-text">{item.title}</h2>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="slider-arrow next-btn">
            <button
              type="button"
              className="arrow-btn next"
              onClick={() => {
                sliderRef1.slickNext();
                sliderRef2.slickNext();
              }}>
              <img
                src={RightArrow}
                alt="Previous button"
                title={`Our Team | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <p className="activity-title">
          Who said we’re all work and no fun? At Pixerfect, we like to celebrate the little and the
          big things.
        </p>
      </div>
    </div>
  );
};

export default EventsAndCelebrations;
