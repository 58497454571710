import React, { useState } from 'react';
import Header from '../../Components/Header/Header';
import Contact from '../../Components/Contact/Contact';
import ProjectsList from '../../Components/ProjectsList/ProjectsList';
import CareerContact from '../../Components/CareerContact/CareerContact';
import './Projects.scss';
import HeroText from '../../Components/HeroText/HeroText';
import Footer from '../../Components/Footer/Footer';
// import ProminentWorksList from '../../Utils/ProminentWorksList';

const Projects = () => {
  const [dataList, setDataList] = useState([]);
  return (
    <>
      <Header />
      <HeroText
        title={'A Gallery of Our Software Success Stories'}
        content={
          'Discover our comprehensive portfolio of diverse case studies, revealing the nuanced challenges encountered by businesses.'
        }
      />
      <ProjectsList dataList={dataList} setDataList={setDataList} />
      {/* {ProminentWorksList.length === dataList.length && ( */}
      <>
        <Contact />
        <CareerContact />
        <Footer />
      </>
      {/* )} */}
    </>
  );
};

export default Projects;
