import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import RightArrow from '../../Assets/Images/right-arrow.png';
import LeftArrow from '../../Assets/Images/slider-left-arrow-button.png';
import { CONSTANT } from '../../Utils/Constant';
import WeServeSlideList from '../../Utils/WeServeSlideList';
import './WeServe.scss';

const WeServe = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  return (
    <div className="we-serve-section">
      <div className="container">
        <h2 className="we-serve-section-title">Industries we serve</h2>
        {WeServeSlideList && WeServeSlideList.length > 0 && (
          <>
            <Slider
              asNavFor={nav1}
              ref={(slider) => (sliderRef2 = slider)}
              arrows={false}
              centerMode={true}
              swipeToSlide={true}
              focusOnSelect={true}
              variableWidth={true}
              className="first-slider">
              {WeServeSlideList.map((item, index) => {
                return (
                  <div key={index} style={{ width: 'auto' }}>
                    <p>{item.title}</p>
                  </div>
                );
              })}
            </Slider>
            <Slider
              className="second-slider"
              asNavFor={nav2}
              ref={(slider) => (sliderRef1 = slider)}
              nextArrow={
                <img
                  src={RightArrow}
                  alt={`Industries we serve | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                  title={`Industries we serve | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                />
              }
              prevArrow={
                <img
                  src={LeftArrow}
                  alt={`Industries we serve | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                  title={`Industries we serve | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                />
              }>
              {WeServeSlideList.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="carousel-content-wrap">
                      <div className="carousel-content-img">
                        <img
                          src={item.imgUrl}
                          alt={`${item.title} | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                          title={`${item.title} | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                        />
                      </div>
                      <div className="carousel-content-text">
                        <h4>{item.title}</h4>
                        <p>{item.content}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </>
        )}
      </div>
    </div>
  );
};

export default WeServe;
