import React from 'react';
import './AgencySlider.scss';
import Slider from 'react-slick';
import { AgenciesList } from '../../Utils/AgenciesList';

const AgencySlider = () => {
  var settings1 = {
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 4000,
    autoplaySpeed: 0,
    cssEase: 'linear',
    touchThreshold: 1000,
    arrows: false,
    pauseOnHover: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  var settings2 = {
    ...settings1,
    rtl: true
  };

  const midpoint = Math.ceil(AgenciesList.length / 2);
  const firstPart = AgenciesList.slice(0, midpoint);
  const secondPart = AgenciesList.slice(midpoint);
  return (
    <div className="slider-container">
      <Slider className="first-slider" {...settings1}>
        {firstPart.map((item) => {
          return (
            <div key={item.id}>
              <div className="img-logo">
                <div className="background"></div>
                <div className="img-container">
                  <img className="img" src={item.imgUrl} alt={item.keyword} title={item.keyword} />
                </div>
                <div className="logo-container">
                  <img className="logo" src={item.logo} alt={item.keyword} title={item.keyword} />
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
      <Slider className="second-slider" {...settings2}>
        {secondPart.map((item) => {
          return (
            <div key={item.id}>
              <div className="img-logo">
                <div className="background"></div>
                <div className="img-container">
                  <img className="img" src={item.imgUrl} alt={item.keyword} title={item.keyword} />
                </div>
                <div className="logo-container">
                  <img className="logo" src={item.logo} alt={item.keyword} title={item.keyword} />
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default AgencySlider;
