import React, { useEffect, useRef } from 'react';
import OurTeamVideo from '../../Assets/videos/pixerfect-team.mp4';
import { CONSTANT } from '../../Utils/Constant';
import './OurTeamHero.scss';

const OurTeamHero = () => {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error('Error attempting to play', error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <div className="container">
      <div className="our-team-hero">
        <h2
          className="our-team-title"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-once="true">
          We are a leader in digital <br />
          innovation and brand development.
        </h2>
        <div className="our-team-hero-content">
          <video
            playsInline
            muted
            alt={'Our Team | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
            src={OurTeamVideo}
            ref={videoEl}
          />
        </div>
      </div>
    </div>
  );
};

export default OurTeamHero;
