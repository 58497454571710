/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { API_URL, CONSTANT } from '../../Utils/Constant';
import './BlogList.scss';

const BlogList = () => {
  const baseUrl = CONSTANT.BACKEND_API_URL;
  const [blogActiveTab, setBlogActiveTab] = useState('View all');
  const [blogCategoryData, setBlogCategoryData] = useState(['View all']);
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    fetchBlogCategoryList();
  }, []);

  useEffect(() => {
    fetchBlogList();
  }, [blogActiveTab]);

  useEffect(() => {
    setTimeout(() => {
      const blogCards = document.querySelectorAll('.blog-card');
      blogCards.forEach((card) => {
        card.style.opacity = '1';
      });
      const noBlogFound = document.querySelectorAll('.no-blog-found');
      if (noBlogFound?.style) noBlogFound.style.opacity = '1';
    }, 1000);
  }, [blogData]);

  const fetchBlogCategoryList = async () => {
    try {
      const url = `${baseUrl}${API_URL.BLOG_CATEGORIES}`;
      const response = await fetch(url).then((response) => response.json());
      if (response.data && response.data.length > 0) {
        setBlogCategoryData(response.data);
      }
    } catch (error) {
      console.error('ERROR : Blog Category List Not Found!');
    }
  };

  const fetchBlogList = async () => {
    try {
      const queryParams =
        blogActiveTab !== 'View all'
          ? `&filters[blog_category][categoryName][$eq]=${encodeURIComponent(blogActiveTab)}`
          : '';
      const url = `${baseUrl}${API_URL.BLOGS}${queryParams}`;
      const response = await fetch(url).then((response) => response.json());
      if (response.data && response.data.length > 0) {
        setBlogData(response.data);
      }
    } catch (error) {
      console.error('ERROR : Blog List Not Found!');
    }
  };

  return (
    <div className="container">
      <div className="blog-section">
        <div className="blog-tab">
          {blogCategoryData && blogCategoryData.length > 0 && (
            <>
              <ul>
                <li className={'View all' === blogActiveTab ? 'active' : ''}>
                  <button
                    type="button"
                    title={`View all blogs`}
                    onClick={() => setBlogActiveTab('View all')}>
                    View All
                  </button>
                </li>
                {blogCategoryData.map((item, index) => (
                  <li
                    key={index}
                    className={item?.attributes?.categoryName === blogActiveTab ? 'active' : ''}>
                    <button
                      type="button"
                      title={`${item?.attributes?.categoryName} blogs`}
                      onClick={() => setBlogActiveTab(item?.attributes?.categoryName)}>
                      {item?.attributes?.categoryName}
                    </button>
                  </li>
                ))}
              </ul>
              <div className="category-select">
                <select onChange={(e) => setBlogActiveTab(e.target.value)} value="View all">
                  <option value="View all" defaultValue>
                    View all
                  </option>
                  {blogCategoryData.map((item, index) => (
                    <option key={index} value={item?.attributes?.categoryName}>
                      {item?.attributes?.categoryName}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}
        </div>
        <div className="blog-wrap">
          {blogData && blogData.length > 0 ? (
            blogData.map((item, index) => {
              return (
                <div key={index} className="blog-card">
                  <div className="blog-details">
                    <div className="blog-img">
                      <img
                        src={baseUrl + item?.attributes?.bannerImage?.data?.attributes?.url}
                        alt={item?.attributes?.title}
                        title={item?.attributes?.title}
                      />
                    </div>
                    <div className="blog-content">
                      <h2>
                        <Link
                          to={`/blog/${item?.attributes?.slug}`}
                          title={item?.attributes?.title}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}>
                          {item?.attributes?.title}
                        </Link>
                      </h2>
                      <p className="sub-title">{item?.attributes?.shortDescription}</p>
                    </div>
                  </div>
                  <div className="blogger-details">
                    <div className="blogger-img">
                      <img
                        src={baseUrl + item?.attributes?.bloggerImage?.data?.attributes?.url}
                        alt={item?.attributes?.title}
                        title={item?.attributes?.title}
                      />
                    </div>
                    <div className="blogger-content">
                      <p>{item?.attributes?.bloggerName}</p>
                      <span>{moment(item?.attributes?.updatedAt).format('DD MMM, YYYY')}</span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="no-blog-found">No Blogs Found!</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogList;
