import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProminentIcon2 from '../../Assets/Images/greenlab-icon.png';
import ProminentIcon3 from '../../Assets/Images/cupcake-castles-icon.png';
import ProminentIcon1 from '../../Assets/Images/assistree-icon.png';
import { CONSTANT } from '../../Utils/Constant';
import ProminentWorksList from '../../Utils/ProminentWorksList';
import './ProminentWorks.scss';
import ProminentWorksDetails from './ProminentWorksDetails';

const ProminentWorks = () => {
  const navigate = useNavigate();
  const [selectedProminentWorks, setSelectedProminentWorks] = useState(0);

  return (
    <div className="prominent-works">
      <div className="container">
        <div className="prominent-works-main">
          <h2>Prominent Works</h2>
          <div
            className={`prominent-works-wrap ${ProminentWorksList[selectedProminentWorks].bgType === 'blue' ? 'bg-blue' : ProminentWorksList[selectedProminentWorks].bgType === 'midnight-slate' ? 'bg-midnight-slate' : ''}`}>
            <div className="prominent-works-list-icon">
              <ul>
                <li className={selectedProminentWorks === 0 ? 'active' : ''}>
                  <button type="button" onClick={() => setSelectedProminentWorks(0)}>
                    <div className="ring-icon">
                      <div className="img-icon">
                        <img
                          src={ProminentIcon3}
                          alt={`Medical & Healthcare | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                          title={`Medical & Healthcare | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                        />
                      </div>
                    </div>
                  </button>
                </li>
                <li className={selectedProminentWorks === 1 ? 'active' : ''}>
                  <button type="button" onClick={() => setSelectedProminentWorks(1)}>
                    <div className="ring-icon">
                      <div className="img-icon">
                        <img
                          src={ProminentIcon2}
                          alt={`Customer Relationship Manager | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                          title={`Customer Relationship Manager | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                        />
                      </div>
                    </div>
                  </button>
                </li>
                <li className={selectedProminentWorks === 2 ? 'active' : ''}>
                  <button type="button" onClick={() => setSelectedProminentWorks(2)}>
                    <div className="ring-icon">
                      <div className="img-icon">
                        <img
                          src={ProminentIcon1}
                          alt={`E-Commerce web application | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                          title={`E-Commerce web application | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                        />
                      </div>
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      navigate('/case-studies');
                      window.scrollTo(0, 0);
                    }}>
                    <div className="ring-icon">
                      <div className="text-icon">
                        <p>More</p>
                      </div>
                    </div>
                  </button>
                </li>
              </ul>
            </div>
            <div className="prominent-works-section">
              {[0, 1, 2, 3].map((item, index) => {
                if (selectedProminentWorks === index) {
                  return (
                    <div key={index}>
                      <ProminentWorksDetails
                        withBrand={false}
                        index={index}
                        item={ProminentWorksList[selectedProminentWorks]}
                      />
                    </div>
                  );
                } else {
                  return;
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProminentWorks;
