import React from 'react';

const TrophyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      x="0"
      y="0"
      viewBox="0 0 24 24"
      xmlSpace="preserve">
      <g>
        <path
          d="M15.966 5a1.297 1.297 0 0 0-1.264-1H9.296a1.3 1.3 0 0 0-1.011.48c-.126.154-.21.332-.253.52H5.498v.5c0 2.164 1.56 4.49 4.983 4.499.003.037.017 2.001.017 2.001 0 .66-.536.927-.722 1h-.778v1h6v-1h-.894c-.152-.067-.606-.319-.606-1 0 0 .014-1.965.017-2.002 3.423-.008 4.983-2.335 4.983-4.498V5h-2.534ZM6.534 6h1.604c.255.954.827 2.171 1.792 2.958C7.819 8.752 6.734 7.436 6.534 6Zm6.981 2.111c-.655.535-1.016 1.243-1.016 1.994V12c0 .409.098.734.237 1h-1.507a1.95 1.95 0 0 0 .27-1v-1.895c0-.751-.36-1.459-1.016-1.994-.902-.736-1.302-1.919-1.479-2.782a.26.26 0 0 1 .056-.217A.307.307 0 0 1 9.296 5h5.406a.3.3 0 0 1 .235.112.26.26 0 0 1 .056.217c-.176.862-.576 2.044-1.479 2.781Zm.555.847c.965-.789 1.538-2.005 1.791-2.958h1.604c-.199 1.436-1.285 2.752-3.395 2.958Z"
          fill="#000000"
          opacity="1"
          data-original="#000000"></path>
        <path
          d="M21.5 0h-19A2.503 2.503 0 0 0 0 2.5V24h24V2.5C24 1.122 22.879 0 21.5 0ZM23 23H1V2.5C1 1.673 1.673 1 2.5 1h19c.827 0 1.5.673 1.5 1.5V23Z"
          fill="#000000"
          opacity="1"
          data-original="#000000"></path>
        <path
          d="M7 16h10v1H7zM5 19h14v1H5z"
          fill="#000000"
          opacity="1"
          data-original="#000000"></path>
      </g>
    </svg>
  );
};

export default TrophyIcon;
