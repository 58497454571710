import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import Agencies from '../../Components/Agencies/Agencies';
import CareerContact from '../../Components/CareerContact/CareerContact';
import ClientReview from '../../Components/ClientReview/ClientReview';
import Contact from '../../Components/Contact/Contact';
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header/Header';
import HeroText from '../../Components/HeroText/HeroText';
import HowItWorks from '../../Components/HowItWorks/HowItWorks';
import ServiceDetailsHero from '../../Components/ServiceDetailsHero/ServiceDetailsHero';
import ServicesList from '../../Components/Services/ServicesList';
import TrustedPartner from '../../Components/TrustedPartner/TrustedPartner';
import { CONSTANT, SEO } from '../../Utils/Constant';
import { ServicesPageDetailsList } from '../../Utils/ServiceList';
import './ServicesDetails.scss';

const ServicesDetails = () => {
  let { serviceName } = useParams();
  const navigate = useNavigate();
  const [servicesPageData, setServicesPageData] = useState([]);

  useEffect(() => {
    if (serviceName && ServicesPageDetailsList && ServicesPageDetailsList.length > 0) {
      const filteredServicesPageData = ServicesPageDetailsList.filter(
        (item) => item.params === serviceName
      );
      if (filteredServicesPageData && filteredServicesPageData.length > 0) {
        setServicesPageData(filteredServicesPageData);
      } else {
        navigate('*');
        setServicesPageData([]);
      }
    } else {
      setServicesPageData([]);
    }
  }, [navigate, serviceName]);
  console.log('servicesPageData', servicesPageData);
  return (
    <>
      <Helmet>
        <title>{servicesPageData[0]?.title || SEO.SERVICES_PAGE_TITLE}</title>
        <meta
          name="description"
          content={servicesPageData[0]?.howItWorksContent || SEO.SERVICES_PAGE_DESCRIPTION}
        />

        <meta property="og:title" content={servicesPageData[0]?.title || SEO.SERVICES_PAGE_TITLE} />
        <meta
          property="og:description"
          content={servicesPageData[0]?.howItWorksContent || SEO.SERVICES_PAGE_DESCRIPTION}
        />
        <meta property="og:image" content="https://www.pixerfect.com/Group_192*192.png" />
        <meta property="twitter:image" content="https://www.pixerfect.com/Group_192*192.png" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.PIXERFECT_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.PIXERFECT_SOLUTIONS_LLP} />

        <meta
          name="twitter:title"
          content={servicesPageData[0]?.title || SEO.SERVICES_PAGE_TITLE}
        />
        <meta
          name="twitter:description"
          content={servicesPageData[0]?.howItWorksContent || SEO.SERVICES_PAGE_DESCRIPTION}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json" data-react-helmet="true">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Service',
            serviceType: servicesPageData[0]?.title || SEO.SERVICES_PAGE_TITLE,
            url: `${CONSTANT?.PIXERFECT_WEB_LINK}/service/${servicesPageData[0]?.params}`,
            provider: {
              '@type': 'Organization',
              name: CONSTANT?.PIXERFECT_SOLUTIONS_LLP,
              url: CONSTANT?.PIXERFECT_WEB_LINK
            },
            areaServed: 'Worldwide',
            description: servicesPageData[0]?.howItWorksContent || SEO.SERVICES_PAGE_DESCRIPTION
          })}
        </script>
      </Helmet>
      <Header />
      <HeroText
        title={'Elevating Your Digital Presence'}
        content={
          'Our comprehensive IT services encompass cutting-edge solutions in web design, development, and beyond with a focus on innovation and client satisfaction.'
        }
      />
      {servicesPageData && servicesPageData.length > 0 ? (
        <>
          <ServiceDetailsHero servicesData={servicesPageData[0]} />
          <HowItWorks servicesData={servicesPageData[0]} />
          {servicesPageData[0].trustedPartner && servicesPageData[0].trustedPartner.length > 0 && (
            <TrustedPartner ourPartner={servicesPageData[0].trustedPartner} />
          )}
        </>
      ) : (
        <>
          <ServicesList />
          <Agencies />
          <ClientReview />
        </>
      )}
      <Contact />
      <CareerContact />
      <Footer />
    </>
  );
};

export default ServicesDetails;
