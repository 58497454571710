import React from 'react';
import Header from '../../Components/Header/Header';
import HeroText from '../../Components/HeroText/HeroText';
import PrivacyPolicy from '../../Components/PrivacyAndService/PrivacyPolicy';
import Footer from '../../Components/Footer/Footer';

const PrivacyPolicyPage = () => {
  return (
    <div>
      <Header />
      <HeroText title={'Privacy Policy'} content={'Privacy Policy of the website & Our products'} />
      <PrivacyPolicy />
      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
