import React, { useState } from 'react';
import { HiMinus, HiPlus } from 'react-icons/hi';
import { FAQList } from '../../Utils/FAQList';
import './Faq.scss';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="Faq">
      <div className="container">
        <h4>FAQ</h4>
        <div className="accordion">
          {FAQList.map((item, index) => {
            return (
              <div
                key={item.id}
                className="accordion-item"
                data-aos="fade-up"
                data-aos-duration={'1' + index + '00'}
                data-aos-once="true">
                <button
                  type="button"
                  className="accordion-title"
                  onClick={() => toggleAccordion(index)}>
                  <span>{item.question}</span>
                  <div className="icon">
                    {activeIndex === index ? (
                      <p>
                        <HiMinus />
                      </p>
                    ) : (
                      <p>
                        <HiPlus />
                      </p>
                    )}
                  </div>
                </button>
                <div className={`accordion-content ${activeIndex === index ? 'open' : ''}`}>
                  {item.answer}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Faq;
