import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_URL, CONSTANT } from '../../Utils/Constant';
import './BlogDetailSec.scss';
import { DataContext } from '../../Context/DataContext';
import BlogBottomSlider from '../BlogSlider/BlogBottomSlider';

const BlogDetailSec = ({ blogData, blogId }) => {
  const baseUrl = CONSTANT.BACKEND_API_URL;
  const [blogLink, setBlogLink] = useState([]);
  const [selectedLink, setSelectedLink] = useState('');
  const { fetchBlogList, bottomSliderBlogs } = useContext(DataContext);
  useEffect(() => {
    fetchBlogListViaCategory();
    if (bottomSliderBlogs.length < 1) {
      fetchBlogList(blogId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLink]);

  const fetchBlogListViaCategory = async () => {
    try {
      const queryParams = `&filters[blog_category][categoryName][$eq]=${encodeURIComponent(blogData.blog_category.data.attributes.categoryName)}&filters[slug][$ne]=${encodeURIComponent(selectedLink || blogData.slug)}`;
      const url = `${baseUrl}${API_URL.BLOGS}${queryParams}`;
      const response = await fetch(url).then((response) => response.json());
      if (response.data && response.data.length > 0) {
        const shuffledArray = [...response.data];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }

        setBlogLink(shuffledArray);
      }
    } catch (error) {
      console.error('ERROR : Blog List Not Found!');
    }
  };

  function Image({ image }) {
    return <img src={image.url} alt={image.alternativeText} />;
  }

  function List({ format, children }) {
    const Tag = format === 'ordered' ? 'ol' : 'ul';
    return <Tag>{children}</Tag>;
  }

  function ListItem({ children }) {
    return <li>{children}</li>;
  }

  function Heading({ level, children }) {
    const Tag = `h${level}`;
    return <Tag>{children}</Tag>;
  }

  function Text({ text }) {
    return <span>{text}</span>;
  }

  function Paragraph({ text }) {
    return text.map((ele, ind) => <p key={ind}>{ele.text}</p>);
  }

  return (
    <div className="container">
      <div className="blog-details-wrap">
        <div
          className="blog-description-details"
          style={{ width: blogLink.length > 0 ? '66.66%' : '100%' }}>
          {blogData.description.map((item, index) => {
            switch (item.type) {
              case 'heading':
                return (
                  <Heading key={index} level={item.level}>
                    {item.children[0].text}
                  </Heading>
                );
              case 'text':
                return <Text key={index} text={item.text} />;
              case 'paragraph':
                return <Paragraph key={index} text={item.children} />;
              case 'list':
                return (
                  <List key={index} format={item.format}>
                    {item.children.map((child, childIndex) => (
                      <ListItem key={childIndex}>{child.children[0].text}</ListItem>
                    ))}
                  </List>
                );
              case 'image':
                return <Image key={index} image={item.image} />;
              default:
                return null;
            }
          })}
        </div>
        {blogLink.length > 0 && (
          <div className="other-blog-links">
            <>
              <h5>Related Blog Links :</h5>
              <ul>
                {blogLink.map((item, index) => (
                  <li key={index}>
                    <Link
                      onClick={() => setSelectedLink(item.attributes.slug)}
                      to={`/blog/${item?.attributes?.slug}`}>
                      <svg
                        width="21"
                        height="13"
                        viewBox="0 0 21 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.632812 6.84719L20.6328 6.79883M20.6328 6.79883C20.6328 6.79883 13.681 8.29883 13.681 12.2988M20.6328 6.79883C20.6328 6.79883 13.681 5.79883 13.681 1.29883"
                          stroke="#103F57"
                          strokeLinecap="round"
                        />
                      </svg>
                      <span>{item?.attributes?.title}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          </div>
        )}
      </div>
      {bottomSliderBlogs.length > 1 && <BlogBottomSlider sliderData={bottomSliderBlogs} />}
    </div>
  );
};

export default BlogDetailSec;
