import React from 'react';
import { SEO } from '../../Utils/Constant';
import { useNavigate } from 'react-router-dom';
import PageNotFoundImg from '../../Assets/Images/404.png';
import './PageNotFoundSec.scss';

const PageNotFoundSec = () => {
  let navigate = useNavigate();
  return (
    <div className="container">
      <div className="not-found-wrap">
        <div className="not-found-img">
          <img
            src={PageNotFoundImg}
            alt={SEO.NOT_FOUND_PAGE_TITLE}
            title={SEO.NOT_FOUND_PAGE_TITLE}
          />
        </div>
        <div className="not-found-content">
          <h2>Oops! page not found.</h2>
          <p>
            We understand how frustrating it can be to encounter a page not found error, and we
            apologize for any inconvenience it may have caused. Rest assured that we will do
            everything we can to resolve the issue and make the necessary changes as soon as
            possible so that you can access the information or complete your task without any
            further delays. Thank you for your patience and understanding.
          </p>
          <div className="go-back">
            <button type="button" className="primary-btn" onClick={() => navigate(-1)}>
              <span>Go Back</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFoundSec;
