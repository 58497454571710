import Indyra from '../Assets/Images/indrya-mokeup.png';
import IndyraLogo from '../Assets/Images/indyra-logo.png';
import Bigkheti from '../Assets/Images/bigkheti-mokeup.png';
import BigkhetiLogo from '../Assets/Images/big-kheti-logo.png';
import Spacetruck from '../Assets/Images/spacetruck-mokeup.png';
import SpacetruckLogo from '../Assets/Images/space-truck-logo.png';
import Kidsacademy from '../Assets/Images/kidsacademy-mokeup.png';
import KidsacademyLogo from '../Assets/Images/kids-academy-logo.png';
import Capitalcalc from '../Assets/Images/capitalcalc-mokeup.png';
import CapitalcalcLogo from '../Assets/Images/capital-cals-logo.png';
import Assistree from '../Assets/Images/assitree-mokeup.png';
import AssistreeLogo from '../Assets/Images/assistree-logo.png';
import Greenlab from '../Assets/Images/greenlab-mokeup.png';
import GreenlabLogo from '../Assets/Images/greenlab-logo.png';
import IngridoOption from '../Assets/Images/ingridoopinion-mokeup.png';
import IngridoOptionLogo from '../Assets/Images/ingrido-opion-logo.png';
import Koomz from '../Assets/Images/koomz-mokeup.png';
import KoomzLogo from '../Assets/Images/koomz-logo.png';
import MySociety from '../Assets/Images/my-society-mokeup.png';
import MysocietyLogo from '../Assets/Images/my-society-logo.png';
import CupcakeCastle from '../Assets/Images/cupcake-castle-mokeup.png';
import CupcakeCastleLogo from '../Assets/Images/cupcake-castles-logo.png';
import Doulamatch from '../Assets/Images/doula-match-mokeup.png';
import DoulamatchLogo from '../Assets/Images/doula-match-logo.png';
import Neurofenix from '../Assets/Images/neurofenix-mokeup.png';
import NeurofenixLogo from '../Assets/Images/neurofix-logo.png';
import Airwe from '../Assets/Images/air-we-mokeup.png';
import AirweLogo from '../Assets/Images/air-we-logo.png';
import { CONSTANT } from './Constant';

export const AgenciesList = [
  {
    id: 1,
    logo: IndyraLogo,
    imgUrl: Indyra,
    keyword: `${`Indyra Project | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}`
  },
  {
    id: 2,
    logo: BigkhetiLogo,
    imgUrl: Bigkheti,
    keyword: `${`Bigkheti Project | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}`
  },
  {
    id: 3,
    logo: SpacetruckLogo,
    imgUrl: Spacetruck,
    keyword: `${`Spacetruck Project | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}`
  },
  {
    id: 4,
    logo: KidsacademyLogo,
    imgUrl: Kidsacademy,
    keyword: `${`Kidsacademy Project | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}`
  },
  {
    id: 5,
    logo: CapitalcalcLogo,
    imgUrl: Capitalcalc,
    keyword: `${`Capitalcalc Project | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}`
  },
  {
    id: 6,
    logo: AssistreeLogo,
    imgUrl: Assistree,
    keyword: `${`Assistree Project | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}`
  },
  {
    id: 7,
    logo: GreenlabLogo,
    imgUrl: Greenlab,
    keyword: `${`Greenlab Project | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}`
  },
  {
    id: 8,
    logo: IngridoOptionLogo,
    imgUrl: IngridoOption,
    keyword: `${`IngridoOption Project | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}`
  },
  {
    id: 9,
    logo: KoomzLogo,
    imgUrl: Koomz,
    keyword: `${`Koomz Project | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}`
  },
  {
    id: 10,
    logo: MysocietyLogo,
    imgUrl: MySociety,
    keyword: `${`MySociety Project | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}`
  },
  {
    id: 11,
    logo: CupcakeCastleLogo,
    imgUrl: CupcakeCastle,
    keyword: `${`CupcakeCastle Project | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}`
  },
  {
    id: 12,
    logo: DoulamatchLogo,
    imgUrl: Doulamatch,
    keyword: `${`Doulamatch Project | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}`
  },
  {
    id: 13,
    logo: NeurofenixLogo,
    imgUrl: Neurofenix,
    keyword: `${`Neurofenix Project | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}`
  },
  {
    id: 14,
    logo: AirweLogo,
    imgUrl: Airwe,
    keyword: `${`Airwe Project | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}`
  }
];
