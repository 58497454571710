import React from 'react';

const ChatbotIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
      <path d="m20.5,0h-5c-1.93,0-3.5,1.57-3.5,3.5v7.195c0,.483.264.924.69,1.152.193.104.405.155.616.155.254,0,.508-.075.729-.222l2.618-1.779h3.847c1.93,0,3.5-1.57,3.5-3.5v-3c0-1.93-1.57-3.5-3.5-3.5Zm2.5,6.5c0,1.378-1.121,2.5-2.5,2.5h-4c-.101,0-.198.03-.281.086l-2.742,1.863c-.137.091-.266.042-.314.015-.049-.026-.162-.104-.162-.27V3.5c0-1.378,1.121-2.5,2.5-2.5h5c1.379,0,2.5,1.122,2.5,2.5v3Zm-4.5,8.5h-.5c0-1.052-.406-2.046-1.143-2.799-.191-.196-.51-.201-.707-.008-.197.193-.201.51-.008.707.553.565.857,1.311.857,2.1v5c0,1.654-1.346,3-3,3H6c-1.654,0-3-1.346-3-3v-5c0-1.654,1.346-3,3-3h4c.276,0,.5-.224.5-.5v-2.5c0-.276-.224-.5-.5-.5s-.5.224-.5.5v2h-3.5c-2.206,0-4,1.794-4,4h-.5c-.827,0-1.5.673-1.5,1.5v3c0,.827.673,1.5,1.5,1.5h.627c.445,1.723,2.013,3,3.873,3h8c1.861,0,3.428-1.277,3.873-3h.627c.827,0,1.5-.673,1.5-1.5v-3c0-.827-.673-1.5-1.5-1.5ZM1.5,20c-.276,0-.5-.224-.5-.5v-3c0-.276.224-.5.5-.5h.5v4h-.5Zm17.5-.5c0,.276-.225.5-.5.5h-.5v-4h.5c.275,0,.5.224.5.5v3Zm-14-1.5c0,1.103.897,2,2,2s2-.897,2-2-.897-2-2-2-2,.897-2,2Zm3,0c0,.551-.449,1-1,1s-1-.449-1-1,.449-1,1-1,1,.449,1,1Zm5,2c1.103,0,2-.897,2-2s-.897-2-2-2-2,.897-2,2,.897,2,2,2Zm0-3c.552,0,1,.449,1,1s-.448,1-1,1-1-.449-1-1,.449-1,1-1Z" />
    </svg>
  );
};

export default ChatbotIcon;
