import React from 'react';
import './Services.scss';
import OurServices from './OurServices';
import ServicesDetails from './ServicesDetails';

const Services = () => {
  return (
    <div className="fullService">
      <div className="background-gary"></div>
      <div className="background-blue"></div>
      <div className="services">
        <div className="container">
          <OurServices />
          <ServicesDetails />
        </div>
      </div>
    </div>
  );
};

export default Services;
