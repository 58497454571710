import React, { useContext, useEffect, useState } from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { FaArrowUp } from 'react-icons/fa6';
import { API_URL, CONSTANT } from '../../Utils/Constant';
import PFBrandWhite from '../../Assets/Images/footer-pf-solution.svg';
import RightArrow from '../../Assets/Images/right-arrow.png';
import { Link } from 'react-router-dom';
import SatyamevaJayateLogo from '../../Assets/Images/government-of-india-ministry-of-commerce-and-industry-organization.png';
import ArrowRightIcon from '../../Assets/Svg/ArrowRightIcon';
import FooterList from '../../Utils/FooterList';
import ContactDetails from './ContactDetails';
import './Footer.scss';
import OurLocation from './OurLocation';
import { DataContext } from '../../Context/DataContext';
import { toast } from 'react-toastify';
import clutchLogo from '../../Assets/Images/clutch-logo.svg';

const Footer = () => {
  const { footerBlogData, ipAddress, deviceInfo } = useContext(DataContext);
  const [isVisible, setIsVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset || document.documentElement.scrollTop;
      setIsVisible(currentScrollPos < prevScrollPos && currentScrollPos > 0);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const OurNewsletter = () => {
    const baseUrl = CONSTANT.BACKEND_API_URL;
    const [email, setEmail] = useState('');

    const submitNewsLetter = async () => {
      try {
        const url = `${baseUrl}${API_URL.NEWS_LETTER}`;
        const body = {
          data: {
            email,
            browserDetail: {
              ip: ipAddress,
              device: deviceInfo
            }
          }
        };
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        });
        if (response.ok) {
          setEmail('');
          toast.success('Thank you for subscribing! Stay tuned for updates and exclusive content.');
        } else {
          const jsonData = await response.json();
          toast.error(
            jsonData?.error?.message === 'This attribute must be unique'
              ? 'Email already exist'
              : jsonData?.error?.message
          );
        }
      } catch (error) {
        console.log('API call failed:', error);
      }
    };

    const handleSubmit = () => {
      submitNewsLetter();
    };

    return (
      <div className="newsletter-container">
        <h6>Our Newsletter</h6>
        <form onSubmit={handleSubmit}>
          <div className="email-input">
            <input
              placeholder="Enter your email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit">
              <ArrowRightIcon />
            </button>
          </div>
        </form>
      </div>
    );
  };

  const SatyamevaJayate = () => {
    return (
      <div className="satya">
        <img
          src={SatyamevaJayateLogo}
          alt="government of india ministry of commerce and industry organization"
        />
        <p>
          Registered with Ministry Of
          <br /> Corporate Affairs,
          <br /> Government of India
        </p>
      </div>
    );
  };

  return (
    <footer className="footer-section">
      <div className="container">
        <div className="footer-wrapper">
          <div className="brand-content">
            <div className="footer-brand">
              <div className="footer-logo">
                <img
                  src={PFBrandWhite}
                  alt={`${CONSTANT.PIXERFECT_SOLUTIONS_LLP} | Brand`}
                  title={`${CONSTANT.PIXERFECT_SOLUTIONS_LLP} | Brand`}
                />
              </div>
              <p>
                An IT company committed to providing designs with meticulous precision and exactness
                is one that prioritizes the principle of pixel perfect.
              </p>
            </div>
            <div className="our-news-letter mobile-view">
              <OurNewsletter />
            </div>
            <div className="footer-link-details-wrap mobile-view">
              <div className="footer-link-wrap">
                <div className="content-detail">
                  <h6>Pages</h6>
                  <ul>
                    {FooterList && FooterList.pageLinks && FooterList.pageLinks.length > 0 ? (
                      FooterList.pageLinks.map((item, i) => (
                        <li key={i}>
                          <Link
                            to={item.path}
                            title={item.name + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                            onClick={scrollToTop}>
                            {item.name}
                          </Link>
                        </li>
                      ))
                    ) : (
                      <li>No Data Found.</li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="footer-link-wrap">
                <div className="content-detail">
                  <h6>Other Links</h6>
                  <ul>
                    {FooterList && FooterList.otherLinks && FooterList.otherLinks.length > 0 ? (
                      FooterList.otherLinks.map((item, i) => (
                        <li key={i}>
                          <Link
                            to={item.path}
                            title={item.name + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                            onClick={scrollToTop}>
                            {item.name}
                          </Link>
                        </li>
                      ))
                    ) : (
                      <li>No Data Found.</li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="footer-link-wrap blog-list">
                <div className="content-detail">
                  <h6>Blogs</h6>
                  <ul>
                    {footerBlogData && footerBlogData?.length > 0 ? (
                      <>
                        {footerBlogData?.map((item, i) => (
                          <li key={i}>
                            <Link
                              to={`/blog/${item.slug}`}
                              title={item.title + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                              onClick={scrollToTop}>
                              {item.title}
                            </Link>
                          </li>
                        ))}
                        <li className="view-all-blogs-link">
                          <Link
                            to="/blogs"
                            title={'Blogs | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                            onClick={scrollToTop}>
                            <span>View All Blogs</span>
                            <img
                              src={RightArrow}
                              alt={'Blogs | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                              title={'Blogs | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                            />
                          </Link>
                        </li>
                      </>
                    ) : (
                      <li>No Data Found.</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <OurLocation animate={false} />
          </div>
          <div className="footer-link-details">
            <div className="footer-contact-details-wrap">
              <ContactDetails animate={false} />
            </div>
            <div className="footer-link-details-wrap">
              <div className="footer-link-wrap">
                <div className="content-detail">
                  <h6>Pages</h6>
                  <ul>
                    {FooterList && FooterList.pageLinks && FooterList.pageLinks.length > 0 ? (
                      FooterList.pageLinks.map((item, i) => (
                        <li key={i}>
                          <Link
                            to={item.path}
                            title={item.name + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                            onClick={scrollToTop}>
                            {item.name}
                          </Link>
                        </li>
                      ))
                    ) : (
                      <li>No Data Found.</li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="footer-link-wrap">
                <div className="content-detail">
                  <h6>Other Links</h6>
                  <ul>
                    {FooterList && FooterList.otherLinks && FooterList.otherLinks.length > 0 ? (
                      FooterList.otherLinks.map((item, i) => (
                        <li key={i}>
                          <Link
                            to={item.path}
                            title={item.name + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                            onClick={scrollToTop}>
                            {item.name}
                          </Link>
                        </li>
                      ))
                    ) : (
                      <li>No Data Found.</li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="footer-link-wrap blog-list">
                <div className="content-detail">
                  <h6>Blogs</h6>
                  <ul>
                    {footerBlogData && footerBlogData?.length > 0 ? (
                      <>
                        {footerBlogData?.map((item, i) => (
                          <li key={i}>
                            <Link
                              to={`/blog/${item.slug}`}
                              title={item.title + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                              onClick={scrollToTop}>
                              {item.title}
                            </Link>
                          </li>
                        ))}
                        <li className="view-all-blogs-link">
                          <Link
                            to="/blog"
                            title={'Blogs | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                            onClick={scrollToTop}>
                            <span>View All Blogs</span>
                            <img
                              src={RightArrow}
                              alt={'Blogs | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                              title={'Blogs | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                            />
                          </Link>
                        </li>
                      </>
                    ) : (
                      <li>No Data Found.</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="newsletter-logo">
              <div className="our-news-letter">
                <OurNewsletter />
              </div>

              <Link to="https://clutch.co/profile/pixerfect-solutions-llp" target="_blank">
                <img src={clutchLogo} alt="clutch-logo" className="clutch-logo" />
              </Link>
              <div className="satyameva-jayate">
                <SatyamevaJayate />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pixerfect-rights">
        <div className="container">
          <div className="pixerfect-rights-wrap">
            <p className="pixerfect-rights-text">
              Copyright&copy; {new Date().getFullYear()} Pixerfect Solutions LLP. All rights
              reserved.
            </p>
            <ul className="social-media">
              <li>
                <a
                  href="https://x.com/pixerfect"
                  title={'Twitter | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                  target="_blank"
                  rel="noreferrer">
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/pixerfectsolutions/"
                  title={'Linkedin | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                  target="_blank"
                  rel="noreferrer">
                  <FaLinkedinIn />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/pixerfectsolutions"
                  title={'Facebook | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                  target="_blank"
                  rel="noreferrer">
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/pixerfectsolutions/"
                  title={'Instagram | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                  target="_blank"
                  rel="noreferrer">
                  <FaInstagram />
                </a>
              </li>
            </ul>
          </div>
          <div className="scroll-to-top">
            {isVisible && (
              <button type="button" onClick={scrollToTop} title="Go to top">
                <FaArrowUp />
              </button>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
