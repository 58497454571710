import React from 'react';
import Header from '../../Components/Header/Header';
import HeroText from '../../Components/HeroText/HeroText';
import TermsOfServices from '../../Components/PrivacyAndService/TermsOfServices';
import Footer from '../../Components/Footer/Footer';

const TermsOfServicesPage = () => {
  return (
    <div>
      <Header />
      <HeroText
        title={'Terms Of Service'}
        content={'Terms of service of the website & Our products'}
      />
      <TermsOfServices />
      <Footer />
    </div>
  );
};

export default TermsOfServicesPage;
