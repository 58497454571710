import EventBg1 from '../Assets/Images/events-celebrations/pixerfect-event-celebration-photo-1.png';
import EventBg2 from '../Assets/Images/events-celebrations/pixerfect-event-celebration-photo-2.png';
import EventBg3 from '../Assets/Images/events-celebrations/pixerfect-event-celebration-photo-3.png';
import EventBg4 from '../Assets/Images/events-celebrations/pixerfect-event-celebration-photo-4.png';
import EventBg5 from '../Assets/Images/events-celebrations/pixerfect-event-celebration-photo-5.png';
import EventBg6 from '../Assets/Images/events-celebrations/pixerfect-event-celebration-photo-6.png';
import EventBg7 from '../Assets/Images/events-celebrations/pixerfect-event-celebration-photo-7.png';
import Event1 from '../Assets/Images/events-celebrations/pixerfect-team-member-photo-1.png';
import Event2 from '../Assets/Images/events-celebrations/pixerfect-team-member-photo-2.png';
import Event3 from '../Assets/Images/events-celebrations/pixerfect-team-member-photo-3.png';
import Event4 from '../Assets/Images/events-celebrations/pixerfect-team-member-photo-4.png';
import Event5 from '../Assets/Images/events-celebrations/pixerfect-team-member-photo-5.png';
import Event6 from '../Assets/Images/events-celebrations/pixerfect-team-member-photo-6.png';
import Event7 from '../Assets/Images/events-celebrations/pixerfect-team-member-photo-7.png';

const EventsAndCelebrationsList = [
  {
    title: 'Farm Day',
    eventImg: Event1,
    bgImg: EventBg1
  },
  {
    title: 'Ganesh Chaturthi',
    eventImg: Event2,
    bgImg: EventBg2
  },
  {
    title: 'Diwali',
    eventImg: Event3,
    bgImg: EventBg3
  },
  {
    title: 'TechTalks',
    eventImg: Event4,
    bgImg: EventBg4
  },
  {
    title: 'Holi Celebration',
    eventImg: Event5,
    bgImg: EventBg5
  },
  {
    title: 'Wellness Week',
    eventImg: Event6,
    bgImg: EventBg6
  },
  {
    title: 'Independence Day',
    eventImg: Event7,
    bgImg: EventBg7
  }
];
export default EventsAndCelebrationsList;
