import React, { useRef } from 'react';
import Slider from 'react-slick';
import QuoteIcon from '../../Assets/Images/quote-icon.png';
import RightArrow from '../../Assets/Images/right-arrow.png';
import LeftArrow from '../../Assets/Images/slider-left-arrow-button.png';
import ClientReviewList from '../../Utils/ClientReviewList';
import { CONSTANT } from '../../Utils/Constant';
import './ClientReview.scss';
import { useNavigate } from 'react-router-dom';

const ClientReview = () => {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false
        }
      }
    ]
  };

  return (
    <div className="client-review">
      <div className="container">
        <div className="client-review-title">
          <h4 data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
            They loved what we do
          </h4>
          <p data-aos="fade-up" data-aos-duration="1200" data-aos-once="true">
            We value our Relationships and put service first with an emphasis on Adding value and
            solving problems
          </p>
        </div>
        <div className="client-review-slider-section">
          <div className="slider-arrow">
            <button
              type="button"
              className="arrow-btn prev"
              onClick={() => sliderRef?.current?.slickPrev()}>
              <img
                src={LeftArrow}
                alt="Previous button"
                title={`Client review | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
              />
            </button>
          </div>
          <div className="client-review-slider">
            {ClientReviewList && ClientReviewList.length > 0 ? (
              <Slider ref={sliderRef} {...settings}>
                {ClientReviewList.map((item, index) => (
                  <div key={index}>
                    <div className="slider-content">
                      <div className="slider-img-part">
                        <div className="slider-arrow">
                          <button
                            type="button"
                            className="arrow-btn prev"
                            onClick={() => sliderRef?.current?.slickPrev()}>
                            <img
                              src={LeftArrow}
                              alt="Previous button"
                              title={`Client review | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                            />
                          </button>
                        </div>
                        <img
                          className="client-img"
                          src={item.clientImg}
                          alt={item.clientName + ' photograph'}
                          title={`Client | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                        />
                        <div className="project-logo">
                          <img
                            src={item.projectLogo}
                            alt={`${item.projectName} - Brand`}
                            title={`${item.projectName} | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                          />
                        </div>
                        <div className="slider-arrow next-btn">
                          <button
                            type="button"
                            className="arrow-btn next"
                            onClick={() => sliderRef?.current?.slickNext()}>
                            <img
                              src={RightArrow}
                              alt="Next button"
                              title={`Client review | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                            />
                          </button>
                        </div>
                      </div>
                      <div className="client-review-content">
                        <h2>{item.clientName}</h2>
                        <h4>{item.clientPosition}</h4>
                        <div className="review-text">
                          <img
                            src={QuoteIcon}
                            alt="Quote Icon"
                            title={`Client review | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                          />
                          <p>{item.clientReview}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              <p>No review found!</p>
            )}
          </div>
          <div className="slider-arrow next-btn">
            <button
              type="button"
              className="arrow-btn next"
              onClick={() => sliderRef?.current?.slickNext()}>
              <img
                src={RightArrow}
                alt="Next button"
                title={`Client review | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
              />
            </button>
          </div>
        </div>
        <div className="review-view-all-btn">
          <div></div>
          <button
            type="button"
            onClick={() => {
              navigate('/testimonial');
              window.scrollTo(0, 0);
            }}>
            <span>View More</span>
            <img src={RightArrow} alt="View all" title="View all" />
          </button>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default ClientReview;
