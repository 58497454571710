import React from 'react';
import { CONSTANT } from '../../Utils/Constant';
import './Map.scss';

const Map = () => {
  return (
    <div className="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4422.854525876516!2d72.80291608645328!3d21.225403210415504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x25ad8cf3367c30f%3A0xe1bb9cbb4153d745!2sPixerFect%20Solutions%20LLP!5e0!3m2!1sen!2sin!4v1712913545593!5m2!1sen!2sin"
        width="100%"
        height="496"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        title={`${CONSTANT.PIXERFECT_SOLUTIONS_LLP} address`}
        referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
  );
};

export default Map;
