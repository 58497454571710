import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import './Header.scss';
import PFBrand from '../../Assets/Images/pixerfect-logo-blue.svg';
import PFBrandWhite from '../../Assets/Images/pixerfect-logo-white.svg';
import { IoIosArrowDown } from 'react-icons/io';
// import RightTopArrow from '../../Assets/Images/right-top-arrow.png';
import RightArrow from '../../Assets/Images/right-arrow.png';
import { CONSTANT } from '../../Utils/Constant.js';
import MenuList from '../../Utils/MenuList.js';

const Header = () => {
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showMenu]);

  return (
    <>
      <div className={scroll ? 'header-blue' : 'header-transparent'}>
        <div className="container">
          <div className="header-wrapper">
            <div className="toggle-icon">
              <button
                type="button"
                onClick={() => setShowMenu(!showMenu)}
                className={`hamburger-toggle ${showMenu ? 'close-btn' : ''}`}>
                <span className="hamburger-icon"></span>
              </button>
            </div>
            <div className="header-sub-wrap">
              <div className="header-logo">
                <Link
                  to="/"
                  title={`${CONSTANT.PIXERFECT_SOLUTIONS_LLP} - Brand`}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}>
                  <img
                    src={scroll ? PFBrandWhite : PFBrand}
                    alt={`${CONSTANT.PIXERFECT_SOLUTIONS_LLP} - Brand`}
                    title={`${CONSTANT.PIXERFECT_SOLUTIONS_LLP} - Brand`}
                  />
                </Link>
              </div>
              <Menu setShowMenu={setShowMenu} />
            </div>
            <div className="connect-btn">
              <button
                type="button"
                className="primary-btn"
                onClick={() => {
                  navigate('/contact-us');
                  window.scrollTo(0, 0);
                }}
                title={`${CONSTANT.PIXERFECT_SOLUTIONS_LLP} - Contact Us`}>
                <span>Join With Us</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`mobile-menu ${showMenu ? 'show' : 'hide'}`}>
        <Menu setShowMenu={setShowMenu} />
      </div>
    </>
  );
};

const Menu = ({ setShowMenu }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState(null);

  return (
    <div className="header-menu">
      <ul className="menu-list">
        {MenuList && MenuList.length > 0 ? (
          MenuList.map((menu, index) => {
            const selectedItem =
              menu?.isSubMenu &&
              menu?.subMenu?.subMenuList.filter((ele) => ele.path === location.pathname);

            return (
              <li key={index}>
                <button
                  type="button"
                  onClick={() => {
                    selectedMenu !== index ? setSelectedMenu(index) : setSelectedMenu(null);
                    screen.width > 768
                      ? navigate(menu?.path)
                      : !menu.isSubMenu && navigate(menu?.path);
                    window.scrollTo(0, 0);
                  }}
                  className={`${selectedMenu === index ? '' : ''} ${menu.path !== '/' && menu.path === location.pathname ? 'active-menu' : menu.isSubMenu && selectedItem.length > 0 ? 'active-menu' : location.pathname.includes(menu.name.toLocaleLowerCase()) ? 'active-menu' : ''}`}
                  title={menu?.title}>
                  <span>{menu.name} </span>
                  {menu?.isSubMenu && <IoIosArrowDown />}
                </button>
                {menu?.isSubMenu && (
                  <div className={`submenu ${selectedMenu === index ? 'active' : ''}`}>
                    <div className="submenu-content">
                      <div className="submenu-wrap">
                        <div className="submenu-details">
                          <h4>{menu?.subMenu?.subMenuName}</h4>
                          <p>{menu?.subMenu?.subMenuDes}</p>
                        </div>
                        <div className="submenu-list">
                          <div className="submenu-list-wrap">
                            {menu?.subMenu?.subMenuList.length > 4 ? (
                              <>
                                <ul>
                                  {menu?.subMenu?.subMenuList
                                    .slice(0, Math.ceil(menu?.subMenu?.subMenuList.length / 2))
                                    .map((subMenu, i) => (
                                      <li key={i} title={subMenu?.title}>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            navigate(subMenu?.path);
                                            screen.width <= 768 && setShowMenu(false);
                                            window.scrollTo(0, 0);
                                          }}
                                          style={{
                                            backgroundColor:
                                              window.location.pathname === subMenu?.path &&
                                              '#e2eff5'
                                          }}>
                                          {subMenu?.icon}
                                          <span>{subMenu?.name}</span>
                                        </button>
                                      </li>
                                    ))}
                                </ul>
                                <ul>
                                  {menu?.subMenu?.subMenuList
                                    .slice(Math.ceil(menu?.subMenu?.subMenuList.length / 2))
                                    .map((subMenu, i) => (
                                      <li key={i} title={subMenu?.title}>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            navigate(subMenu?.path);
                                            screen.width <= 768 && setShowMenu(false);
                                            window.scrollTo(0, 0);
                                          }}
                                          style={{
                                            backgroundColor:
                                              window.location.pathname === subMenu?.path &&
                                              '#e2eff5'
                                          }}>
                                          {subMenu?.icon}
                                          <span>{subMenu?.name}</span>
                                        </button>
                                      </li>
                                    ))}
                                </ul>
                              </>
                            ) : (
                              <ul>
                                {menu?.subMenu?.subMenuList.map((subMenu, i) => (
                                  <li key={i} title={subMenu?.title}>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        navigate(subMenu?.path);
                                        screen.width <= 768 && setShowMenu(false);
                                        window.scrollTo(0, 0);
                                      }}
                                      style={{
                                        backgroundColor:
                                          window.location.pathname === subMenu?.path && '#e2eff5'
                                      }}>
                                      {subMenu?.icon}
                                      <span>{subMenu?.name}</span>
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                          {menu?.subMenu?.isViewAllBtn && (
                            <div className="view-all-btn">
                              <button
                                type="button"
                                onClick={() => {
                                  navigate(menu?.subMenu?.viewAllPath);
                                  window.scrollTo(0, 0);
                                }}
                                title={`${menu?.name} | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}>
                                <span>View all</span>
                                <img
                                  src={RightArrow}
                                  alt={`View All ${menu?.name}`}
                                  title={`${menu?.name} | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                                />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </li>
            );
          })
        ) : (
          <li>
            <button type="button" title={`${CONSTANT.PIXERFECT_SOLUTIONS_LLP} - Home`}>
              <span>Home</span>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Header;
