import React from 'react';
import { Helmet } from 'react-helmet';
import { CONSTANT, SEO } from '../../Utils/Constant';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import Contact from '../../Components/Contact/Contact';
import HeroText from '../../Components/HeroText/HeroText';
import Agencies from '../../Components/Agencies/Agencies';
import ServicesList from '../../Components/Services/ServicesList';
import ClientReview from '../../Components/ClientReview/ClientReview';
import CareerContact from '../../Components/CareerContact/CareerContact';
import './Services.scss';

const Services = () => {
  return (
    <>
      <Helmet>
        <title>{SEO.SERVICES_PAGE_TITLE}</title>
        <meta name="description" content={SEO.SERVICES_PAGE_DESCRIPTION} />

        <meta property="og:title" content={SEO.SERVICES_PAGE_TITLE} />
        <meta property="og:description" content={SEO.SERVICES_PAGE_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.PIXERFECT_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.PIXERFECT_SOLUTIONS_LLP} />
        <meta property="og:image" content="https://www.pixerfect.com/Group_192*192.png" />
        <meta property="twitter:image" content="https://www.pixerfect.com/Group_192*192.png" />
        <meta name="twitter:title" content={SEO.SERVICES_PAGE_TITLE} />
        <meta name="twitter:description" content={SEO.SERVICES_PAGE_DESCRIPTION} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Header />
      <HeroText
        title={'Elevating Your Digital Presence'}
        content={
          'Our comprehensive IT services encompass cutting-edge solutions in web design, development, and beyond with a focus on innovation and client satisfaction.'
        }
      />
      <ServicesList />
      <Agencies />
      <ClientReview />
      <Contact />
      <CareerContact />
      <Footer />
    </>
  );
};

export default Services;
