import AirWeIcon from '../Assets/Images/air-we-icon.png';
import AirWe from '../Assets/Images/air-we-logo.png';
import ProminentWorksImg10 from '../Assets/Images/air-we-project.png';
import AirWeSliderImg2 from '../Assets/Images/air-we-slider/air-we-project-aboutus-page.png';
import AirWeSliderImg6 from '../Assets/Images/air-we-slider/air-we-project-chatting.png';
import AirWeSliderImg4 from '../Assets/Images/air-we-slider/air-we-project-features.png';
import AirWeSliderImg1 from '../Assets/Images/air-we-slider/air-we-project-home-page.png';
import AirWeSliderImg5 from '../Assets/Images/air-we-slider/air-we-project-parcel-carrier.png';
import AirWeSliderImg3 from '../Assets/Images/air-we-slider/air-we-project-travel-companion.png';
import AssisTreeIcon from '../Assets/Images/assistree-icon.png';
import AssisTree from '../Assets/Images/assistree-logo.png';
import ProminentWorksImg9 from '../Assets/Images/assistree-project.png';
import AssisTreeSliderImg4 from '../Assets/Images/assistree-slider/assistree-project-cover-latter.png';
import AssisTreeSliderImg2 from '../Assets/Images/assistree-slider/assistree-project-features.png';
import AssisTreeSliderImg5 from '../Assets/Images/assistree-slider/assistree-project-generate-proposal.png';
import AssisTreeSliderImg1 from '../Assets/Images/assistree-slider/assistree-project-home-page.png';
import AssisTreeSliderImg3 from '../Assets/Images/assistree-slider/assistree-project-message-generate.png';
import AssisTreeSliderImg7 from '../Assets/Images/assistree-slider/assistree-project-messages.png';
import AssisTreeSliderImg6 from '../Assets/Images/assistree-slider/assistree-project-reviews.png';
import AtoZQuizIcon from '../Assets/Images/atoz-quiz-icon.png';
import AtoZQuiz from '../Assets/Images/atoz-quiz-logo.png';
import ProminentWorksImg14 from '../Assets/Images/atoz-quiz-project.png';
import AtoZQuizSliderImg2 from '../Assets/Images/atoz-quiz-slider/atoz-quiz-project-Bonus-question- screen-1.png';
import AtoZQuizSliderImg3 from '../Assets/Images/atoz-quiz-slider/atoz-quiz-project-Bonus-question- screen-2.png';
import AtoZQuizSliderImg8 from '../Assets/Images/atoz-quiz-slider/atoz-quiz-project-coin-history.png';
import AtoZQuizSliderImg9 from '../Assets/Images/atoz-quiz-slider/atoz-quiz-project-game-finish-screen-1.png';
import AtoZQuizSliderImg10 from '../Assets/Images/atoz-quiz-slider/atoz-quiz-project-gameover-screen.png';
import AtoZQuizSliderImg1 from '../Assets/Images/atoz-quiz-slider/atoz-quiz-project-home-screen.png';
import AtoZQuizSliderImg4 from '../Assets/Images/atoz-quiz-slider/atoz-quiz-project-login-screen.png';
import AtoZQuizSliderImg6 from '../Assets/Images/atoz-quiz-slider/atoz-quiz-project-play-area-screen-1.png';
import AtoZQuizSliderImg7 from '../Assets/Images/atoz-quiz-slider/atoz-quiz-project-play-area-screen-2.png';
import AtoZQuizSliderImg5 from '../Assets/Images/atoz-quiz-slider/atoz-quiz-project-play-screen.png';
import AtoZQuizSliderImg11 from '../Assets/Images/atoz-quiz-slider/atoz-quiz-project-price-list.png';
import AtoZQuizSliderImg12 from '../Assets/Images/atoz-quiz-slider/atoz-quiz-project-search-screen-1.png';
import AtoZQuizSliderImg13 from '../Assets/Images/atoz-quiz-slider/atoz-quiz-project-search-screen.png';
import AtoZQuizSliderImg14 from '../Assets/Images/atoz-quiz-slider/atoz-quiz-project-use-lifeline-screen.png';
import BigKheti from '../Assets/Images/big-kheti-logo.png';
import ProminentWorksImg4 from '../Assets/Images/big-kheti-project.png';
import BigKhetiIcon from '../Assets/Images/bigkheti-icon.png';
import BigKhetiSliderImg4 from '../Assets/Images/bigkheti-slider/bigkheti-project-app-store.png';
import BigKhetiSliderImg2 from '../Assets/Images/bigkheti-slider/bigkheti-project-details.png';
import BigKhetiSliderImg1 from '../Assets/Images/bigkheti-slider/bigkheti-project-home-page.png';
import BigKhetiSliderImg3 from '../Assets/Images/bigkheti-slider/bigkheti-project-shopping-page.png';
import ProminentWorksImg6 from '../Assets/Images/capital-calcs-project.png';
import CapitalCalsIcon from '../Assets/Images/capital-cals-icon.png';
import CapitalCals from '../Assets/Images/capital-cals-logo.png';
import CapitalCalsSliderImg1 from '../Assets/Images/capitalcalc-slider/Capitalcalc1.png';
import CapitalCalsSliderImg2 from '../Assets/Images/capitalcalc-slider/Capitalcalc2.png';
import CapitalCalsSliderImg3 from '../Assets/Images/capitalcalc-slider/Capitalcalc3.png';
import CapitalCalsSliderImg4 from '../Assets/Images/capitalcalc-slider/Capitalcalc4.png';

import AirWeImg from '../Assets/Images/case-study-air-we-project.png';
import AssisTreeImg from '../Assets/Images/case-study-assistree-project.png';
import AtoZquizImg from '../Assets/Images/case-study-atoz-quiz-project.png';
import BigKhetiImg from '../Assets/Images/case-study-bigkheti-project.png';
import CapitalCalsImg from '../Assets/Images/case-study-capital-cals-project.png';
import CupcakeImg from '../Assets/Images/case-study-cupcake-castles-project.png';
import DoulaMatchImg from '../Assets/Images/case-study-doula-match-project.png';
import GreenlabImg from '../Assets/Images/case-study-greenlab-project.png';
import IngridoOpionImg from '../Assets/Images/case-study-ingrido-opion-project.png';
import KidsAcademyImg from '../Assets/Images/case-study-kids-academy-project.png';
import KoomzImg from '../Assets/Images/case-study-koomz-project.png';
import NeurofixImg from '../Assets/Images/case-study-neurofenix-project.png';
import SpaceTruckImg from '../Assets/Images/case-study-space-truck-project.png';
import CupcakeCastlesIcon from '../Assets/Images/cupcake-castles-icon.png';
import CupcakeCastles from '../Assets/Images/cupcake-castles-logo.png';
import ProminentWorksImg2 from '../Assets/Images/cupcake-castles-project.png';
import CupCakeSliderImg2 from '../Assets/Images/cupcake-slider/cupcake-castle-project-destinations.png';
import CupCakeSliderImg1 from '../Assets/Images/cupcake-slider/cupcake-castle-project-home-page.png';
import CupCakeSliderImg6 from '../Assets/Images/cupcake-slider/cupcake-castle-project-planning-process.png';
import CupCakeSliderImg4 from '../Assets/Images/cupcake-slider/cupcake-castle-project-services-page.png';
import CupCakeSliderImg5 from '../Assets/Images/cupcake-slider/cupcake-castle-project-vacation-planning.png';
import CupCakeSliderImg3 from '../Assets/Images/cupcake-slider/cupcake-castle-project-vacations-best.png';
import DoulaMatchIcon from '../Assets/Images/doula-match-icon.png';
import DoulaMatch from '../Assets/Images/doula-match-logo.png';
import ProminentWorksImg3 from '../Assets/Images/doula-match-project.png';
import DoulaMatchSliderImg4 from '../Assets/Images/doulamatch-slider/doula-match-project-hiring.png';
import DoulaMatchSliderImg1 from '../Assets/Images/doulamatch-slider/doula-match-project-home-page.png';
import DoulaMatchSliderImg3 from '../Assets/Images/doulamatch-slider/doula-match-project-match-section.png';
import DoulaMatchSliderImg2 from '../Assets/Images/doulamatch-slider/doula-match-project-reviews.png';
import GreenlabIcon from '../Assets/Images/greenlab-icon.png';
import Greenlab from '../Assets/Images/greenlab-logo.png';
import ProminentWorksImg8 from '../Assets/Images/greenlab-project.png';
import GreenLabSliderImg2 from '../Assets/Images/greenlab-slider/greenlab-project-body-scan.png';
import GreenLabSliderImg5 from '../Assets/Images/greenlab-slider/greenlab-project-classes.png';
import GreenLabSliderImg1 from '../Assets/Images/greenlab-slider/greenlab-project-home-page.png';
import GreenLabSliderImg3 from '../Assets/Images/greenlab-slider/greenlab-project-products-store.png';
import GreenLabSliderImg4 from '../Assets/Images/greenlab-slider/greenlab-project-products.png';
import GreenLabSliderImg6 from '../Assets/Images/greenlab-slider/greenlab-project-works.png';
import CalculateUsProd from '../Assets/Images/Calculate-us-brand.png';
import ProminentWorksImgCalculateUs from '../Assets/Images/ProminentWorksImg12.png';
import IngridoOpionIcon from '../Assets/Images/ingrido-opion-icon.png';
import IngridoOpion from '../Assets/Images/ingrido-opion-logo.png';
import ProminentWorksImg7 from '../Assets/Images/ingrido-opion-project.png';
import IngridoOptionSliderImg1 from '../Assets/Images/ingrido-option-slider/Ingrido1.png';
import IngridoOptionSliderImg2 from '../Assets/Images/ingrido-option-slider/Ingrido2.png';
import IngridoOptionSliderImg3 from '../Assets/Images/ingrido-option-slider/Ingrido3.png';
import IngridoOptionSliderImg4 from '../Assets/Images/ingrido-option-slider/Ingrido4.png';
import IngridoOptionSliderImg5 from '../Assets/Images/ingrido-option-slider/Ingrido5.png';
import IngridoOptionSliderImg6 from '../Assets/Images/ingrido-option-slider/Ingrido6.png';

import ProminentWorksImg13 from '../Assets/Images/kid-academy-project.png';
import KidsAcademyIcon from '../Assets/Images/kids-academy-icon.png';
import KidsAcademy from '../Assets/Images/kids-academy-logo.png';
import KidAcademySliderImg1 from '../Assets/Images/kids-academy-slider/Kids1.png';
import KidAcademySliderImg2 from '../Assets/Images/kids-academy-slider/Kids2.png';
import KidAcademySliderImg3 from '../Assets/Images/kids-academy-slider/Kids3.png';
import KidAcademySliderImg4 from '../Assets/Images/kids-academy-slider/Kids4.png';
import KidAcademySliderImg5 from '../Assets/Images/kids-academy-slider/Kids5.png';
import KidAcademySliderImg6 from '../Assets/Images/kids-academy-slider/KIds6.png';
import KoomzIcon from '../Assets/Images/koomz-icon.png';
import Koomz from '../Assets/Images/koomz-logo.png';
import ProminentWorksImg5 from '../Assets/Images/koomz-project.png';
import KoomzSliderImg1 from '../Assets/Images/koomz-slider/koomz-project-home-page.png';
import KoomzSliderImg3 from '../Assets/Images/koomz-slider/koomz-project-store.png';
import KoomzSliderImg2 from '../Assets/Images/koomz-slider/koomz-project-tshirt-section.png';
import MySocietyIcon from '../Assets/Images/my-society-icon.png';
import MySociety from '../Assets/Images/my-society-logo.png';
import NeurofixIcon from '../Assets/Images/neurofenix-icon.png';
import Neurofix from '../Assets/Images/neurofix-logo.png';
import ProminentWorksImg1 from '../Assets/Images/neurofix-project.png';
import NeurofixSliderImg6 from '../Assets/Images/neurofix-slider/neurofenix-project-clinical-evidence.png';
import NeurofixSliderImg1 from '../Assets/Images/neurofix-slider/neurofenix-project-home-page.png';
import NeurofixSliderImg2 from '../Assets/Images/neurofix-slider/neurofenix-project-quote-page.png';
import NeurofixSliderImg7 from '../Assets/Images/neurofix-slider/neurofenix-project-referral.png';
import NeurofixSliderImg3 from '../Assets/Images/neurofix-slider/neurofenix-project-service-page.png';
import NeurofixSliderImg4 from '../Assets/Images/neurofix-slider/neurofenix-project-team.png';
import NeurofixSliderImg5 from '../Assets/Images/neurofix-slider/neurofenix-project-works.png';
import ProminentWorksImg12 from '../Assets/Images/space-truck-game-project.png';
import SpaceTruckIcon from '../Assets/Images/space-truck-icon.png';
import SpaceTruck from '../Assets/Images/space-truck-logo.png';
import SpaceTruckSliderImg7 from '../Assets/Images/space-truck-slider/space-truck-project-game-play-collect-coin.png';
import SpaceTruckSliderImg5 from '../Assets/Images/space-truck-slider/space-truck-project-game-play-level0-screen.png';
import SpaceTruckSliderImg6 from '../Assets/Images/space-truck-slider/space-truck-project-game-play-level2-screen.png';
import SpaceTruckSliderImg4 from '../Assets/Images/space-truck-slider/space-truck-project-game-play-screen.png';
import SpaceTruckSliderImg1 from '../Assets/Images/space-truck-slider/space-truck-project-home-screen.png';
import SpaceTruckSliderImg2 from '../Assets/Images/space-truck-slider/space-truck-project-select-car.png';
import SpaceTruckSliderImg3 from '../Assets/Images/space-truck-slider/space-truck-project-select-levels.png';
import IndiaFlag from '../Assets/Svg/IndiaFlag';
import UkFlag from '../Assets/Svg/UkFlag';
import UsFlag from '../Assets/Svg/UsFlag';
import CalculatorProductLogo from '../Assets/Images/CalculatorProductIcon.png';
import CalculateUsSlider1 from '../Assets/Images/Calculate-Us-slider/Calcus1.png';
import CalculateUsSlider2 from '../Assets/Images/Calculate-Us-slider/Calcus2.png';
import CalculateUsSlider3 from '../Assets/Images/Calculate-Us-slider/Calcus3.png';
import CalculateUsSlider4 from '../Assets/Images/Calculate-Us-slider/Calcus4.png';
import CalculateUsSlider5 from '../Assets/Images/Calculate-Us-slider/Calcus5.png';
import CalculateUsSlider6 from '../Assets/Images/Calculate-Us-slider/Calcus6.png';

import CalcTimesBrand from '../Assets/Images/CalculatorTimes/calcTime-brand.png';
import CalcTimesLogo from '../Assets/Images/CalculatorTimes/calcTime-logo.png';
import CalcTimesProduct from '../Assets/Images/CalculatorTimes/calcTime-product.png';
import CalcTimesProductInside from '../Assets/Images/CalculatorTimes/CalcTimes-project-inner-img.png';
import CalcTimesSlider1 from '../Assets/Images/CalculatorTimes/Calculator-Times-slider-1.png';
import CalcTimesSlider2 from '../Assets/Images/CalculatorTimes/Calculator-Times-slider-2.png';
import CalcTimesSlider3 from '../Assets/Images/CalculatorTimes/Calculator-Times-slider-3.png';

import MausamBrand from '../Assets/Images/mausam/mausam-brand.png';
import MausamLogo from '../Assets/Images/mausam/mausam-logo.png';
import MausamProduct from '../Assets/Images/mausam/mausam-product.png';
import MausamProductInside from '../Assets/Images/mausam/mausam-product-inside.png';
import MausamSlider1 from '../Assets/Images/mausam/mausam-slider-1.png';
import MausamSlider2 from '../Assets/Images/mausam/mausam-slider-2.png';
import MausamSlider3 from '../Assets/Images/mausam/mausam-slider-3.png';
import MausamSlider4 from '../Assets/Images/mausam/mausam-slider-4.png';
import MausamSlider5 from '../Assets/Images/mausam/mausam-slider-5.png';

import MySocietySlider1 from '../Assets/Images/My-Society/Mysociety1.png';
import MySocietySlider2 from '../Assets/Images/My-Society/Mysociety2.png';
import MySocietySlider3 from '../Assets/Images/My-Society/Mysociety3.png';
import MySocietyProductImg from '../Assets/Images/mysocietyProduct.png';
import MySocietyProductInsideImg from '../Assets/Images/my-society-inside.png';

const ProminentWorksList = [
  {
    subTitle: 'Customer Relationship Management',
    title: 'Application for Resort, Cruise, and Hotel Bookings',
    content:
      'We are developing a comprehensive website for booking resorts, cruises, and hotels, featuring secure payment integration and personalized user interfaces to ensure a seamless and efficient reservation process.',
    flag: <UsFlag />,
    region: 'US',
    duration: 'In Progress',
    platforms: 'Web Application',
    technology: 'Angular, Node.js',
    projectImgUrl: ProminentWorksImg2,
    projectLogoUrl: CupcakeCastlesIcon,
    projectBrandUrl: CupcakeCastles,
    bgType: 'midnight-slate',
    slideType: 'web',
    heroTitle: 'Cupcake Castles - ',
    heroSubTitle: 'A travelling management networking platform',
    industry: 'Customer Relationship Management',
    deliverables: 'An Optimized Web Platform',
    projectImg: CupcakeImg,
    category: 'Web Development',
    keywords: 'resort application, booking agent, booking , cupcake castles',
    client: 'Laurie Burg',
    location: 'United States of America',
    contentTitle: 'Cupcake Castle: A Memorable Vacations for Busy Families',
    description:
      'Cupcake Castles Travel Company was founded in 2013 by Laurie Burg, following her passion to help others realize the dream of a Walt Disney World vacation. By sharing the love and knowledge of a destination she had grown up visiting, a company was formed and a vision, manifested. What started as a small, exciting dream, swiftly became an empire in the making. The culture Laurie created began with a place where like-minded individuals could come together to learn from each other, grow with each other, and establish relationships that would last a lifetime.',
    challenges:
      "We recently launched 'Cupcake Castles', a specialized CRM web tool for managing bookings across resorts, cruises, and tours in the US. Initially, all booking details were stored in Google Sheets, where each entry covered a wide range of information including bookings, trips, agents, tour operators, resorts, cruises, emails, and event specifics. Moving this complex dataset into our MongoDB database presented a significant challenge.<br /><br /> The main task was to smoothly transfer these varied fields and maintain their relationships within MongoDB's structured collections. We carefully mapped out the database schema to handle more than 40 fields per record, ensuring that all details—from booking specifics to associated entities like agents and tour operators—were accurately preserved. <br /><br />With meticulous planning and leveraging MongoDB's capabilities, we successfully imported the entire dataset while maintaining data accuracy and relational integrity. This process required both technical expertise in database management and strategic decision-making to optimize performance and ensure a seamless user experience within the application. ",
    solution:
      "We've developed 12-14 tables to organize and maintain the relationships between our records effectively. Each table is designed to handle specific types of data, ensuring that information such as bookings, trips, agents, and tour operators are stored and accessed efficiently. <br /><br /> After rigorous testing across different environments, we've implemented a strong script to seamlessly import data into our production database. This process involved meticulous planning and troubleshooting to ensure data accuracy and system reliability.",
    result:
      'Improved Customer Engagement: Enhanced customer engagement through personalized interactions, streamlined communication channels, and tailored services that meet individual needs and preferences.<br /><br />Better Customer Satisfaction: Increased customer satisfaction by delivering high-quality products or services, resolving issues promptly, and maintaining clear and transparent communication throughout the customer journey.<br /><br />Improved Data Management: Enhanced data management capabilities, ensuring secure storage, efficient retrieval, and accurate analysis of customer information to make informed business decisions and optimize operational efficiency.',
    sliderImg: [
      CupCakeSliderImg1,
      CupCakeSliderImg2,
      CupCakeSliderImg3,
      CupCakeSliderImg4,
      CupCakeSliderImg5,
      CupCakeSliderImg6
    ],
    path: 'cupcake-castles'
  },
  {
    subTitle: 'Health Product website',
    title: 'Health Status by Meticulously Analyzing Heartbeats',
    content:
      'Web application designed to analyze health data through weekly ratios, offering personalized guidance based on the information gathered by the platform.',
    flag: <UkFlag />,
    region: 'UK',
    duration: 'In Progress',
    platforms: 'Web Application',
    technology: 'Express, React, Node.js, MongoDB',
    projectImgUrl: ProminentWorksImg8,
    projectLogoUrl: GreenlabIcon,
    projectBrandUrl: Greenlab,
    bgType: 'blue',
    slideType: 'web',
    heroTitle: 'Green Lab - ',
    heroSubTitle: 'Analyze health data through weekly ratios',
    industry: 'Medical & Healthcare',
    deliverables: 'An Optimized Web App Platform',
    projectImg: GreenlabImg,
    category: 'Web Development',
    keywords:
      'Heartbeat analyzer,Heart health app,Heartbeat monitor,Heart rate analysis,Cardiovascular health,Heart rate checker,Heart health tracker,Pulse rate monitor,Cardiac health app,Heart rate variability,Real-time heart rate,Heartbeat detection,Heart rhythm analysis,ECG monitor,Heart health insights,Blood pressure monitoring,Arrhythmia detection,Heart health reports,Cardiac performance,eart rate history,Cardiovascular fitness,Heart health monitoring,Wellness tracking,Preventive health,Healthy heart,Cardio care,Heart condition insights,Heart disease prevention,Heart wellness tips,Heart-focused health,Monitor heart health,Analyze heartbeats,Check heart rate,Improve cardiovascular health,Heart rate insights,Detect heart issues,Track heart wellness,Manage heart health,Real-time heartbeat analysis,Personalized heart tips,Best heart health app,Top-rated heartbeat monitor,Popular heart rate checker,Innovative heart health app,Trusted heartbeat analyzer,Highly rated heart app,Comprehensive heart analysis,Free heart rate monitor,Recommended heart app',
    client: 'Chinmay Bhatt',
    location: 'United Kingdom',
    contentTitle: 'Green Lab: Meticulously analyzing heartbeats',
    description:
      'Green Lab offers a comprehensive health supplement store and fitness tracking application tailored to support individuals in their wellness pursuits. Featuring a variety of high-quality supplements such as vitamins, minerals, protein powders, and specialty formulas, Green Lab aims to enhance performance and overall health. The integrated fitness tracking application allows users to set goals, track progress, and optimize workouts seamlessly. With a focus on quality products and user-friendly technology, Green Lab provides practical solutions for achieving a balanced lifestyle.',
    challenges:
      "Developing Green Lab's website posed several challenges, including ensuring responsive design across diverse devices while maintaining consistency in user experience. Integrating robust e-commerce functionalities, such as secure transactions and efficient inventory management, required meticulous planning and execution. Implementing a sophisticated fitness tracking module that accurately captures and presents workout data posed technical complexities, necessitating seamless integration with the website's architecture. Designing an intuitive user interface that effectively communicates supplement information, fitness tracking capabilities, and educational content was crucial for enhancing user engagement and satisfaction. Lastly, optimizing website performance to ensure fast loading times and smooth navigation across different browsing conditions demanded continuous monitoring and iterative improvements to meet user expectations effectively.",
    solution:
      'Fitness Tracking Module: Developed and integrated a comprehensive fitness tracking module that accurately captures and displays workout data. Ensured seamless integration with the website for user-friendly interaction and goal tracking.<br /><br />E-commerce Integration: Implemented a secure e-commerce platform with robust payment gateways and streamlined checkout processes. Integrated inventory management systems for real-time stock updates and automated order processing.<br /><br />Performance Optimization: Optimized website performance through caching, image compression, and script minification. Conducted regular performance audits and optimizations to ensure fast loading times and smooth browsing experience across all devices.',
    result:
      "Green Lab's website development efforts led to enhanced user experience with responsive design and intuitive interfaces, efficient e-commerce operations, accurate fitness tracking capabilities, improved usability through iterative enhancements, and optimized performance with reduced loading times, ensuring a seamless and satisfying online experience for customers.",
    sliderImg: [
      GreenLabSliderImg1,
      GreenLabSliderImg2,
      GreenLabSliderImg3,
      GreenLabSliderImg4,
      GreenLabSliderImg5,
      GreenLabSliderImg6
    ],
    path: 'greenlab'
  },
  {
    subTitle: 'Chrome extension',
    title: 'A GPT Powered Chrome Extension',
    content:
      'Assistree AI is an AI-powered productivity tool that helps freelancers save time and get more done on Upwork. It offers a variety…',
    flag: <IndiaFlag />,
    region: 'India',
    duration: '4 months',
    platforms: 'Chrome Extension',
    technology: 'JavaScript, HTML, CSS, Node.js',
    projectImgUrl: ProminentWorksImg9,
    projectLogoUrl: AssisTreeIcon,
    projectBrandUrl: AssisTree,
    bgType: 'midnight-slate',
    slideType: 'web',
    heroTitle: 'Assistree - ',
    heroSubTitle: 'Helps freelancers save time and get more done',
    industry: 'AI-powered Productivity Tool',
    deliverables: 'An Optimized Browser Extension',
    projectImg: AssisTreeImg,
    category: 'Chrome Extension Development',
    keywords:
      'AI productivity tool,Freelancer productivity app,Upwork assistant,Time-saving tool,Productivity booster,Freelance management,AI task manager,Upwork productivity,Freelance efficiency,Work optimization app,Task automation,Time management,Project management,AI task assistant,Workflow automation,Freelance project tracker,Proposal management,Job application automation,Client communication,Deadline tracking,Boost productivity,Save time on Upwork,Freelance efficiency tool,Improve workflow,Task efficiency,Maximize output,Optimize freelance work,Smart productivity,Efficient task management,Productivity enhancement,Manage Upwork projects,Automate freelance tasks,Increase productivity,Organize freelance work,Simplify project management,Time-saving features,AI assistant for Upwork,Improve freelance workflow,Efficient work management,Freelance productivity boost,Best productivity tool,Top-rated AI assistant,Popular freelancer tool,Upalert alternative,Innovative productivity app,Trusted task manager,Highly rated productivity app,Comprehensive freelance tool,Free productivity assistant,Recommended AI tool',
    client: 'Kim James',
    location: 'India',
    contentTitle: 'Assistree: GPT powered chrome extension offers a variety',
    description:
      'Unlock the Power of Personalized Cover Letters with Assistree. Look no further! Assistree, your ultimate productivity tool, is here to streamline your job application process like never before. With Assistree, you can effortlessly generate personalized cover letters in a matter of seconds. Thanks to our cutting-edge AI technology, your cover letters will stand out from the crowd, increasing your chances of getting hired faster than ever.',
    challenges:
      'During the development of Assistree, we faced challenges such as ensuring seamless integration across diverse web browsers while maintaining consistent performance, refining AI models to reliably produce accurate and relevant content, prioritizing user privacy with stringent data security measures, designing an intuitive user interface within the compact extension format, and optimizing overall performance to minimize browser impact during AI processing and document creation. Overcoming these obstacles was crucial to delivering a refined product that enhances user productivity and job application success through advanced AI technology.',
    solution:
      'Cross-Browser Compatibility: Implemented robust compatibility testing across multiple web browsers (e.g., Chrome, Firefox) to ensure seamless integration and consistent performance for users across different platforms.<br /><br />Enhanced Data Security Measures: Implemented strong encryption protocols and strict adherence to privacy regulations to safeguard user data and maintain confidentiality throughout the cover letter generation process.<br /><br />Performance Optimization: Employed techniques such as efficient resource allocation and streamlined processing algorithms to minimize browser impact and ensure smooth operation during AI processing and document generation.',
    result:
      "Assistree successfully overcame development challenges to deliver a robust Chrome extension for personalized cover letter generation. It integrates seamlessly across web browsers, ensuring consistent performance. Advanced AI models generate accurate and tailored cover letters, enhancing application effectiveness. Strict data security measures maintain user privacy, while an intuitive interface facilitates easy use. Performance optimizations minimize browser impact, ensuring smooth operation during intensive tasks. These achievements highlight Assistree's reliability in streamlining the job application process effectively.",
    sliderImg: [
      AssisTreeSliderImg1,
      AssisTreeSliderImg2,
      AssisTreeSliderImg3,
      AssisTreeSliderImg4,
      AssisTreeSliderImg5,
      AssisTreeSliderImg6,
      AssisTreeSliderImg7
    ],
    path: 'assistree'
  },
  {
    subTitle: 'Mobile Calculator Application',
    title: 'Your Ultimate Calculator App',
    content: 'A comprehensive calculator app for finance, mathematical, and health calculations.',
    flag: <IndiaFlag />,
    region: 'India',
    duration: '8 month',
    platforms: 'Android',
    technology: 'Flutter framework, Figma',
    projectImgUrl: ProminentWorksImgCalculateUs,
    projectLogoUrl: CalculatorProductLogo,
    projectBrandUrl: CalculateUsProd,
    bgType: 'blue',
    slideType: 'mobile',
    heroTitle: 'Calculate Us - ',
    heroSubTitle: 'Multiple types of calculators and Implementing formulas',
    industry: 'Mobile Application Development',
    deliverables: 'Mobile Application for Android Devices',
    projectImg: ProminentWorksImgCalculateUs,
    category: 'Mobile Application Development',
    keywords:
      'Calculator tools,Online calculators,Financial calculators,Health calculators,Math calculators,Scientific calculators,Retirement calculators,Mortgage calculators,Loan calculators,BMI calculators,Tax calculators,Investment calculators,Currency converters,Unit converters,Time zone converters',
    client: 'Pixerfect Solutions LLP',
    location: 'India',
    contentTitle: 'CalculateUs: Your Ultimate Calculator App',
    description:
      'CalculateUs is your go-to app for a wide range of calculators designed to simplify complex calculations in finance, mathematics, and health. Whether you are planning your finances, solving equations, or tracking health metrics, our app provides accurate results instantly. Developed with user experience in mind, CalculateUs offers intuitive interfaces and precise calculations tailored to meet your needs.',
    challenges:
      'Developing a robust set of calculators covering diverse domains. <br /><br />Ensuring accuracy and reliability across all calculations.<br /><br />Designing a user-friendly interface for seamless navigation and usability.',
    solution:
      'Conducted thorough research and planning to identify essential calculator categories and functionalities.<br/><br/>Implemented rigorous testing procedures to validate calculation accuracy and reliability.<br/><br/>Utilized Flutter is flexible UI framework to create an intuitive and responsive user interface.',
    result:
      'Successfully launched an app featuring over [number] calculators across finance, mathematics, and health.<br/><br/>Received positive user feedback for accuracy, ease of use, and comprehensive functionality.<br/><br/>Achieved numbers of downloads and maintained a high user rating on the Google Play Store.',
    sliderImg: [
      CalculateUsSlider1,
      CalculateUsSlider2,
      CalculateUsSlider3,
      CalculateUsSlider4,
      CalculateUsSlider5,
      CalculateUsSlider6
    ],
    path: 'calculate-us',
    appLink: 'https://play.google.com/store/apps/details?id=com.pf.calculators'
  },
  {
    subTitle: 'Medical & Healthcare',
    title: 'E-Commerce Website for Healthcare Products',
    content:
      'We developed an E-Commerce website dedicated to the online purchasing of healthcare products, ensuring a seamless user experience for customers.',
    flag: <UsFlag />,
    region: 'US',
    duration: '8 months',
    platforms: 'Web Application',
    technology: 'React, MongoDB, Node.js',
    projectImgUrl: ProminentWorksImg1,
    projectLogoUrl: NeurofixIcon,
    projectBrandUrl: Neurofix,
    bgType: 'midnight-slate',
    slideType: 'web',
    heroTitle: 'Neurofenix - ',
    heroSubTitle: 'A health product networking platform',
    industry: 'Medical & Healthcare',
    deliverables: 'An Optimized Web Platform',
    projectImg: NeurofixImg,
    category: 'Web Development',
    keywords: 'Medical & Healthcare, Web Development, USA, ',
    client: 'Guillem Singla, Dimitrios Athanasiou',
    location: 'United States of America',
    contentTitle: 'Neurofenix: Empowering Stroke Recovery at Home',
    description:
      'Neurofenix, journey began with a deep, personal connection to stroke rehabilitation. Neurofenix’s co-founders, Guillem Singla and Dimitrios Athanasiou, inspired by their own family experiences, envisioned a world where stroke recovery was not just effective but accessible to everyone. Blending their expertise in Biomedical Engineering, Neurotechnology, and Biomechanics to innovate in stroke care. Where elder-age people receive treatment for a lack of consistent, intensive care that hinders the recovery process.',
    challenges:
      'A health care product company had been selling their products through traditional brick-and-mortar stores but wanted to expand their reach by setting up an e-commerce platform.<br /><br /> They needed a solution that would enable easy online purchasing and provide a seamless user experience for customers.',
    solution:
      'We developed an e-commerce platform that was user-friendly and provided customers with a hassle-free shopping experience.<br /><br /> The platform was optimized for search engines, making it easier for customers to find the products they were looking for. We also implemented various security measures to ensure that customer data was protected and the online shopping experience was secure.',
    result:
      'The e-commerce platform proved to be successful, and the health care product company saw a significant increase in sales. Customers appreciated the convenience of shopping online and the ease of finding the products they needed.<br /><br /> The client was pleased with the results and continues to use the e-commerce platform to sell their health care products online.',
    sliderImg: [
      NeurofixSliderImg1,
      NeurofixSliderImg2,
      NeurofixSliderImg3,
      NeurofixSliderImg4,
      NeurofixSliderImg5,
      NeurofixSliderImg6,
      NeurofixSliderImg7
    ],
    path: 'neurofix'
  },
  {
    subTitle: 'Medical & Healthcare',
    title: 'Perfect Match for Pre & Post-Pregnancy Needs',
    content:
      'We created a webisite that provided information to expectant families on their pregnancy and post-pregnancy needs, preferences, and budget.',
    flag: <UsFlag />,
    region: 'US',
    duration: '8 months',
    platforms: 'Web Application',
    technology: 'React, MongoDB, Node.js',
    projectImgUrl: ProminentWorksImg3,
    projectLogoUrl: DoulaMatchIcon,
    projectBrandUrl: DoulaMatch,
    bgType: 'blue',
    slideType: 'web',
    heroTitle: 'Doula Match - ',
    heroSubTitle: 'A pre-post pregnancy taker networking platform',
    industry: 'Medical & Healthcare',
    deliverables: 'An Optimized Web Platform',
    projectImg: DoulaMatchImg,
    category: 'Web Development',
    keywords: 'match ,  Doula Match, doulas, doula’s training, post-pregnancy needs',
    client: 'Kim James',
    location: 'United States of America',
    contentTitle: 'Doula Match: A Perfect Doula for Parent And Families',
    description:
      'Doula Match developed to help expectant families quickly and efficiently find doulas who are available during their due dates. It’s also a great place to get a baseline understanding of each doula’s training, certification, and experience levels. Kim James, a certified doula, doula trainer, and childbirth educator in Seattle, Washington and her husband Pat. Please contact us with your suggestions for improvement, additions to the certifications and qualifications section, or with any questions or concerns. We value your feedback.',
    challenges:
      'The objective of the website is to aid individuals in locating Doulas for both pre and post-pregnancy needs. The primary challenge is to assess the precise demands of customers and identify the ideal Doula match.<br /><br /> The selected Doulas must fulfill the requirements and also provide exceptional service.',
    solution:
      'To address the challenge of evaluating accurate customer requirements and finding the best match for Doulas, the website can incorporate a comprehensive questionnaire for customers to fill out, providing information on their pregnancy and post-pregnancy needs, preferences, and budget.<br /><br /> Using this information, an algorithm can match customers with Doulas who meet their specific requirements and preferences. The website can also provide profiles and reviews of each Doula to enable customers to make informed decisions.<br /><br /> To ensure that selected Doulas deliver exceptional service, the website can establish a rating system that allows customers to rate the quality of service provided by each Doula. Doulas who consistently receive high ratings can be prioritized in the matching algorithm, while those who receive poor ratings can be removed from the platform.<br /><br /> By implementing these measures, the website can facilitate a seamless and efficient process of matching customers with Doulas that meet their requirements, while ensuring that they receive exceptional service.',
    result:
      'The implementation of the proposed solution lead to several positive outcomes.<br /><br />Firstly, the comprehensive questionnaire enabled customers to provide detailed information about their pregnancy and post-pregnancy needs, significantly enhancing the accuracy of the matching process.<br /><br />Secondly, the algorithmic matching of customers with Doulas who met their specific requirements and preferences streamlined the process of finding suitable Doulas, reducing the time and effort required for customers to search on their own.<br /><br />Thirdly, the provision of profiles and reviews of each Doula provided customers with valuable insights into the quality of service offered by each Doula, facilitating informed decision-making.<br /><br />Finally, the rating system incentivized Doulas to provide exceptional service, resulting in an overall improvement in the quality of service provided on the platform and enhanced customer satisfaction.<br /><br />Overall, the implementation of the proposed solution resulted in a user-friendly and efficient platform that offered customers a seamless experience in finding suitable Doulas during and after pregnancy.',
    sliderImg: [
      DoulaMatchSliderImg1,
      DoulaMatchSliderImg2,
      DoulaMatchSliderImg3,
      DoulaMatchSliderImg4
    ],
    path: 'doula-match'
  },
  {
    subTitle: 'E-Commerce Web Application',
    title: 'A Perfect Delivery for Farming Activities',
    content:
      "This website specializes in selling farmers' tools that can significantly assist in farming and reduce the time required for farming activities.",
    flag: <UsFlag />,
    region: 'US',
    duration: '6 months',
    platforms: 'Web Application',
    technology: 'Angular, Node.js',
    projectImgUrl: ProminentWorksImg4,
    projectLogoUrl: BigKhetiIcon,
    projectBrandUrl: BigKheti,
    bgType: 'midnight-slate',
    slideType: 'web',
    heroTitle: 'Big Kheti - ',
    heroSubTitle: 'A farming product and grocery selling platform',
    industry: 'E-Commerce',
    deliverables: 'An Optimized Web Platform',
    projectImg: BigKhetiImg,
    category: 'Web Development',
    keywords: 'Kheti, Agri Digital Platform, farmers,',
    client: 'Pranav Sir',
    location: 'United States of America',
    contentTitle: 'Big Kheti: Modernizing Farming for the Future',
    description:
      'In the year 2022, a group of enthusiastic entrepreneurs founds BigKheti. The premier Agri Digital Platform in India, BigHaat, uses science, data, and technology to alter the entire value chain of agriculture, from pre-harvest to post-harvest. It has an impact on millions of farmers around the nation by making a wide variety of high-quality inputs accessible, giving end-to-end crop assistance, and creating market connections for a variety of commodities, delivering a 360-degree solution to farmers with a highly distinctive approach.',
    challenges:
      "This website specializes in selling farmers' tools that can significantly assist in farming and reduce the time required for farming activities. The tools are sourced from multiple origins and delivered to both the customer and the farmer's doorstep.<br /><br /> The primary challenge faced by the website is automating the process of managing shipments from various origins while ensuring the lowest possible cost and the best shipping method.",
    solution:
      'Integration of Shipping Software: The website can integrate shipping software that automates the process of managing shipments from various origins. This software can identify the most efficient shipping method for each order based on the destination, weight, and size of the item.<br /><br /> Collaborate with Multiple Shipping Companies: By collaborating with multiple shipping companies, the website can take advantage of competitive pricing to reduce shipping costs. The website can negotiate bulk shipping rates with each company, allowing them to select the best shipping method at the lowest possible cost.<br /><br /> Implement Supply Chain Management: The website can implement supply chain management techniques to optimize the movement of goods from different origins to the final destination. This involves coordinating with suppliers, transporters, and other stakeholders involved in the shipping process to ensure that all the necessary steps are executed in the most efficient and cost-effective manner.<br /><br /> Warehouse Optimization: The website can optimize the warehouse operations to ensure that the right products are shipped at the right time. This involves using advanced inventory management techniques, such as just-in-time inventory and RFID tagging, to track the location and movement of products in real-time.<br /><br /> By adopting these solutions, the website can streamline the shipping process, reduce costs, and enhance customer satisfaction by delivering the right products to the right place at the right time.',
    result:
      'The implementation of the proposed solutions lead to several positive outcomes for the website and its customers.<br /><br />Firstly, the integration of shipping software automated the shipping process and reduced errors, enabling the website to ship products quickly and accurately.<br /><br />Secondly, collaborating with multiple shipping companies helped the website take advantage of competitive pricing and reduced shipping costs, resulting in cost savings for the website and its customers.<br /><br />Thirdly, implementing supply chain management techniques optimized the shipping process, leading to faster and more efficient delivery times.<br /><br />Fourthly, warehouse optimization improved inventory management, reducing the time it took to process and ship orders and enhancing the accuracy of order fulfillment.<br /><br />Overall, the implementation of these solutions resulted in a more streamlined and efficient shipping process for the website, enhancing customer satisfaction, increasing sales, and strengthening its competitive position in the market.',
    sliderImg: [BigKhetiSliderImg1, BigKhetiSliderImg2, BigKhetiSliderImg3, BigKhetiSliderImg4],
    path: 'big-kheti'
  },
  {
    subTitle: 'Clothing web application',
    title: 'Specializes in Selling Customized Clothing Products',
    content:
      'The application is to provide a user-friendly customization module that enables customers to customize their products online.',
    flag: <UsFlag />,
    region: 'US',
    duration: '6 months',
    platforms: 'Web Application',
    technology: 'React, MongoDB, Node.js',
    projectImgUrl: ProminentWorksImg5,
    projectLogoUrl: KoomzIcon,
    projectBrandUrl: Koomz,
    bgType: 'blue',
    slideType: 'web',
    heroTitle: 'Koomz - ',
    heroSubTitle: 'Customize clothes selling platform',
    industry: 'E-Commerce',
    deliverables: 'An Optimized Web Platform',
    projectImg: KoomzImg,
    category: 'Web Development',
    keywords:
      'Online clothing store,Buy clothes online,Fashion shop,Trendy clothing,Affordable fashion,Designer clothes,Latest fashion trends,Fashion deals,Discount clothing,Fashion sale,',
    client: 'Kim James',
    location: 'United States of America',
    contentTitle: 'Koomz: Specializes in selling customized clothing products.',
    description:
      'We are young creatives ranging from visual artists to musical artists who want to push and change the aesthetics of things in music, video, fashion, and art. We believe anyone can be a fashion designer if they choose to collaborate with the people they are designing, developing, or planning for. This is where we come in, we create an opportunity for interesting everyday creatives to work with a design team to create a collection tailored to their individual clothing style',
    challenges:
      'This web application specializes in selling customized clothing products. The primary challenge faced by the application is to provide a user-friendly customization module that enables customers to customize their products online.<br /><br /> Once the order is placed, the application must automatically send the order to the production unit for customization and manage the entire order process to ensure that customer orders are produced according to their requirements, delivered on time, and shipped directly from the warehouse.',
    solution:
      'User-Friendly Customization Module: The web application can provide a user-friendly customization module that enables customers to customize their products online. This module should be easy to navigate, have clear instructions, and allow customers to see a preview of their customized product.<br /><br /> Automated Order Management: The web application can implement an automated order management system that tracks orders from the time they are placed to the time they are delivered. This system should be able to send orders to the production unit automatically and provide real-time updates to customers about the status of their order.<br /><br /> Production Unit Integration: The web application can integrate with the production unit to ensure that the production process is streamlined and efficient. This integration can allow the application to track the progress of each order and ensure that the orders are produced and delivered on time.<br /><br /> Warehouse Optimization: The web application can optimize the warehouse operations to ensure that the right products are shipped at the right time. This involves using advanced inventory management techniques, such as just-in-time inventory and RFID tagging, to track the location and movement of products in real-time.<br /><br />  By adopting these solutions, the web application can streamline the customization, production, and order process, reduce errors, and enhance customer satisfaction by delivering the right products to the right place at the right time.',
    result:
      "The implementation of the proposed solutions can lead to several positive outcomes for the web application and its customers.<br /><br /> Firstly, the user-friendly customization module can enhance the customer experience, making it easier for them to customize their products online and place orders.<br /><br /> Secondly, the automated order management system can reduce errors, minimize delays, and provide real-time updates to customers, improving customer satisfaction.<br /><br /> Thirdly, the integration of the production unit can streamline the production process, reducing lead times and ensuring that orders are produced according to the customer's specifications.<br /><br /> Fourthly, optimizing the warehouse operations can ensure that products are delivered on time, reducing delivery times and improving customer satisfaction.<br /><br /> Overall, the implementation of these solutions can lead to a more streamlined and efficient customization, production, and order process for the web application, resulting in enhanced customer satisfaction, increased sales, and a stronger competitive position in the market.",
    sliderImg: [KoomzSliderImg1, KoomzSliderImg2, KoomzSliderImg3],
    path: 'koomz'
  },
  {
    subTitle: 'Android application',
    title: 'Mobile App That Offers Various Types of Calculators',
    content:
      'Multiple types of calculators and implementing formulas that can accurately calculate investment results for up to a hundred years, Which adopted by Capital Calc',
    flag: <IndiaFlag />,
    region: 'India',
    duration: '8 months',
    platforms: 'Android',
    technology: 'Flutter, Figma',
    projectImgUrl: ProminentWorksImg6,
    projectLogoUrl: CapitalCalsIcon,
    projectBrandUrl: CapitalCals,
    bgType: 'midnight-slate',
    slideType: 'mobile',
    heroTitle: 'Capital Calc - ',
    heroSubTitle: 'Multiple types of calculators and Implementing formulas',
    industry: 'Finance',
    deliverables: 'Mobile Application',
    projectImg: CapitalCalsImg,
    category: 'App Development',
    keywords:
      'Calculator app,Mobile calculator,Free calculator,Best calculator,Simple calculator,Smart calculator,Basic calculator,Advanced calculator,Scientific calculator,Calculator for Android,Finance calculator,Math calculator,Health calculator,Loan calculator,BMI calculator,Currency converter,Unit converter,Percentage calculator,Discount calculator,Tip calculator',
    client: 'Guillem Singla, Dimitrios Athanasiou',
    location: 'India',
    contentTitle: 'Capital Calc: offers various types of calculators',
    description:
      'In the year 2022, a group of enthusiastic entrepreneurs founds BigKheti. The premier Agri Digital Platform in India, BigHaat, uses science, data, and technology to alter the entire value chain of agriculture, from pre-harvest to post-harvest. It has an impact on millions of farmers around the nation by making a wide variety of high-quality inputs accessible, giving end-to-end crop assistance, and creating market connections for a variety of commodities, delivering a 360-degree solution to farmers with a highly distinctive approach.',
    challenges:
      'Capital Calc is a mobile app that offers various types of calculators. The primary challenge faced by the app was to integrate multiple types of calculators and implement formulas that can accurately calculate investment results for up to a hundred years.<br /><br /> This would enable users to obtain future investment estimations and understand how their investment will perform over time.',
    solution:
      'Multi-Type Calculator Integration: The app can integrate various types of calculators, such as investment, savings, and loan calculators, into a single platform. This would enable users to perform different types of financial calculations in one place.<br /><br /> Advanced Formulas Implementation: The app can implement advanced formulas that accurately calculate investment results for up to a hundred years. The formulas should consider various factors, such as interest rates, inflation, and tax implications, to provide accurate investment estimations.<br /><br /> User-Friendly Interface: The app should have a user-friendly interface that makes it easy for users to input their financial data and obtain investment estimations. The interface should be simple, intuitive, and provide clear instructions to users.<br /><br /> Real-Time Updates: The app can provide real-time updates to users about their investment performance. This can help users track their progress and make informed decisions about their investments.<br /><br /> By adopting these solutions, Capital Calc can provide users with a comprehensive financial calculation tool that can accurately calculate investment results and provide future investment estimations. This would help users make informed decisions about their investments and improve their overall financial wellbeing.',
    result:
      "The implementation of the proposed solutions for Capital Calc lead to several positive outcomes for the mobile app and its users.<br /><br />Firstly, the integration of multiple types of calculators into a single platform enhanced the user experience, allowing users to conveniently perform various financial calculations in one place.<br /><br />Secondly, the implementation of advanced formulas ensured that investment estimations were accurate and reliable, empowering users to make informed decisions and mitigate financial risks.<br /><br />Thirdly, the user-friendly interface improved the app's usability, making it easier for users to input financial data and obtain precise investment estimations.<br /><br />Fourthly, real-time updates provided valuable insights into investment performance, enabling users to monitor progress and make informed investment decisions.<br /><br />Overall, these solutions resulted in a more comprehensive and efficient financial calculation tool for Capital Calc, enhancing user satisfaction, increasing app usage, and solidifying its competitive position in the market.",
    sliderImg: [
      CapitalCalsSliderImg1,
      CapitalCalsSliderImg2,
      CapitalCalsSliderImg3,
      CapitalCalsSliderImg4
    ],
    path: 'capital-calc',
    appLink: 'https://play.google.com/store/apps/details?id=com.pf.capitalcalc&hl=en_IN&gl=US'
  },
  {
    subTitle: 'Android application',
    title: 'Application to Analyze Product Ingredients and Offer Health Benefits',
    content:
      'Say goodbye to uncertainty! Our app allows you to simply scan product ingredient images, instantly providing you with a comprehensive analysis of the components.',
    flag: <IndiaFlag />,
    region: 'India',
    duration: '8 months',
    platforms: 'Android',
    technology: 'Flutter, Figma',
    projectImgUrl: ProminentWorksImg7,
    projectLogoUrl: IngridoOpionIcon,
    projectBrandUrl: IngridoOpion,
    bgType: 'blue',
    slideType: 'mobile',
    heroTitle: 'Ingrido Opinion - ',
    heroSubTitle: 'Allows you to simply scan product ingredients',
    industry: 'Consumer Goods And Technology',
    deliverables: 'App Development',
    projectImg: IngridoOpionImg,
    category: 'App Development',
    keywords:
      'Ingredient analyzer,Health benefits app,Product ingredient checker,Food ingredient scanner,Nutrition analyzer,Healthy living app,Ingredient info app,Health and ellness,Product health analysis,Ingredient lookup,Food ingredient analysis,Cosmetic ingredient checker,Nutritional information,Dietary supplements analysis,Health impact analysis,Ingredient safety checker,Allergen detection,Ingredient label scanner,Organic product checker,Additive and preservative checker,Healthy ifestyle,Wellness tracker,Nutritional health,Diet planning,Fitness and nutrition,Healthy eating,Wellness insights,Holistic health,Personalized health tips,Health onitoring,Analyze product ingredients,Check health benefits,Identify harmful ingredients,Improve health with food,Healthier choices,Discover product benefits,Ingredient safety tips,Understand product labels,Health-conscious shopping,Make informed choices,Best ingredient checker,Top health app,Popular nutrition app',
    client: 'Kim James',
    location: 'India',
    contentTitle: 'Ingrido Opinion: Analyze product ingredients it’s offer you health',
    description:
      'Introducing Ingrido Opinion, your ultimate companion for conscious shopping. This innovative app features an Ingredient Scanner for instant product analysis, ensuring transparency about what you consume. Receive personalized recommendations aligned with your values, from vegan to cruelty-free options. Utilize sustainability ratings to support eco-friendly practices with every purchase. Stay informed with the latest insights on conscious consumerism through articles and guides. Download Ingrido Opinion now to embark on your journey towards a more informed and mindful lifestyle. Shape a better future through informed choices today! 🌍📲',
    challenges:
      'Ingrido Opinion encountered several key challenges. These included ensuring the accuracy of its Ingredient Scanner across diverse product types and brands, developing complex algorithms to interpret scanned images effectively, and fine-tuning recommendation systems to deliver personalized suggestions aligned with varied user preferences and ethical standards. Establishing reliable sustainability metrics that resonate globally was also pivotal, alongside designing a user interface that balances simplicity with the presentation of detailed information. Overcoming these hurdles was essential to creating a robust app that empowers users to make informed, conscious shopping choices seamlessly.',
    solution:
      'Advanced Ingredient Scanner: Implemented advanced machine learning and image recognition technologies to enhance accuracy and reliability of product ingredient analysis.<br /><br />Intuitive User Interface: Designed an intuitive interface that simplifies complex information while ensuring ease of use and functionality through iterative user testing and feedback.<br /><br />Continuous Improvement: Commitment to ongoing updates and enhancements based on user feedback, technological advancements, and evolving sustainability practices to maintain relevance and effectiveness.',
    result:
      'Increased User Satisfaction: Personalization algorithms boosted user satisfaction by delivering tailored recommendations.<br /><br />Improved Sustainability Awareness: Comprehensive sustainability framework raised awareness and guided eco-friendly choices.<br /><br />Positive User Experience: Intuitive interface facilitated seamless shopping, enhancing engagement.<br /><br />Adaptability and Relevance: Continuous updates based on feedback ensured app remained adaptive and relevant.',
    sliderImg: [
      IngridoOptionSliderImg1,
      IngridoOptionSliderImg2,
      IngridoOptionSliderImg3,
      IngridoOptionSliderImg4,
      IngridoOptionSliderImg5,
      IngridoOptionSliderImg6
    ],
    path: 'ingrido-opion',
    appLink: 'https://play.google.com/store/apps/details?id=com.pf.ingrido.opinion&hl=en_IN&gl=US'
  },
  {
    subTitle: 'Social Networking & Logistics website',
    title: 'Connecting Travelers, Sharing Journeys',
    content:
      'Innovative solution solves the challenge of solo journeys and shipping needs by connecting users with like-minded travelers, ensuring safe and convenient experiences.',
    flag: <IndiaFlag />,
    region: 'India',
    duration: '6 months',
    platforms: 'Web Application',
    technology: 'React, Node.js, Figma, Express, MongoDB',
    projectImgUrl: ProminentWorksImg10,
    projectLogoUrl: AirWeIcon,
    projectBrandUrl: AirWe,
    bgType: 'midnight-slate',
    slideType: 'web',
    heroTitle: 'AirWe - ',
    heroSubTitle: 'Solo journeys and shipping needs by connecting users',
    industry: 'Social Networking & Logistics',
    deliverables: 'An Optimized Web App Platform',
    projectImg: AirWeImg,
    category: 'Web Development',
    keywords:
      'Solo travel buddy,Travel companionship,Peer-to-peer shipping,Safe solo journeys,Shared travel experiences,Community travel app,Group travel solutions,ollaborative shipping,Travel safety network,Solo travel support',
    client: 'Guillem Singla, Dimitrios Athanasiou',
    location: 'India',
    contentTitle: 'AirWe: Connecting Travelers, Sharing Journeys',
    description:
      'AirWE is a secure and hassle-free platform that solves the problem of finding travel companions and parcel delivery options through fellow travelers. It enables users to find companions and send/receive parcels while allowing service providers to earn extra cash, all on one convenient platform.',
    challenges:
      'Capital Calc is a mobile app that offers various types of calculators. The primary challenge faced by the app was to integrate multiple types of calculators and implement formulas that can accurately calculate investment results for up to a hundred years.<br /><br /> This would enable users to obtain future investment estimations and understand how their investment will perform over time.Challenges encountered during the development of AirWE included establishing trust and security measures for both travel companionship and parcel delivery, implementing effective logistics management without centralized infrastructure for parcel pickup and drop-off, fostering user adoption and engagement amidst competitive market dynamics, navigating regulatory complexities across travel, parcel delivery, and financial transactions, and seamlessly integrating technology features like real-time tracking and payment gateways across diverse devices and networks. Overcoming these hurdles was essential to creating AirWE as a secure, user-friendly platform that facilitates connections between travelers and service providers for efficient travel companionship and parcel delivery solutions.',
    solution:
      'User Engagement Strategies: Deployed targeted marketing campaigns and incentives to attract and retain users, leveraging feedback loops and user analytics to continuously improve the user experience and foster community engagement on the platform.<br /><br />Logistics Optimization: Developed a scalable logistics framework that leverages user-generated routes and schedules, integrating real-time tracking and communication features to enhance reliability and efficiency in parcel delivery without centralized infrastructure.<br /><br />Trust and Security Protocols: Implemented robust verification processes for users and service providers, including identity verification and user reviews, to build trust and ensure secure transactions for both travel companionship and parcel delivery.<br /><br />Technology Integration and Scalability: Integrated robust technology solutions for real-time tracking, seamless communication, and secure payment gateways, optimizing compatibility across various devices and networks while preparing for scalable growth and future enhancements.',
    result:
      'AirWE successfully overcame development challenges to become a trusted platform for travel companionship and parcel delivery. Robust security measures and efficient logistics management have ensured reliable service, while effective user engagement strategies and seamless technology integration have enhanced user satisfaction and platform credibility.',
    sliderImg: [
      AirWeSliderImg1,
      AirWeSliderImg2,
      AirWeSliderImg3,
      AirWeSliderImg4,
      AirWeSliderImg5,
      AirWeSliderImg6
    ],
    path: 'airwe'
  },
  {
    subTitle: 'Kids learning app',
    title: 'Learn & Play: The Ultimate Mobile Application',
    content:
      'Explore the fascinating world of birds, animals, vehicles, vegetables, alphabets, human body parts, digits, and more with our engaging and educational app!',
    flag: <IndiaFlag />,
    region: 'India',
    duration: '3 months',
    platforms: 'Android',
    technology: 'Flutter, Figma, MongoDB',
    projectImgUrl: ProminentWorksImg13,
    projectLogoUrl: KidsAcademyIcon,
    projectBrandUrl: KidsAcademy,
    bgType: 'blue',
    slideType: 'mobile',
    heroTitle: 'Kids academy - ',
    heroSubTitle: 'helps your children to learn',
    industry: 'Software Development',
    deliverables: 'App Development',
    projectImg: KidsAcademyImg,
    category: 'Web Development',
    keywords:
      'Educational app,Learning app,Kids learning tool,Explore animals,Discover birds,Alphabet learning,Human body parts,Educational games,Interactive learning,Learn digits',
    client: 'Kim James',
    location: 'India',
    contentTitle: 'Kids Academy: "Learn & Play," ',
    description:
      'Unlock the Power of Personalized Cover Letters with Assistree. Look no further! Assistree, your ultimate productivity tool, is here to streamline your job application process like never before. With Assistree, you can effortlessly generate personalized cover letters in a matter of seconds. Thanks to our cutting-edge AI technology, your cover letters will stand out from the crowd, increasing your chances of getting hired faster than ever.',
    challenges:
      'This web application specializes in selling customized clothing products. The primary challenge faced by the application is to provide a user-friendly customization module that enables customers to customize their products online.<br /><br /> Once the order is placed, the application must automatically send the order to the production unit for customization and manage the entire order process to ensure that customer orders are produced according to their requirements, delivered on time, and shipped directly from the warehouse.',
    solution:
      'User-Friendly Customization Module: The web application can provide a user-friendly customization module that enables customers to customize their products online. This module should be easy to navigate, have clear instructions, and allow customers to see a preview of their customized product.<br /><br /> Automated Order Management: The web application can implement an automated order management system that tracks orders from the time they are placed to the time they are delivered. This system should be able to send orders to the production unit automatically and provide real-time updates to customers about the status of their order.<br /><br /> Production Unit Integration: The web application can integrate with the production unit to ensure that the production process is streamlined and efficient. This integration can allow the application to track the progress of each order and ensure that the orders are produced and delivered on time.<br /><br /> Warehouse Optimization: The web application can optimize the warehouse operations to ensure that the right products are shipped at the right time. This involves using advanced inventory management techniques, such as just-in-time inventory and RFID tagging, to track the location and movement of products in real-time.<br /><br />  By adopting these solutions, the web application can streamline the customization, production, and order process, reduce errors, and enhance customer satisfaction by delivering the right products to the right place at the right time.',
    result:
      "The implementation of the proposed solutions can lead to several positive outcomes for the web application and its customers.<br /><br /> Firstly, the user-friendly customization module can enhance the customer experience, making it easier for them to customize their products online and place orders.<br /><br /> Secondly, the automated order management system can reduce errors, minimize delays, and provide real-time updates to customers, improving customer satisfaction.<br /><br /> Thirdly, the integration of the production unit can streamline the production process, reducing lead times and ensuring that orders are produced according to the customer's specifications.<br /><br /> Fourthly, optimizing the warehouse operations can ensure that products are delivered on time, reducing delivery times and improving customer satisfaction.<br /><br /> Overall, the implementation of these solutions can lead to a more streamlined and efficient customization, production, and order process for the web application, resulting in enhanced customer satisfaction, increased sales, and a stronger competitive position in the market.",
    sliderImg: [
      KidAcademySliderImg1,
      KidAcademySliderImg2,
      KidAcademySliderImg3,
      KidAcademySliderImg4,
      KidAcademySliderImg5,
      KidAcademySliderImg6
    ],
    path: 'kids-academy',
    appLink: 'https://play.google.com/store/apps/details?id=com.pf.kids.academy&hl=en_IN&gl=US'
  },
  {
    subTitle: 'Cosmic Trucking Adventure',
    title: 'Cars in the Air! Let’s Enjoy Every Level',
    content:
      'Embark on an exhilarating journey through the cosmos and city streets in our Space Truck Universe game!',
    flag: <IndiaFlag />,
    region: 'India',
    duration: '3 months',
    platforms: 'Android',
    technology: 'Unity, C#',
    projectImgUrl: ProminentWorksImg12,
    projectLogoUrl: SpaceTruckIcon,
    projectBrandUrl: SpaceTruck,
    bgType: 'midnight-slate',
    slideType: 'web',
    heroTitle: 'Space Truck - ',
    heroSubTitle: 'Gaming platform',
    industry: 'Gaming',
    deliverables: 'An Optimized Game Platform',
    projectImg: SpaceTruckImg,
    category: 'Game Development',
    keywords:
      'Space truck game,Cosmic adventure,Space exploration,City streets game,Sci-fi truck simulator,Galactic journey,Space trucker game,Intergalactic adventure,Cosmic truck driving,Sci-fi city game',
    client: 'Guillem Singla, Dimitrios Athanasiou',
    location: 'India',
    contentTitle: 'Space Truck Universe: "Interstellar and Urban Trucking Adventure,"',
    description:
      'Embark on an exhilarating journey through the cosmos and city streets in our Space Truck Universe game! Get ready to test your skills as you take control of powerful trucks, navigating the vastness of space and the challenges of urban roads.',
    keyFeatures: {
      CosmicAdventures:
        'Pilot your space truck through mesmerizing interstellar landscapes, avoiding celestial obstacles and delivering precious cargo across the universe.',
      DiverseTrucks:
        'Choose from a fleet of customizable trucks, each with unique abilities and upgrades to suit your driving style.',
      UpgradesAndCustomization:
        "Improve your truck's performance, appearance, and functionality by investing in upgrades and personalizing it to match your preferences.",
      StunningGraphics:
        "Immerse yourself in the game's breathtaking visuals, from the depths of space to the intricate details of cityscapes.",
      ChallengeFriends:
        'Compete with friends and players worldwide in exciting multiplayer modes, demonstrating your truck-driving prowess.',
      ThrillingSoundtrack:
        'Enjoy an engaging soundtrack that complements the adrenaline-pumping action.',
      RegularUpdates:
        'Stay engaged with ongoing content updates, introducing new challenges, vehicles, and missions to keep your space trucking experience fresh.'
    },
    challenges:
      'The main challenge in developing Space Truck Universe was to create a seamless experience that integrates the vastness of space with the complexity of urban environments. Ensuring smooth gameplay, realistic physics for both cosmic and urban trucking, and providing a variety of customization options were key hurdles. Additionally, the multiplayer feature required robust real-time synchronization and competitive balancing.',
    solution:
      "Seamless Gameplay Integration: The game was developed using Unity to allow for high-quality graphics and smooth transitions between space and urban environments. Blender was used for creating detailed 3D models.<br /><br />Realistic Physics: Advanced physics engines were implemented to handle both space and urban trucking dynamics, providing a realistic experience.<br /><br />Customization and Upgrades: A comprehensive upgrade system was developed, allowing players to enhance their trucks' performance, appearance, and functionality.<br /><br />Multiplayer Synchronization: Firebase was utilized to enable real-time updates and synchronization for the multiplayer mode, ensuring a smooth and competitive experience for players worldwide.<br /><br />Regular Content Updates: The game was designed with an update-friendly architecture, allowing developers to easily introduce new challenges, vehicles, and missions.",
    result:
      'Space Truck Universe has been well-received for its innovative blend of space and urban trucking, engaging gameplay, and stunning graphics. The seamless integration of different environments, along with diverse customization options, has resulted in a growing player base. Regular updates keep the game fresh and exciting, maintaining high player engagement and positive reviews.',
    sliderImg: [
      SpaceTruckSliderImg1,
      SpaceTruckSliderImg2,
      SpaceTruckSliderImg3,
      SpaceTruckSliderImg4,
      SpaceTruckSliderImg5,
      SpaceTruckSliderImg6,
      SpaceTruckSliderImg7
    ],
    path: 'space-truck',
    appLink:
      'https://play.google.com/store/apps/details?id=com.PixerFect.SpaceTruckUniverse&hl=en_IN&gl=US'
  },
  {
    subTitle: 'Interactive Quizzing App',
    title: 'Knowledgeable & Fun: A Quizzing Application',
    content:
      'Discover the captivating realm of birds, animals, sports, politics, fill in blank, cricket mania, history, and beyond through our interactive and enlightening quiz application!',
    flag: <IndiaFlag />,
    region: 'India',
    duration: '3 months',
    platforms: 'Web Application',
    technology: 'React, Node.js, Express, MongoDB',
    projectImgUrl: ProminentWorksImg14,
    projectLogoUrl: AtoZQuizIcon,
    projectBrandUrl: AtoZQuiz,
    bgType: 'blue',
    slideType: 'mobile',
    heroTitle: 'AtoZ Quiz - ',
    heroSubTitle: 'Engage, Learn, and Have Fun!',
    industry: 'Education & Entertainment',
    deliverables: 'An Optimized Web Platform',
    projectImg: AtoZquizImg,
    category: 'Web Development',
    keywords:
      'quiz, online quiz, quick quiz,Quiz app,Trivia game,Interactive quiz,Educational quiz,Quiz challenge,Learn through quizzes,Quiz competition,Knowledge quiz,Trivia quiz,Quiz for trending',
    client: 'Kim James',
    location: 'India',
    contentTitle: 'Knowledgeable & Fun: "Interactive Quizzing Application,"',
    description:
      'Discover the captivating realm of birds, animals, sports, politics, fill in blank, cricket mania, history, and beyond through our interactive and enlightening quiz application!',
    keyFeatures: {
      DiverseTopics:
        'Explore quizzes on a wide range of topics, from nature and sports to politics and history, ensuring there is something for everyone.',
      InteractiveQuestions:
        'Engage with interactive questions that keep you on your toes and make learning fun and exciting.',
      RealTimeResults:
        'Get instant feedback on your answers, helping you learn and improve your knowledge as you play.',
      Leaderboards:
        'Compete with friends and players around the world, climbing the leaderboards and showcasing your quizzing prowess.',
      CustomizableQuizzes:
        'Create your own quizzes and challenge your friends, making the quizzing experience even more personalized and enjoyable.',
      StunningGraphics: 'Enjoy a visually appealing interface that makes quizzing a delight.',
      RegularUpdates:
        'Stay engaged with new quizzes and topics added regularly, ensuring fresh content for continuous learning.'
    },
    challenges:
      'The main challenge in developing the Knowledgeable & Fun quizzing application was to create an engaging and user-friendly interface that caters to a wide range of topics and provides instant feedback. Ensuring real-time performance and maintaining a vast database of questions were also significant challenges.',
    solution:
      'Engaging Interface: Developed using React to create a dynamic and responsive user interface that keeps users engaged.<br /><br /> Real-Time Performance: Leveraged Node.js and WebSockets to ensure real-time feedback and smooth performance.<br /><br /> Extensive Database: Utilized MongoDB for a scalable and flexible database solution that can handle a vast collection of quiz questions across diverse topics.<br /><br /> Customization and Personalization: Implemented features for creating custom quizzes and tracking user performance, making the application more personalized and engaging.<br /><br /> Regular Content Updates: Designed the application to allow easy addition of new quizzes and topics, ensuring fresh content is always available.',
    result:
      'Knowledgeable & Fun has been praised for its engaging and user-friendly design, diverse range of topics, and interactive features. The application has seen high user engagement and positive feedback for its real-time performance and regular content updates. It continues to attract a growing user base, providing an enjoyable and educational experience for quiz enthusiasts.',
    sliderImg: [
      AtoZQuizSliderImg1,
      AtoZQuizSliderImg2,
      AtoZQuizSliderImg3,
      AtoZQuizSliderImg4,
      AtoZQuizSliderImg5,
      AtoZQuizSliderImg6,
      AtoZQuizSliderImg7,
      AtoZQuizSliderImg8,
      AtoZQuizSliderImg9,
      AtoZQuizSliderImg10,
      AtoZQuizSliderImg11,
      AtoZQuizSliderImg12,
      AtoZQuizSliderImg13,
      AtoZQuizSliderImg14
    ],
    path: 'atoz-quiz'
  },
  {
    subTitle: 'Managing website',
    title: 'Website That Assists Societies in Managing Their Shares',
    content:
      'MySociety can improve the scalability and reliability of its website, making it easier to manage society and member data and integrate new features and modules in the future.',
    flag: <IndiaFlag />,
    region: 'India',
    duration: '3 months',
    platforms: 'Web Application',
    technology: 'Figma, React js, Material UI, Wordpress',
    projectImgUrl: MySocietyProductImg,
    projectLogoUrl: MySocietyIcon,
    projectBrandUrl: MySociety,
    bgType: 'midnight-slate',
    slideType: 'web',
    heroTitle: 'My Society -',
    heroSubTitle: 'easier to manage society and member data',
    industry: 'Software Development',
    deliverables: 'Web Platform',
    projectImg: MySocietyProductInsideImg,
    category: 'Web Development',
    keywords:
      'Society management software,Member data management,Website scalability,Website reliability,Society management tools,Member data integration,Future-proof website,Website management system,Society website solutions,Scalable web platform',
    client: 'Guillem Singla, Dimitrios Athanasiou',
    location: 'United States of America',
    contentTitle: 'My society: managing societies ',
    description:
      'Neurofenix, journey began with a deep, personal connection to stroke rehabilitation. Neurofenix’s co-founders, Guillem Singla and Dimitrios Athanasiou, inspired by their own family experiences, envisioned a world where stroke recovery was not just effective but accessible to everyone. Blending their expertise in Biomedical Engineering, Neurotechnology, and Biomechanics to innovate in stroke care. Where elder-age people receive treatment for a lack of consistent, intensive care that hinders the recovery process.',
    challenges:
      'A health care product company had been selling their products through traditional brick-and-mortar stores but wanted to expand their reach by setting up an e-commerce platform.<br /><br /> They needed a solution that would enable easy online purchasing and provide a seamless user experience for customers.',
    solution:
      'We developed an e-commerce platform that was user-friendly and provided customers with a hassle-free shopping experience.<br /><br /> The platform was optimized for search engines, making it easier for customers to find the products they were looking for. We also implemented various security measures to ensure that customer data was protected and the online shopping experience was secure.',
    result:
      'The e-commerce platform proved to be successful, and the health care product company saw a significant increase in sales. Customers appreciated the convenience of shopping online and the ease of finding the products they needed.<br /><br /> The client was pleased with the results and continues to use the e-commerce platform to sell their health care products online.',
    sliderImg: [MySocietySlider1, MySocietySlider2, MySocietySlider3],
    path: 'my-society'
  },
  {
    subTitle: 'finance Website',
    title: 'Comprehensive Calculator Platform',
    content:
      'A versatile platform offering a wide range of calculators across various categories including health, finance, math, and more. Developed using ReactJS and WordPress, it provides detailed blog posts explaining calculation steps and equations.',
    flag: <IndiaFlag />,
    region: 'India',
    duration: '3 month',
    platforms: 'Web Application',
    technology: 'ReactJs, Material-UI, WordPress',
    projectImgUrl: CalcTimesProduct,
    projectLogoUrl: CalcTimesLogo,
    projectBrandUrl: CalcTimesBrand,
    bgType: 'blue',
    slideType: 'web',
    heroTitle: 'Calculator Times - ',
    heroSubTitle: 'Comprehensive Calculator Platform',
    industry: 'Software Development',
    deliverables: 'Website Development, Calculator Tools',
    projectImg: CalcTimesProductInside,
    category: 'Webflow Development',
    keywords:
      'Calculator tools,Online calculators,Financial calculators,Health calculators,Math calculators,Scientific calculators,Retirement calculators,Mortgage calculators,Loan calculators,BMI calculators,Tax calculators,Investment calculators,Currency converters,Unit converters,Time zone converters',
    client: 'Mr.Sandip',
    location: 'India',
    contentTitle: 'Calculator Times - Comprehensive Calculator Platform',
    description:
      'CalculatorTimes.com is an all-encompassing calculator platform designed to cater to diverse calculation needs. Whether you need to manage your finances, track your health metrics, solve mathematical problems, or handle various other calculations, this platform has you covered.<br /><br />The website is built with ReactJS for a dynamic, responsive user experience and WordPress to manage content efficiently. Each calculator is meticulously detailed in accompanying blog posts, which outline the steps, underlying equations, and rationale behind the calculations, making it easy for users to understand and utilize the tools effectively.',
    challenges:
      'Integrating complex calculator functionalities within a ReactJS and WordPress environment posed initial technical challenges.<br /><br />Creating detailed and informative blog posts for each calculator while maintaining user readability and engagement was a significant content challenge.<br /><br />Ensuring optimal SEO practices and visibility in a competitive online environment required continuous monitoring and adaptation.',
    solution:
      'Utilized ReactJS for frontend interactivity and responsiveness, leveraging WordPress for content management and blog integration. Custom plugins and API integrations were employed for seamless calculator functionalities.<br /><br />Developed a structured approach to content creation, focusing on clear explanations of calculation steps, real-life applications, and interactive elements such as charts and diagrams to enhance user understanding.<br /><br />Implemented a robust SEO strategy including keyword research, on-page optimization, and regular content updates. Integrated social sharing functionalities and collaborated with influencer and niche communities to expand reach. ',
    result:
      'Improved user engagement through intuitive design and comprehensive calculator functionalities, resulting in increased time spent on the website and lower bounce rates.<br /><br />Established CalculatorTimes.com as a go-to resource for accurate and detailed calculators, driving organic traffic and establishing credibility in niche markets.<br /><br />Achieved significant improvements in search engine rankings for targeted keywords and increased organic traffic, leading to higher conversion rates and broader user reach.<br /><br />Fostered a community of users through interactive features, social media integration, and regular updates, enhancing brand loyalty and driving repeat visits.',
    sliderImg: [CalcTimesSlider1, CalcTimesSlider2, CalcTimesSlider3],
    path: 'calculator-times'
  },
  {
    subTitle: 'Weather Forecast Web',
    title: 'Global Climate Insights by Mausam',
    content:
      'Your comprehensive global weather forecasting solution. Providing precise, timely predictions for every country, empowering informed decisions worldwide.',
    flag: <IndiaFlag />,
    region: 'India',
    duration: '8 month',
    platforms: 'Web Application',
    technology: 'ReactJs, WordPress, OpenWeatherAPI',
    projectImgUrl: MausamProduct,
    projectLogoUrl: MausamLogo,
    projectBrandUrl: MausamBrand,
    bgType: 'midnight-slate',
    slideType: 'web',
    heroTitle: 'Mausam - ',
    heroSubTitle: 'Global Weather Insights',
    industry: 'Weather Forecasting and Meteorology',
    deliverables: 'Web Platform',
    projectImg: MausamProductInside,
    category: 'Web Development, Data Analytics, Content Creation',
    keywords:
      'Weather forecast,Local weather,Global weather,Weather updates,Weather predictions,Weather radar,Climate data,Weather maps,Meteorological services,Daily weather report,Weekly weather forecast,Historical weather data,Weather trends,Weather alerts,Storm tracking',
    client: 'Mr.Sandip',
    location: 'India',
    contentTitle: 'Mausam: your ultimate source for detailed weather',
    description:
      'Welcome to Mausam, your premier source for comprehensive and reliable weather forecasts. Whether you need daily, hourly, weekly, or monthly updates, Mausam has you covered with accurate weather data for every corner of the globe. Explore our detailed past and future weather insights to plan your days with confidence. Stay ahead of the weather curve with our latest news, web stories, and insightful blogs on all things weather-related. Mausam isn’t just about forecasts; it’s about bringing you the world’s weather in a way that is engaging, informative, and easy to understand. Discover a seamless weather experience with Mausam, where the forecast is always at your fingertips.',
    challenges:
      'Delivering precise weather forecasts for a global audience.<br /><br />Making weather data engaging and easy to understand for users of all backgrounds. <br /><br />Offering up-to-date news, web stories, and blogs on the latest weather events and trends.',
    solution:
      'Utilizing cutting-edge meteorological tools and data analytics to ensure the highest accuracy in weather predictions.<br /><br />Designing an intuitive website layout that allows users to easily access and interpret weather information. <br /><br />Regularly updating our platform with fresh and relevant content, including web stories and blogs, to keep users informed and engaged. ',
    result:
      '<b>High User Satisfaction:</b> Achieved through consistent, accurate weather forecasts and a user-friendly interface.<br /><br /><b>Increased Engagement:</b> Enhanced user interaction and return visits due to diverse and compelling content.<br /><br /><b>Authority and Trust:</b> Established Mausam as a trusted and authoritative source for weather information and updates.',
    sliderImg: [MausamSlider1, MausamSlider2, MausamSlider3, MausamSlider4, MausamSlider5],
    path: 'mausam'
  }
];
export default ProminentWorksList;
