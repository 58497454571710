import React from 'react';

const GameDevelopmentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      x="0"
      y="0"
      viewBox="0 0 24 24"
      xmlSpace="preserve">
      <g>
        <path
          d="M22.355 5.378A4.474 4.474 0 0 0 18.385 3H5.615a4.475 4.475 0 0 0-3.97 2.377A14.19 14.19 0 0 0 0 11.978C0 17.504 2.019 22 4.5 22c1.628 0 2.603-2.146 3.133-3.946A1.493 1.493 0 0 1 9.065 17h5.87c.661 0 1.25.434 1.432 1.054.53 1.8 1.504 3.946 3.133 3.946 2.481 0 4.5-4.496 4.5-10.022 0-2.886-.895-5.183-1.645-6.6ZM19.5 21c-.766 0-1.578-1.207-2.174-3.229A2.498 2.498 0 0 0 14.935 16h-5.87a2.498 2.498 0 0 0-2.391 1.771C6.078 19.793 5.266 21 4.5 21 2.848 21 1 17.142 1 11.978c0-2.681.832-4.814 1.529-6.133A3.478 3.478 0 0 1 5.615 4h12.77c1.301 0 2.484.707 3.086 1.845A13.185 13.185 0 0 1 23 11.978C23 17.142 21.152 21 19.5 21ZM9 9.5a.5.5 0 0 1-.5.5H7v1.5a.5.5 0 0 1-1 0V10H4.5a.5.5 0 0 1 0-1H6V7.5a.5.5 0 0 1 1 0V9h1.5a.5.5 0 0 1 .5.5ZM16 8a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm0 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-2 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
          fill="#000000"
          opacity="1"
          data-original="#000000"></path>
      </g>
    </svg>
  );
};

export default GameDevelopmentIcon;
