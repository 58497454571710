import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import PageNotFoundSec from '../../Components/PageNotFound/PageNotFoundSec';
import { SEO } from '../../Utils/Constant';

const PageNotFound = () => {
  return (
    <>
      <Helmet>
        <title>{SEO.NOT_FOUND_PAGE_TITLE}</title>
      </Helmet>
      <Header />
      <PageNotFoundSec />
      <Footer />
    </>
  );
};

export default PageNotFound;
