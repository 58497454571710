import React from 'react';

const DigitalMarketingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      x="0"
      y="0"
      viewBox="0 0 24 24"
      xmlSpace="preserve">
      <g>
        <path
          d="M18 16.395c0 .685.49 1.263 1.166 1.375l2.833.473A2.386 2.386 0 0 1 24 20.605 2.397 2.397 0 0 1 21.605 23H21v.5a.5.5 0 0 1-1 0V23h-.5a2.503 2.503 0 0 1-2.5-2.5.5.5 0 0 1 1 0c0 .827.673 1.5 1.5 1.5h2.105c.769 0 1.395-.626 1.395-1.395 0-.685-.49-1.263-1.166-1.375l-2.833-.473A2.386 2.386 0 0 1 17 16.395 2.397 2.397 0 0 1 19.395 14H20v-.5a.5.5 0 0 1 1 0v.5h.5c1.378 0 2.5 1.122 2.5 2.5a.5.5 0 0 1-1 0c0-.827-.673-1.5-1.5-1.5h-2.105c-.769 0-1.395.626-1.395 1.395ZM11.488 11A2.515 2.515 0 0 1 14 13.512V16.5c0 1.378-1.122 2.5-2.5 2.5H10c-.551 0-1-.449-1-1v-6c0-.551.449-1 1-1h1.488Zm0 1H10v6h1.5c.827 0 1.5-.673 1.5-1.5v-2.988c0-.834-.678-1.512-1.512-1.512ZM7.99 18.415a.489.489 0 0 1-.485.585.492.492 0 0 1-.48-.39L6.692 17H4.316l-.323 1.589a.492.492 0 0 1-.485.392H3.49a.49.49 0 0 1-.48-.585l1.279-6.308C4.37 11.689 4.731 11 5.496 11s1.121.659 1.214 1.119l1.28 6.296ZM6.485 16l-.763-3.683c-.038-.185-.078-.316-.227-.316s-.195.166-.219.284L4.519 16h1.965ZM24 4.5v7a.5.5 0 0 1-1 0V8H1v9.5C1 19.43 2.57 21 4.5 21h10a.5.5 0 0 1 0 1h-10A4.505 4.505 0 0 1 0 17.5v-13C0 2.019 2.019 0 4.5 0h15C21.981 0 24 2.019 24 4.5ZM23 7V4.5C23 2.57 21.43 1 19.5 1h-15C2.57 1 1 2.57 1 4.5V7h22ZM4 3a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm3 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm3 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
          fill="#000000"
          opacity="1"
          data-original="#000000"></path>
      </g>
    </svg>
  );
};

export default DigitalMarketingIcon;
