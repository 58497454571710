import React from 'react';
import { CONSTANT } from '../../Utils/Constant';
import TechTeam from '../../Assets/Images/pixerfect-tech-team.png';
import './TechPartner.scss';

const TechPartner = () => {
  return (
    <div className="tech-partner">
      <h3>Meet Our Pixel Perfectos</h3>
      <div className="tech-partner-container">
        <div className="background-gradient"></div>
        <div className="tech">
          <div className="container">
            <div className="left-img">
              <img
                src={TechTeam}
                alt={'Tech Team | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                title={'Tech Team | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
              />
            </div>
            <div className="right-part">
              <span data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                Tech Team
              </span>
              <p data-aos="fade-up" data-aos-duration="1200" data-aos-once="true">
                Comprising seasoned professionals with diverse expertise, Our leaders are committed
                to driving growth, fostering a culture of collaboration, and guiding our talented
                teams to new heights.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechPartner;
