import React from 'react';
import './CareerContact.scss';
import CareerContactCommon from './CareerContactCommon';
import { CONSTANT } from '../../Utils/Constant';

const CareerContact = () => {
  return (
    <div className="fullCareerContact">
      <div className="background-gradient"></div>
      <div className="background-gary"></div>
      <div className="careerContent">
        <div className="container">
          <CareerContactCommon
            className="p-right"
            title="Career With Us"
            content="Looking for a company where you can grow professionally and make a real impact?
            Pixerfect is the place for you."
            alt={'Career with us | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
            redirectPath="/career"
          />
          <CareerContactCommon
            className="p-left"
            title="Contact Us"
            content="Whether you're seeking bespoke software solutions, innovative development services,
            or cutting-edge SaaS offering, our team is here to help."
            alt={'Contact us | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
            redirectPath="/contact-us"
          />
        </div>
      </div>
    </div>
  );
};

export default CareerContact;
