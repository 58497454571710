import React from 'react';
import { useNavigate } from 'react-router';
// import RightTopArrow from '../../Assets/Images/right-top-arrow.png';
import RightArrow from '../../Assets/Images/right-arrow.png';

const CareerContactCommon = ({ title, content, alt, className, redirectPath }) => {
  const navigate = useNavigate();
  return (
    <div className={`career-contact ${className}`}>
      <div className="career-contact-container">
        <button
          type="button"
          onClick={() => {
            navigate(redirectPath);
            window.scrollTo(0, 0);
          }}>
          <span>{title}</span>
          <img src={RightArrow} alt={alt} title={alt} />
        </button>
        <p data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
          {content}
        </p>
      </div>
    </div>
  );
};

export default CareerContactCommon;
