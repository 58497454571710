import React from 'react';
import { CONSTANT } from '../../Utils/Constant';
import './HowItWorks.scss';

const HowItWorks = ({ servicesData }) => {
  return (
    <div className="container">
      <div className="how-it-works-wrapper">
        <div className="how-it-works-content">
          <h3 data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
            How it works
          </h3>
          <p data-aos="fade-up" data-aos-duration="1100" data-aos-once="true">
            {servicesData.howItWorksContent}
          </p>
        </div>
        <div className="center-content">
          <div className="how-it-works-title">
            <h4 data-aos="fade-up" data-aos-duration="1200" data-aos-once="true">
              {servicesData.howItWorksSubTitle}
            </h4>
          </div>
          <div className="how-it-works-step">
            <ul>
              {servicesData.howItWorksStep.map((item, index) => (
                <li
                  key={index}
                  data-aos="fade-up"
                  data-aos-duration={'1' + (index + 2) + '00'}
                  data-aos-once="true">
                  <div className="step-icon">
                    <img src={item.icon} alt={item.name} title={item.name} />
                  </div>
                  <span>{item.name}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="how-it-works-img">
            <div className="img-wrapper">
              <img
                src={servicesData.howItWorksImg}
                alt={servicesData.title + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                title={servicesData.title + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
              />
            </div>
          </div>
        </div>
        <div className="service-content-text">
          <p data-aos="fade-up" data-aos-duration="2000" data-aos-once="true">
            {servicesData.serviceContent}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
