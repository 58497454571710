import React from 'react';

const TeamIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      x="0"
      y="0"
      viewBox="0 0 24 24"
      xmlSpace="preserve">
      <g>
        <path
          d="M5 6c1.654 0 3-1.346 3-3S6.654 0 5 0 2 1.346 2 3s1.346 3 3 3Zm0-5c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2Zm8 5c1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3 1.346 3 3 3Zm0-5c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2Zm-2.101 10.301a.498.498 0 0 1-.7.099A2.002 2.002 0 0 0 7 13.001c0 .948.673 1.772 1.6 1.96a.501.501 0 0 1-.199.98 3.008 3.008 0 0 1-2.4-2.94c0-1.654 1.346-3 3-3 .656 0 1.278.208 1.801.601a.5.5 0 0 1 .099.7ZM17.5 11c-3.584 0-6.5 2.916-6.5 6.5s2.916 6.5 6.5 6.5 6.5-2.916 6.5-6.5-2.916-6.5-6.5-6.5Zm0 12c-3.033 0-5.5-2.468-5.5-5.5s2.467-5.5 5.5-5.5 5.5 2.468 5.5 5.5-2.467 5.5-5.5 5.5Zm3.35-6.857a.5.5 0 0 1-.012.707l-2.703 2.614c-.36.355-.835.533-1.311.533s-.949-.177-1.312-.531l-1.364-1.347a.5.5 0 1 1 .702-.711l1.363 1.345a.88.88 0 0 0 1.223-.004l2.706-2.618a.5.5 0 0 1 .707.012ZM5 9c-2.206 0-4 1.794-4 4v.5a.5.5 0 0 1-1 0V13a5.006 5.006 0 0 1 6.667-4.716.501.501 0 0 1-.334.944A3.981 3.981 0 0 0 5 9Zm10.916.536a.5.5 0 0 1-.694.137 4.052 4.052 0 0 0-3.555-.445.5.5 0 1 1-.334-.944 5.056 5.056 0 0 1 4.445.559.5.5 0 0 1 .137.693Zm-6.918 8.937a.5.5 0 0 1-.442.552 3.994 3.994 0 0 0-3.555 3.976v.5a.5.5 0 0 1-1 0v-.5a4.993 4.993 0 0 1 4.445-4.97.497.497 0 0 1 .552.442Z"
          fill="#000000"
          opacity="1"
          data-original="#000000"></path>
      </g>
    </svg>
  );
};

export default TeamIcon;
