import React from 'react';
import './Counter.scss';
import CountUp from 'react-countup';

const Counter = () => {
  return (
    <div className="counter">
      <div className="container">
        <div className="counter-container">
          <div className="count">
            <CountUp
              start={0}
              end={8}
              duration={5}
              enableScrollSpy={true}
              scrollSpyDelay={500}
              scrollSpyOnce={true}
            />
            <span>+</span>
          </div>
          <p>Awards Won</p>
        </div>
        <div className="counter-container">
          <div className="count">
            <CountUp
              start={0}
              end={12}
              duration={5}
              enableScrollSpy={true}
              scrollSpyDelay={500}
              scrollSpyOnce={true}
            />
            <span>+</span>
          </div>
          <p>Years Of Experience</p>
        </div>
        <div className="counter-container">
          <div className="count">
            <CountUp
              start={0}
              end={225}
              duration={5}
              enableScrollSpy={true}
              scrollSpyDelay={500}
              scrollSpyOnce={true}
            />
            <span>+</span>
          </div>
          <p>Happy Clients</p>
        </div>
      </div>
    </div>
  );
};

export default Counter;
