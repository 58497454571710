import React from 'react';
import { useNavigate } from 'react-router-dom';
import './OurServices.scss';

const OurServices = () => {
  const navigate = useNavigate();
  return (
    <div className="our-services">
      <h4 data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
        Our Services
      </h4>
      <p data-aos="fade-up" data-aos-duration="1100" data-aos-once="true">
        We pride ourselves on delivering professional solutions tailored to meet your unique needs.
      </p>
      <div className="get-started" data-aos="fade-up" data-aos-duration="1200" data-aos-once="true">
        <button
          type="button"
          onClick={() => {
            navigate('/services');
            window.scrollTo(0, 0);
          }}
          className="primary-btn">
          <span>Get Started</span>
        </button>
      </div>
    </div>
  );
};

export default OurServices;
