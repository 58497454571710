import React from 'react';
import { CONSTANT } from '../../Utils/Constant';
import './TrustedPartner.scss';

const TrustedPartner = ({ ourPartner }) => {
  return (
    <div className="container">
      <div className="trusted-partner-wrap">
        <h3>Technologies we works</h3>
        {ourPartner.length > 6 ? (
          <>
            <ul>
              {ourPartner.slice(0, Math.ceil(ourPartner.length / 2)).map((item, index) => (
                <li key={index}>
                  <img
                    src={item.icon}
                    alt={item.name + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                    title={item.name + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                  />
                </li>
              ))}
            </ul>
            <ul>
              {ourPartner.slice(Math.ceil(ourPartner.length / 2)).map((item, index) => (
                <li key={index}>
                  <img
                    src={item.icon}
                    alt={item.name + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                    title={item.name + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                  />
                </li>
              ))}
            </ul>
          </>
        ) : (
          <ul>
            {ourPartner.map((item, index) => (
              <li key={index}>
                <img
                  src={item.icon}
                  alt={item.name + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                  title={item.name + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default TrustedPartner;
