import React from 'react';
import FooterList from '../../Utils/FooterList';
// import HeadQuarter from '../../Assets/Images/head-quarter.png';
// import { CONSTANT } from '../../Utils/Constant';

const OurLocation = ({ animate }) => {
  return (
    <div className="address">
      <div
        className="location-title"
        data-aos={animate ? 'fade-up' : null}
        data-aos-duration={animate ? '1100' : null}
        data-aos-once={animate ? 'true' : null}>
        <h6>Our Location</h6>
        {/* <img
          src={HeadQuarter}
          alt={'Address | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
          title={'Address | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
        /> */}
      </div>
      <ul>
        {FooterList && FooterList.addresses && FooterList.addresses.length > 0 ? (
          FooterList.addresses.map((item, i) => (
            <li
              key={i}
              data-aos={animate ? 'fade-up' : null}
              data-aos-duration={animate ? '1' + (i + 2) + '00' : null}
              data-aos-once={animate ? 'true' : null}>
              <h6>
                {item.title}
                <span>{item.flag}</span>
              </h6>
              <p>{item.address}</p>
            </li>
          ))
        ) : (
          <li>No Data Found.</li>
        )}
      </ul>
    </div>
  );
};

export default OurLocation;
