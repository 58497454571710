import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header/Header';
import Contact from '../../Components/Contact/Contact';
import CareerContact from '../../Components/CareerContact/CareerContact';
import Footer from '../../Components/Footer/Footer';
import HeroCaseStudy from '../../Components/CaseStudies/HeroCaseStudy';
import ProminentWorksList from '../../Utils/ProminentWorksList';
import { useNavigate, useParams } from 'react-router-dom';

const CaseStudies = () => {
  let { companyName } = useParams();
  const navigate = useNavigate();
  const [caseStudyPageData, setCaseStudyPageData] = useState([]);

  useEffect(() => {
    if (companyName && ProminentWorksList && ProminentWorksList.length > 0) {
      const filteredCaseStudyPageData = ProminentWorksList.filter(
        (item) => item.path === companyName
      );
      if (filteredCaseStudyPageData && filteredCaseStudyPageData.length > 0) {
        setCaseStudyPageData(filteredCaseStudyPageData);
      } else {
        navigate('*');
        setCaseStudyPageData([]);
      }
    } else {
      setCaseStudyPageData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName]);
  return (
    <div>
      <Header />
      {caseStudyPageData && caseStudyPageData.length > 0 && (
        <HeroCaseStudy caseStudyData={caseStudyPageData[0]} />
      )}
      <Contact />
      <CareerContact />
      <Footer />
    </div>
  );
};

export default CaseStudies;
