import React, { useCallback } from 'react';
import Particles from 'react-particles';
import { useNavigate } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter';
import { loadSlim } from 'tsparticles-slim';
import EarthImg from '../../Assets/Images/earth-background.png';
import HeroImg from '../../Assets/Images/pixerfect-background-img.png';
import { CONSTANT } from '../../Utils/Constant';
import './HeroSection.scss';

const HeroSection = () => {
  const navigate = useNavigate();
  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const particlesLoaded = useCallback();

  return (
    <>
      <div className="hero-section-content">
        <div className="container">
          <div className="hero-section-wrapper">
            <div className="hero-section-text">
              <h1>
                Pixel-Perfect Solutions for Your Digital{' '}
                <span>
                  <Typewriter
                    words={[
                      'Business',
                      'Software',
                      'Saas',
                      'Technology',
                      'Start Up',
                      'Dream',
                      'Vision',
                      'Ideas'
                    ]}
                    loop={false}
                    cursor
                    cursorStyle="|"
                    typeSpeed={120}
                    deleteSpeed={120}
                    delaySpeed={1000}
                  />
                </span>
              </h1>
              <p data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                Our specialists provide comprehensive IT outsourcing services to scale your IT
                products and achieve success.
              </p>
              <div
                className="get-in-touch"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-once="true">
                <button
                  type="button"
                  className="primary-btn"
                  onClick={() => {
                    navigate('/contact-us');
                    window.scrollTo(0, 0);
                  }}
                  title={`${CONSTANT.PIXERFECT_SOLUTIONS_LLP} - Contact Us`}>
                  <span>Get In Touch</span>
                </button>
              </div>
            </div>
            <div className="hero-section-img">
              <img
                className="desktop-hero-section-img"
                src={HeroImg}
                alt={`${CONSTANT.PIXERFECT_SOLUTIONS_LLP} | Digital`}
                title={`${CONSTANT.PIXERFECT_SOLUTIONS_LLP} | Digital`}
              />
              <img
                className="mobile-hero-section-img"
                src={EarthImg}
                alt={`${CONSTANT.PIXERFECT_SOLUTIONS_LLP} | Digital`}
                title={`${CONSTANT.PIXERFECT_SOLUTIONS_LLP} | Digital`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="hero-section">
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            fullScreen: {
              enable: false,
              zIndex: -1
            },
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: 'push'
                },
                onHover: {
                  enable: true,
                  mode: 'repulse'
                },
                resize: true
              },
              modes: {
                push: {
                  quantity: 4
                },
                repulse: {
                  distance: 200,
                  duration: 0.4
                }
              }
            },
            particles: {
              color: {
                value: '#DCFFFF'
              },
              links: {
                color: '#DCFFFF',
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1
              },
              move: {
                direction: 'none',
                enable: true,
                outModes: {
                  default: 'bounce'
                },
                random: false,
                speed: 6,
                straight: false
              },
              number: {
                density: {
                  enable: true,
                  area: 800
                },
                value: 80
              },
              opacity: {
                value: 0.5
              },
              shape: {
                type: 'circle'
              },
              size: {
                value: { min: 1, max: 5 }
              }
            },
            detectRetina: true
          }}
        />
      </div>
    </>
  );
};

export default HeroSection;
