import React, { useEffect, useRef, useState } from 'react';
import MobileStoreButton from 'react-mobile-store-button';
import { Link } from 'react-router-dom';
import ProminentWorksList from '../../Utils/ProminentWorksList';
import ProminentWorksDetails from '../ProminentWorks/ProminentWorksDetails';
import './ProjectsList.scss';

const ProjectsList = ({ dataList, setDataList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const lastBoxRef = useRef(null);

  useEffect(() => {
    setDataList(ProminentWorksList.slice(0, 4));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function handleScroll() {
      if (
        lastBoxRef.current &&
        lastBoxRef.current.getBoundingClientRect().bottom <= window.innerHeight
      ) {
        setIsLoading(true);
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        const newDataList = [
          ...dataList,
          ...ProminentWorksList.slice(dataList.length, dataList.length + 4)
        ];
        setDataList(newDataList);
        setIsLoading(false);
      }, 1000);
    }
  }, [dataList, isLoading, setDataList]);

  return (
    <div className="container">
      <div className="project-list">
        {dataList.map((item, index) => (
          <div
            className="project-case-study"
            key={index}
            ref={index === dataList.length - 1 ? lastBoxRef : null}>
            <Link
              to={`/case-study/${item.path}`}
              onClick={() => {
                window.scrollTo(0, 0);
              }}>
              <div
                className={`project-list-content ${item.bgType === 'blue' ? 'bg-blue' : item.bgType === 'midnight-slate' ? 'bg-midnight-slate' : ''}`}>
                <ProminentWorksDetails withBrand={true} index={index} item={item} />
              </div>
            </Link>
            <div
              className="appLink"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-once="true">
              {item.appLink && (
                <MobileStoreButton
                  store="android"
                  url={item.appLink}
                  linkProps={{ title: 'android Store Button' }}
                  height={56}
                />
              )}
            </div>
          </div>
        ))}
        {ProminentWorksList.length !== dataList.length && isLoading && (
          <div className="lazy-loader"></div>
        )}
      </div>
    </div>
  );
};

export default ProjectsList;
