import React from 'react';
import './AboutDetails.scss';
import HeroImg from '../../Assets/Gif/problems-solving.gif';
import { SEO } from '../../Utils/Constant';

const AboutDetails = () => {
  return (
    <div className="about-details">
      <div className="container">
        <div className="hero-img">
          <img src={HeroImg} alt="heroimg" title={SEO.ABOUT_US_PAGE_TITLE} />
        </div>
        <div className="content">
          <p data-aos="fade-up" data-aos-duration="1200" data-aos-once="true">
            <span>Pixerfect</span> is a digital solutions company servicing businesses across the
            globe who are looking to <span>Create Or Improve</span> their online functionality.
            <span> Our Services</span> exist to help clients build their public image and run more
            efficiently on the web. <span>We Achieve</span> this with a <span>Dedicated Team </span>
            of engineers who create personalized solutions for our clients in almost every industry
            under the sun. <span>Our Work</span> is passionate, innovative, smart and detailed, to
            bring you products and interfaces that realize <span>Your Best Potential.</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutDetails;
