import AOS from 'aos';
import React, { useEffect } from 'react';
import { CONSTANT } from '../../Utils/Constant';
import './ProminentWorksDetails.scss';

const ProminentWorksDetails = ({ item, index, withBrand }) => {
  useEffect(() => {
    const element = document.getElementById('prominent-work');
    if (element) {
      const handleScroll = () => {
        const rect = element.getBoundingClientRect();
        if (
          rect.top >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        ) {
          AOS.init({
            once: true,
            duration: 1000
          });
          window.removeEventListener('scroll', handleScroll);
        }
      };

      window.addEventListener('scroll', handleScroll);
      handleScroll();
    }
  }, []);

  return (
    <div className="prominent-works-details" id="prominent-work">
      <div className="prominent-works-details-wrap">
        <div className={`details-content ${item.appLink && 'content-padding'}`}>
          {withBrand ? (
            <div className="brand-details">
              <div className="ring-icon">
                <div className="img-icon">
                  <img
                    src={item.projectLogoUrl}
                    alt={`${item.subTitle} | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                    title={`${item.subTitle} | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                  />
                </div>
              </div>
              <div className="right-content">
                <div className="brand-img">
                  <img
                    src={item.projectBrandUrl}
                    alt={`${item.subTitle} | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                    title={`${item.subTitle} | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                  />
                </div>
                <p className="brand-title">{item.subTitle}</p>
              </div>
            </div>
          ) : (
            <h6
              className="project-sub-title"
              data-aos="fade-up"
              data-aos-duration={'1' + (index + 1) + '00'}
              data-aos-once="true">
              {item.subTitle}
            </h6>
          )}
          <h2 data-aos="fade-up" data-aos-duration={'1' + (index + 2) + '00'} data-aos-once="true">
            {item.title}
          </h2>
          <p data-aos="fade-up" data-aos-duration={'1' + (index + 3) + '00'} data-aos-once="true">
            {item.content}
          </p>
          <div className="country-blog">
            <div className="country-container">
              <div
                className="country"
                data-aos="fade-up"
                data-aos-duration="1400"
                data-aos-once="true">
                <p>Region</p>
                <span>
                  {item.flag} <span>{item.region}</span>
                </span>
              </div>
              <div
                className="platform"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-once="true">
                <p>Platforms</p>
                <span>{item.platforms}</span>
              </div>
            </div>
            <div className="duration-container">
              <div
                className="duration"
                data-aos="fade-up"
                data-aos-duration="1400"
                data-aos-once="true">
                <p>Duration</p>
                <span>{item.duration}</span>
              </div>
              <div
                className="technology"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-once="true">
                <p>Technology</p>
                <span>{item.technology}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="details-img"
          data-aos="fade-up"
          data-aos-duration={'1' + (index + 3) + '00'}
          data-aos-once="true">
          <img
            src={item.projectImgUrl}
            alt={`${item.title} | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
            title={`${item.title} | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
          />
        </div>
      </div>
    </div>
  );
};

export default ProminentWorksDetails;
