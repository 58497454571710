import IndiaFlag from '../Assets/Svg/IndiaFlag';
import UsFlag from '../Assets/Svg/UsFlag';
const FooterList = {
  addresses: [
    {
      title: 'Surat',
      flag: <IndiaFlag height="26" weight="26" />,
      address:
        '330, Silverstone Arcade, Singanpor Causway Road, Dabholi Gam, Surat, Gujarat 395004, India'
    },
    {
      title: 'Canada',
      flag: <UsFlag height="26" weight="26" />,
      address: '2012 Martin Grove Rd, #48, etobicoke, Toronto, M9V 3S5'
    }
  ],
  pageLinks: [
    {
      name: 'Services',
      path: '/services'
    },
    {
      name: 'About Us',
      path: '/about-us'
    },
    {
      name: 'Testimonial',
      path: '/testimonial'
    },
    {
      name: 'Case Studies',
      path: '/case-studies'
    },
    {
      name: 'Career',
      path: '/career'
    }
  ],
  otherLinks: [
    {
      name: 'Privacy policy',
      path: '/privacy-policy'
    },
    {
      name: 'Terms Of service',
      path: '/terms-of-service'
    }
  ]
  // blogsLinks: [
  //   {
  //     name: 'Tech trends 2021 that will be game changers for the',
  //     path: '/'
  //   },
  //   {
  //     name: 'Top UX principles for a successful business website',
  //     path: '/'
  //   }
  // ]
};
export default FooterList;
