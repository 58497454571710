export const FAQList = [
  {
    id: 1,
    question: 'How do I start a software development project?',
    answer:
      "The best way is to get in touch and explain your idea. We start with a product design sprint that will help you to build a solid plan of development, cost breakdown and will outline the potential future development of new features. It's a tested and trusted process and, according to our clients, by far the most effective and cost-efficient way to build your minimum viable product."
  },
  {
    id: 2,
    question:
      "I need images of a product but it's still being designed. What will you need from me?",
    answer:
      "Sure, that's a common phase in which visualizations are created. We build digital prototypes of products and help with the design process. The very least we would need is a sketch of what you have in mind and some guidance and feedback as we build it out from scratch."
  },
  {
    id: 3,
    question: 'Who will run my project?',
    answer:
      'At our company, your project will be managed by a dedicated team of experienced professionals who specialize in delivering customized solutions tailored to meet your specific needs and goals. We ensure a collaborative approach, transparent communication, and meticulous attention to detail throughout the project lifecycle to ensure successful delivery and client satisfaction.'
  },
  {
    id: 4,
    question: 'How much will my project cost and how long will it take?',
    answer:
      'We approach every project differently, so we give you an estimate after we analyze your brief. For new software projects, we start with a product design sprint that helps us to gather all needed information and assumptions about your project and come up with a plan of development, a thorough estimate and cost breakdown.'
  },
  {
    id: 5,
    question: 'Will you be able to maintain my product after the development is complete?',
    answer:
      "Yes, we build reliable and sustainable products that scale well, so we're not afraid to take care of maintenance after completing the agreed scope. We can discuss the details of an SLA and we will be there when you need us to implement new features or updates."
  }
];
