import React from 'react';
import Slider from 'react-slick';
import { CONSTANT } from '../../Utils/Constant';
import RecruitmentProcessList from '../../Utils/RecruitmentProcessList';
import './RecruitmentProcess.scss';

const RecruitmentProcess = () => {
  var settings = {
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className="recruitment-process-section">
      <div className="container">
        <h5 className="recruitment-process-title">Recruitment process</h5>
      </div>
      <div className="recruitment-process-slider">
        <div className="container">
          <Slider {...settings}>
            {RecruitmentProcessList.map((item, index) => (
              <div key={index} className="d-block">
                <div className="recruitment-process-wrap">
                  <div className="recruitment-process-img">
                    <img
                      src={item.img}
                      alt={`${item.title} | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                      title={`${item.title} | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`}
                    />
                    <span className="recruitment-process-count">{item.count}</span>
                  </div>
                  <div className="recruitment-process-content">
                    <h4>{item.title}</h4>
                    <p data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                      {item.content}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default RecruitmentProcess;
