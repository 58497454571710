import CupcakeCastles from '../Assets/Images/cupcake-castles-logo.png';
import GreenLabReviewLog from '../Assets/Images/greenlab-review-logo.png';
import LauriBurg from '../Assets/Images/ClientReviewAssets/Cupcake-Laurie-Burg.png';
import PranavSir from '../Assets/Images/ClientReviewAssets/Cupcake-Pranav-Sir.png';
import ChinmayBhatt from '../Assets/Images/ClientReviewAssets/GreenLab-Chinmay-Bhatt.png';
import BigKhetiLogo from '../Assets/Images/bigkheti-slider/big_kheti.png';

import SandipImg from '../Assets/Images/ClientReviewAssets/SandipSir.png';
import SandipProjectImg from '../Assets/Images/ClientReviewAssets/SandipSir-Project.png';

const ClientReviewList = [
  {
    clientImg: LauriBurg,
    clientPhoto: LauriBurg,
    projectLogo: CupcakeCastles,
    projectName: 'Cupcake Castles',
    clientName: 'Laurie Burg',
    clientPosition: 'CEO',
    clientReview:
      'Pixerfect resource is a highly responsive and dedicated professional, who consistently provides valuable input, demonstrates proactive problem-solving skills, and cares about the success of his clients.'
  },
  {
    clientImg: ChinmayBhatt,
    clientPhoto: ChinmayBhatt,
    projectLogo: GreenLabReviewLog,
    projectName: 'Green Lab: Personalized Health Solutions',
    clientName: 'Chinmay Bhatt',
    clientPosition: 'Owner',
    clientReview:
      'Over the past three years, Pixerfect Solutions LLP has been instrumental in developing GreenLab, our e-commerce platform for health products and data tracking. Their technical expertise, creativity, and professionalism consistently delivered high-quality results on time. I highly recommend them for any development projects.'
  },
  {
    clientImg: PranavSir,
    clientPhoto: PranavSir,
    projectLogo: BigKhetiLogo,
    projectName: 'Cupcake Castles',
    clientName: 'Pranav Sir',
    clientPosition: 'Chief Operating Officer',
    clientReview:
      'Pixerfect Solutions LLP exceeded our expectations by providing tailored IT solutions that greatly improved our operations. Their professionalism and unmatched dedication resulted in exceptional service that we highly recommend to other businesses.'
  },
  {
    clientImg: SandipImg,
    clientPhoto: SandipImg,
    projectLogo: SandipProjectImg,
    projectName: 'Mausam & CalculatorTimes',
    clientName: 'Sandip Kalsariya',
    clientPosition: 'Owner',
    clientReview:
      'I am incredibly pleased with the outstanding services provided by Pixerfect. They expertly crafted two exceptional websites for my projects. Mausam offers precise and user-friendly weather forecasts for all countries, while CalculatorTimes simplifies complex calculations with its diverse range of online calculators. The team is professionalism, creativity, and attention to detail exceeded my expectations.'
  }
];
export default ClientReviewList;
