import React from 'react';
import { IoCall, IoMail } from 'react-icons/io5';
import { RiTimeFill } from 'react-icons/ri';
import { CONSTANT } from '../../Utils/Constant';

const ContactDetails = ({ animate }) => {
  return (
    <>
      <div
        className="contact-list"
        data-aos={animate ? 'fade-up' : null}
        data-aos-duration={animate ? '1100' : null}
        data-aos-once={animate ? 'true' : null}>
        <div className="content-detail">
          <h6>Call Us</h6>
          <div className="contact-list-wrap">
            <span>
              <IoCall />
            </span>
            <a
              href={`tel:${CONSTANT.PIXERFECT_MOBILE_NUMBER}`}
              title={'Mobile Number | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}>
              {CONSTANT.PIXERFECT_MOBILE_NUMBER}
            </a>
          </div>
        </div>
      </div>
      <div
        className="contact-list"
        data-aos={animate ? 'fade-up' : null}
        data-aos-duration={animate ? '1200' : null}
        data-aos-once={animate ? 'true' : null}>
        <div className="content-detail">
          <h6>Write To Us</h6>
          <div className="contact-list-wrap">
            <span>
              <IoMail />
            </span>
            <a
              href={`https://mail.google.com/mail/?view=cm&fs=1&to=${CONSTANT.PIXERFECT_MAIL}&su=Inquiry from pixerfect.com`}
              title={'Email | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
              target="_blank"
              rel="noreferrer">
              {CONSTANT.PIXERFECT_MAIL}
            </a>
          </div>
        </div>
      </div>
      <div
        className="contact-list"
        data-aos={animate ? 'fade-up' : null}
        data-aos-duration={animate ? '1300' : null}
        data-aos-once={animate ? 'true' : null}>
        <div className="content-detail">
          <h6>Reach Us At</h6>
          <div className="contact-list-wrap">
            <span>
              <RiTimeFill />
            </span>
            <p>Mon-Fri, 09:00 AM to 06:00 PM (IST)</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactDetails;
