import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RightArrow from '../../Assets/Images/right-arrow.png';
import { CONSTANT, SEO } from '../../Utils/Constant';
import { ServicesPageList } from '../../Utils/ServiceList';
import './ServicesList.scss';

const ServicesList = () => {
  const navigate = useNavigate();
  const [currentService, setCurrentService] = useState(0);

  const onMouseEnter = (index) => {
    setCurrentService(index);
  };
  const onMouseLeave = (index) => {
    setCurrentService(index);
  };

  return (
    <div className="container">
      <div className="service-list-content">
        <ul className="service-list-detail">
          {ServicesPageList.map((item, index) => (
            <li
              key={index}
              className={`${currentService === index ? 'active' : ''} ${currentService > index ? 'prev' : ''} ${currentService < index ? 'next' : ''}`}>
              <div className={`service-img`}>
                <img
                  src={item.imgUrl}
                  alt={item.title + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                  title={item.title + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                />
              </div>
              <button
                onClick={() => {
                  navigate(item.path);
                  window.scrollTo(0, 0);
                }}
                type="button"
                className="mobile-view-btn">
                <span>{item.title}</span>
                <img
                  src={RightArrow}
                  alt={SEO.SERVICES_PAGE_TITLE}
                  title={SEO.SERVICES_PAGE_TITLE}
                />
              </button>
            </li>
          ))}
        </ul>
        <ul className="desktop-view-content">
          {ServicesPageList.map((item, index) => (
            <li
              key={index}
              className={`service-button ${currentService === index ? 'active' : ''}`}>
              <button
                type="button"
                onMouseEnter={() => onMouseEnter(index)}
                onMouseLeave={() => onMouseLeave(index)}
                onClick={() => {
                  navigate(item.path);
                  window.scrollTo(0, 0);
                }}
                className={`${currentService === index ? 'active' : ''}`}>
                <span>{item.title}</span>
                <img
                  src={RightArrow}
                  alt={SEO.SERVICES_PAGE_TITLE}
                  title={SEO.SERVICES_PAGE_TITLE}
                />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ServicesList;
